import RouterViewSlider from 'src/components/RouterViewSlider';
import { RouteEntityDetail } from 'src/components/EntityDetail';
import { entityDetailRoute } from 'src/components/EntityDetail/entity-route-util';
import { validateWorkOrderSelectAcl } from './workOrder.validations';
import { accessor } from 'src/store';
import { createdOrUpdatedByUser } from 'src/util';
import { getEntity, saveEntity } from './workOrder.entity';
import _isEqual from 'lodash.isequal';

export const workOrderRoutes = [
    {
        name: 'work-order-config-nav',
        path: 'work-orders',
        redirect: { name: 'work-order-list' },
        meta: {
            navigationGuard: () => validateWorkOrderSelectAcl(),
            navbarTitle: 'Work Orders',
            navbarIcon: '$argo-work-order',
            transition: 'vertical',
        },
        component: RouterViewSlider,
        children: [
            {
                name: 'work-orders-nav',
                path: '',
                redirect: { name: 'work-order-list' },
                navigationGuard: () => validateWorkOrderSelectAcl(),
                meta: {
                    transition: 'horizontal',
                },
                component: RouterViewSlider,
                children: [
                    {
                        name: 'work-order-list',
                        path: 'list',
                        component: () => import(/* webpackChunkName: "work-order" */ './WorkOrderList.view.vue'),
                        meta: {
                            pageBannerTitle: 'Work Orders', // TODO: i18n?
                        },
                    },
                    entityDetailRoute<'workOrder'>({
                        name: 'workOrder',
                        path: ':workOrderId',
                        component: RouteEntityDetail,
                        redirect: to => ({ name: 'work-order-general', params: to.params, query: to.query }),
                        meta: {
                            transition: 'horizontal',
                            entityType: 'workOrder',
                            getEntity,
                            saveEntity,
                            canEdit: () => true,
                            pageBannerTitle: () => 'Work Order', // TODO: i18n?
                            pageBannerSubtitle: () => createdOrUpdatedByUser(accessor.entityAsType('workOrder')),
                            pageBannerIcon: '$argo-work-order',
                            hasChanges: () => {
                                return !_isEqual({
                                    ...accessor.entityAsType('workOrder'),
                                    status: null,
                                }, {
                                    ...accessor.originalEntityAsType('workOrder'),
                                    status: null,
                                });
                            },
                            returnRoute: { name: 'work-order-list' },
                            routeParamsForFetch: route => ({ workOrderId: route.params.workOrderId }),
                        },
                        children: [
                            {
                                path: 'general',
                                name: 'work-order-general',
                                component: () => import(/* webpackChunkName: "work-order" */ './WorkOrderGeneral.view.vue'),
                                meta: {
                                    tab: {
                                        label: 'General Settings', // TODO: i81n
                                        icon: 'mdi-cog',
                                    },
                                },
                            },
                        ],
                    }),
                ],
            },
        ],
    },
];
