import { RouterViewSlider } from '../components/RouterViewSlider';
import { validateAcls } from 'src/auth';
import { messages } from 'src/i18n/i18n';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
    entityDetailRoute,
} from 'src/components/EntityDetail/entity-route-util';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { Notify } from 'src/notifications';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { AddAssetTypeDocument, FisCoreStateEnum, GetAssetTypeDocument, UpdateAssetTypeDocument } from '@redviking/argonaut-core-ui/types/db';
import { accessor } from '../store';
import { EntityTypeMap } from 'types';
import { getListChanges } from 'src/util/composables/watch-list-changes';
import { assetCodeTypes } from './assets/asset.util';
import { v4 as uuidv4 } from 'uuid';
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { getLastHourFromUnixTimestamp, useReportFilters } from './performance.util';
import { coreStates } from './stateColors';

function enforceReportQueryParams (to: Route, from: Route, next: NavigationGuardNext) {
    const { query, name } = to;
    if (!query.to || !query.from) {
        next({
            ...to,
            name: name!,
            query: {
                ...query,
                from: 'now-1d',
                to: 'now',
            },
        });
    } else {
        next();
    }
}

function reportSubtitleText (): string {
    const reportFilters = useReportFilters();
    const from = messages.tsFormatters.short.format(getLastHourFromUnixTimestamp(reportFilters.value.from));
    const to = messages.tsFormatters.short.format(getLastHourFromUnixTimestamp(reportFilters.value.to));
    return `${from} - ${to}`;
}

export default [
    {
        path: '/performance',
        name: 'fisNavRoute',
        component: RouterViewSlider,
        redirect: { name: 'PickAReport' },
        meta: {
            navigationGuard: () => validateAcls([ 'report_select' ]),
            navbarTitle: messages.performance.titles.performance,
            navbarIcon: 'mdi-chart-areaspline',
            transition: 'vertical',
        },
        children: [
            {
                name: 'PickAReport',
                path: '',
                component: () => import(/* webpackChunkName: "performance" */ './PerformanceMain.vue'),
                meta: {
                    transition: 'horizontal',
                    pageBannerTitle: 'Choose a Report',
                },
            },
            {
                name: 'Configuration',
                path: 'configuration',
                component: () => import(/* webpackChunkName: "performance" */ './Assets.vue'),
                meta: {
                    transition: 'horizontal',
                    pageBannerTitle: 'Configuration',
                    navbarTitle: 'Configuration',
                    navbarIcon: 'mdi-cog-box',
                },
            },
            {
                beforeEnter: enforceReportQueryParams,
                name: 'CATReport',
                path: 'constraint-analysis',
                component: () => import(/* webpackChunkName: "performance" */ './CATReport.vue'),
                meta: {
                    navbarTitle: messages.performance.titles.constraintAnalysis,
                    navbarIcon: 'mdi-chart-bar-stacked',
                    transition: 'horizontal',
                    pageBannerTitle: messages.performance.catReport.title,
                    pageBannerSubtitle: reportSubtitleText,
                },
            },
            {
                beforeEnter: enforceReportQueryParams,
                name: 'OEEReport',
                path: 'oee-analysis',
                component: () => import(/* webpackChunkName: "performance" */ './OEEReport.vue'),
                meta: {
                    navbarTitle: messages.performance.titles.oeeReport,
                    navbarIcon: 'mdi-chart-bar-stacked',
                    transition: 'horizontal',
                    pageBannerTitle: 'OEE Report',
                    pageBannerSubtitle: reportSubtitleText,
                },
            },
            {
                beforeEnter: enforceReportQueryParams,
                name: 'FReport',
                path: 'fault-analysis',
                component: () => import(/* webpackChunkName: "performance" */ './FReport.vue'),
                meta: {
                    navbarTitle: messages.performance.titles.faultReport,
                    navbarIcon: 'mdi-chart-bar',
                    transition: 'horizontal',
                    pageBannerTitle: 'Fault Report',
                    pageBannerSubtitle: reportSubtitleText,
                },
            },
            {
                beforeEnter: enforceReportQueryParams,
                name: 'MTBFReport',
                path: 'mtbf',
                component: () => import(/* webpackChunkName: "performance" */ './MTBFReport.vue'),
                meta: {
                    navbarTitle: messages.performance.titles.mtbfReport,
                    navbarIcon: 'mdi-chart-bar',
                    transition: 'horizontal',
                    pageBannerTitle: 'MTBF/MTTR Report',
                    pageBannerSubtitle: reportSubtitleText,
                },
            },
            {
                beforeEnter: enforceReportQueryParams,
                name: 'StateReport',
                path: 'state',
                component: () => import(/* webpackChunkName: "performance" */ './StateReport.vue'),
                meta: {
                    navbarTitle: 'State Report',
                    navbarIcon: 'mdi-chart-bar',
                    transition: 'horizontal',
                    pageBannerTitle: 'State Report',
                    pageBannerSubtitle: reportSubtitleText,
                },
            },
            // {
            //     name: 'FISSettings',
            //     path: 'settings',
            //     component: () => import(/* webpackChunkName: "performance" */ './FISGlobalSettings.vue'),
            //     meta: {
            //         transition: 'horizontal',
            //         pageBannerTitle: 'FIS Settings',
            //         navbarTitle: messages.settings,
            //         navbarIcon: 'mdi-cog',
            //     },
            // },
            entityDetailRoute<'assetType'>({
                path: 'settings/asset-type/:assetTypeId',
                name: 'assetTypeMaintenance',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'assetTypeDetail', params: to.params }),
                meta: {
                    entityType: 'assetType',
                    canEdit: true,
                    pageBannerSubtitle: () => 'Performance Settings',
                    pageBannerTitle: () => 'Asset Type',
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            const entityData: EntityTypeMap['assetType'] = {
                                __typename: 'fis_asset_type',
                                id: to.params.assetTypeId,
                                name: '',
                                asset_codes: Object.entries(coreStates).map(([ state, stateCfg ], idx) => {
                                    const assetCode: EntityTypeMap['assetType']['asset_codes'][number] = {
                                        data_label: {
                                            name: '',
                                        },
                                        id: uuidv4(),
                                        data_label_id: assetCodeTypes.state.id,
                                        code: idx + 1,
                                        description: `${state}`.replace(/^[-_]*(.)/u, (_, c) => c.toUpperCase()).replace(/[-_]+(.)/gu, (_, c) => ` ${c.toUpperCase()}`),
                                        core_state: state as FisCoreStateEnum,
                                        created_at: Date.now(),
                                        asset_type_id: to.params.assetTypeId,
                                        meta: { color: stateCfg.color },
                                    };
                                    return assetCode;
                                }),
                                created_at: Date.now(),
                            };
                            return {
                                entity: entityData,
                            };
                        }
                        const { asset_type } = await gqlClient.request({
                            document: GetAssetTypeDocument,
                            variables: {
                                assetTypeId: to.params.assetTypeId,
                            },
                        });
                        if (asset_type[0]) {
                            return {
                                entity: {
                                    ...asset_type[0],
                                },
                            };
                        } else {
                            const err = new Error('Unknown Asset Type');
                            Notify.error(err);
                            throw err;
                        }
                    },
                    saveEntity: async (payload: ExtendedEntityParameters<'assetType'>) => {
                        const { entity, oldEntity: originalOldEntity } = payload;
                        type AssetCodePlc = {
                            id: string,
                            asset_type_id: string,
                            data_label_id: string,
                            code: number,
                            description: string,
                            meta: any,
                            core_state: FisCoreStateEnum,
                            __typename?: string,
                        };
                        const origAssetCodes: AssetCodePlc[] = [ ...originalOldEntity?.asset_codes || [] ] || [];
                        const newAssetCodes: AssetCodePlc[] = [ ...entity?.asset_codes || [] ] || [];
                        const assetCodesPlc = getListChanges(newAssetCodes, origAssetCodes, { deep: true, partialChanges: false, format: 'plc' });

                        try {
                            if (!entity.name) {
                                throw new Error('Name is required');
                            }

                            const { updateResults } = await gqlClient.request({
                                document: UpdateAssetTypeDocument,
                                variables: {
                                    assetTypeId: entity.id,
                                    assetType: {
                                        name: entity.name,
                                    },
                                    insertAssetCodes: [
                                        ...assetCodesPlc.inserts.map(ac => ({
                                            id: ac.id,
                                            data_label_id: ac.data_label_id,
                                            asset_type_id: ac.asset_type_id,
                                            code: ac.code,
                                            description: ac.description,
                                            meta: ac.meta,
                                            core_state: ac.core_state,
                                        })),
                                        ...assetCodesPlc.updates.map(ac => ({
                                            id: ac.id,
                                            data_label_id: ac.data_label_id,
                                            asset_type_id: ac.asset_type_id,
                                            code: ac.code,
                                            description: ac.description,
                                            meta: ac.meta,
                                            core_state: ac.core_state,
                                        })),
                                    ],
                                    deleteAssetCodeBoolExp: {
                                        _or: assetCodesPlc.deletes.map(ac => ({
                                            asset_type_id: { _eq: ac.asset_type_id },
                                            data_label_id: { _eq: ac.data_label_id },
                                            code: { _eq: ac.code },
                                        })),
                                    },
                                },
                            });
                            if (updateResults?.asset_type) {
                                Notify.win('Saved');
                            }
                        } catch (error) {
                            Notify.error(error as Error);
                            throw error;
                        }
                    },
                    createEntity: async (payload: EntityModifyParameters<'assetType'>) => {
                        const { entity } = payload;
                        try {
                            const assetCodes = [ ...entity.asset_codes || [] ];
                            if (!entity.name) {
                                throw new Error('Name is required');
                            }
                            await gqlClient.request({
                                document: AddAssetTypeDocument,
                                variables: {
                                    assetType: {
                                        id: entity.id,
                                        name: entity.name,
                                        asset_codes: {
                                            data: assetCodes.map(ac => ({
                                                id: ac.id,
                                                data_label_id: ac.data_label_id,
                                                code: ac.code,
                                                description: ac.description,
                                                core_state: ac.core_state,
                                                meta: ac.meta,
                                            })),
                                        },
                                    },
                                },
                            });
                            Notify.win('Saved');
                        } catch (err) {
                            Notify.error(err as Error);
                            throw err;
                        }
                    },
                },
                children: [
                    {
                        path: 'detail',
                        name: 'assetTypeDetail',
                        component: () => import(/* webpackChunkName: "material" */ './assets/AssetTypeDetail.vue'),
                        meta: {
                            tab: {
                                label: 'Detail',
                                icon: 'mdi-chart-line-stacked',
                            },
                            pageBannerSubtitle: 'Asset Type Detail',
                            pageBannerTitle: () => accessor.entityAsType('assetType')?.name || 'Asset Type',
                        },
                    },
                    {
                        path: 'state-codes',
                        name: 'assetTypeStateCodes',
                        component: () => import(/* webpackChunkName: "state" */ './assets/AssetTypeState.vue'),
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'State Codes',
                                icon: 'mdi-cog',
                            },
                            pageBannerSubtitle: 'Asset Type States',
                            pageBannerTitle: () => accessor.entityAsType('assetType')?.name || 'Asset Type',
                        },
                    },
                ],
            }),
        ],
    } as RouteConfig,
];
