import { z } from 'zod';
import { sparkplugAppletNodeCfgSchema } from '../sparkplug-node-cfg.zod';
import { varConditionSchema } from './conditional.zod';
import { varpConfigBase } from './var-provider.zod';

export const sparkplugDeviceWriteProviderSchema = varpConfigBase.extend({
    type: z.literal('sparkplugDeviceWrite'),
    attrs: z.object({
        /**
         * which variables should be sent to which metrics
         */
        metrics: z.object({
            metricExpr: z.string(),
            /**
             * the name of the variable to provide the metric value
             */
            varSource: z.string(),
        }).array(),
        nodeCfg: sparkplugAppletNodeCfgSchema,
        /**
         * if specified, and an error occurs while trying to send, the message will be written to this var.
         * after a successful send, the provider will clear the value.
         */
        errorVar: z.string(),

        /**
         * only valid for local/custom vars. after sending the payload, these variable will be reset
         */
        varsToResetOnSend: z.string().array(),
        trigger: z.object({
            conditional: z.union([ varConditionSchema, z.null() ]).optional(),
        }),
    }),
});
