import z from 'zod';
import { locationSchema } from '../location.deployParam';
import { optionalVarNameSchema } from '../var-providers/var.zod';
import { varConditionSchema } from './conditional.zod';
import { varpConfigBase } from './var-provider.zod';

export const processProviderStateVarsSchema = z.object({
    required: optionalVarNameSchema,
    good: optionalVarNameSchema,
    bad: optionalVarNameSchema,
    status: optionalVarNameSchema,
    specId: optionalVarNameSchema.default(''),
    specName: optionalVarNameSchema,
    /** the variable to hold a validation error */
    validationError: optionalVarNameSchema,
});

export const processProviderLocationSchema = z.preprocess(value => {
    // previously, this was a string that indicated if the location should come from the mes-app config location / app device link provided location
    return typeof value === 'string' ? { deployParamId: 'Location', deployParamKind: 'location' } : value;
}, locationSchema);

export const processProviderSchema = varpConfigBase.extend({
    type: z.literal('process'),
    inputs: z.string().array(),
    attrs: z.object({
        processRevId: z.string(),
        /** the var to trigger data collection. when its value changes (or transitions to an ok status), it will trigger a data collection */
        trigger: varConditionSchema,
        materialIdVar: z.string(),

        /** var that holds the current material process state var */
        materialProcessStateIdVar: z.string().optional(),

        /** variable that when changed, will trigger the material process state to be refreshed */
        materialProcessStateUpdateVar: z.string().optional(),

        /** maps data collection data elements to input variables */
        dataCollectionVars: z.record(z.string(), z.string()),

        /** maps solved spec params to variables (keyed by data element id). not all params need to be assigned to variables */
        params: z.record(z.string(), z.string()),

        /**
         * whether the process should be recorded as taking place at the device location, or the location of the config
         * @default 'config'
         */
        location: processProviderLocationSchema,

        stateVars: processProviderStateVarsSchema,
    }),

    /**
     * variable names to act as outputs of this var provider
     *
     * for the process provider, these are only *configured* outputs,
     * since some are optional.
     */
    outputs: z.string().array(),
});
