import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import {
    ArgoWorkOrderConstraint,
    ArgoWorkOrderRestrictionEnum,
    ArgoWorkOrderStatusEnum,
    ArgoWorkOrderUpdateColumn,
    GetWorkOrderDocument,
    SaveWorkOrderDocument,
} from 'types/db';
import { Route } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';
import { Notify } from 'src/notifications';
import _cloneDeep from 'lodash.clonedeep';
import { SaveResult } from 'types';
import { type ExtendedEntityParameters } from 'src/components/EntityDetail';
import { getListChanges } from 'src/util/composables/watch-list-changes';

export type WorkOrderEntity = {
    id: string;
    orderNumber: string;
    materialModelId: string;
    status: string;
    restriction: string;
    targetQuantity: number;
    plannedStart?: string | null;
    actualStart?: string | null;
    locations: { id: string, location_id: string }[];
    materials: { id: string, material_id: string }[];
    materialCompletedCount: number;
    materialOtherCount?: number;
    createdAt: string | null;
    updatedAt: string | null;
}

export async function getEntity (to: Route): Promise<{ entity: WorkOrderEntity, originalEntity?: WorkOrderEntity }> {

    if (to.query.mode === 'create') {
        const workOrder: WorkOrderEntity = {
            id: uuidv4(),
            orderNumber: '',
            materialModelId: '',
            status: 'staged',
            restriction: 'unrestricted',
            targetQuantity: 0,
            plannedStart: null,
            actualStart: null,
            locations: [],
            materials: [],
            materialCompletedCount: 0,
            materialOtherCount: 0,
            createdAt: null,
            updatedAt: null,
        };
        return { entity: workOrder };
    } else {
        const { workOrder } = await gqlClient.request({
            document: GetWorkOrderDocument,
            variables: {
                work_order_id: to.params.workOrderId,
            },
        });

        if (!workOrder) {
            const err = new Error('Unknown Work Order');
            Notify.error(err);
            throw err;
        }

        // convert the graphql structure to our entity type
        let originalEntity: WorkOrderEntity = {
            createdAt: workOrder.created_at,
            id: workOrder.id,
            orderNumber: workOrder.order_number,
            materialModelId: workOrder.material_model_id,
            status: workOrder.status,
            restriction: workOrder.restriction,
            targetQuantity: workOrder.target_quantity,
            plannedStart: workOrder.planned_start || null,
            actualStart: workOrder.actual_start || null,
            locations: workOrder.work_order_locations.map(locLnk => ({
                id: locLnk.id,
                location_id: locLnk.location_id,
            })),
            materials: workOrder.work_order_materials.map(matLnk => ({
                id: matLnk.id,
                material_id: matLnk.material_id,
            })),
            materialCompletedCount: workOrder.material_completed_count || 0,
            materialOtherCount: workOrder.material_other_count || 0,
            updatedAt: workOrder.updated_at || null,
        };

        if (to.query.mode === 'copy') {
            originalEntity = _cloneDeep(originalEntity);
            originalEntity.id = uuidv4();
            originalEntity.orderNumber = `${originalEntity.orderNumber} - Copy`;
            originalEntity.status = 'staged';
            originalEntity.actualStart = null;
            originalEntity.materials = [];
        }

        const entity = _cloneDeep(originalEntity);

        return {
            entity,
            originalEntity,
        };
    }
}

export async function saveEntity (payload: ExtendedEntityParameters<'workOrder'>): Promise<void | SaveResult> {
    const entity = payload.entity;
    const oldEntity = payload.oldEntity;

    const workOrderLocationPlc = getListChanges(entity.locations, oldEntity?.locations || [], { deep: true, format: 'plc' });
    const workOrderMaterialPlc = getListChanges(entity.materials, oldEntity?.materials || [], { deep: true, format: 'plc' });

    if (!entity.orderNumber) {
        const errMessage = 'Work Order field is required';
        Notify.error(errMessage);
        throw new Error(errMessage);
    }

    if (!entity.materialModelId) {
        const errMessage = 'Material Model is required';
        Notify.error(errMessage);
        throw new Error(errMessage);
    }

    await gqlClient.request({
        document: SaveWorkOrderDocument,
        variables: {
            workOrderInput: {
                id: entity.id,
                order_number: entity.orderNumber,
                material_model_id: entity.materialModelId,
                status: entity.status as ArgoWorkOrderStatusEnum,
                restriction: entity.restriction as ArgoWorkOrderRestrictionEnum,
                target_quantity: entity.targetQuantity,
                planned_start: entity.plannedStart,
            },
            onConflict: {
                constraint: ArgoWorkOrderConstraint.WorkOrderPkey,
                update_columns: [
                    ArgoWorkOrderUpdateColumn.OrderNumber,
                    ArgoWorkOrderUpdateColumn.MaterialModelId,
                    ArgoWorkOrderUpdateColumn.PlannedStart,
                    ArgoWorkOrderUpdateColumn.Status,
                    ArgoWorkOrderUpdateColumn.Restriction,
                    ArgoWorkOrderUpdateColumn.TargetQuantity,
                ],
            },
            insertWorkOrderLocations: workOrderLocationPlc.inserts.map(plc => ({
                work_order_id: entity.id,
                location_id: plc.location_id,
            })),
            deleteWorkOrderLocationBoolExp: {
                id: { _in: workOrderLocationPlc.deletes.map(plc => plc.id) },
            },
            insertWorkOrderMaterials: workOrderMaterialPlc.inserts.map(plc => ({
                work_order_id: entity.id,
                material_id: plc.material_id,
            })),
            deleteWorkOrderMaterialBoolExp: {
                id: { _in: workOrderMaterialPlc.deletes.map(plc => plc.id) },
            },
        },
    });

    const router = await import('src/routing').then(m => m.router);
    router.push({ name: 'work-order-general', params: { workOrderId: entity.id } });
}
