export const messages = {
    createdAtLocation: 'Created At Location',
    material: {
        addNew: 'Add New Material',
        attrFilter: 'Filter: Attributes',
        confirmDelete: 'Are you sure you want to delete this material?',
        create: 'Create Material',
        entityName: 'Material',
        hide: 'Hide Material',
        inspect: 'Inspect Material',
        labelPrint: 'Label Print',
        materialDef: 'Material Classes',
        name: 'Material Name',
        noneFound: 'No Material Found',
        notifyClassSaved: 'Material Saved!',
        notifySaved: 'Material Saved!',
        saved: 'Material Saved',
        search: 'Search Material by Name or Description',
        serialNumber: 'Serial Number',
        status: 'Status',
        type: 'Material Type',
    },
    mtlAttr: {
        attrDesc: 'Description',
        attrName: 'Attribute Name',
        attrSchema: 'Schema',
        noneFound: 'No Material Attributes Found',
        notifySaved: 'Material Attributes Saved!',
        search: 'Search Material Attributes by Name or Description',
    },
    states: {
        failed: 'Failed',
        passed: 'Passed',
        pending: 'Pending',
    },
    titles: {
        nav: 'Material',
        noName: 'Unnamed Material',
        pageBannerSubtitle: 'View Material',
        pageBannerTitle: 'Material',
    },
    total: 'Total',
};
