export const messages = {
    assets: {
        coreState: 'Core State',
        entityName: 'Asset',
        new: {
            asset_type: 'Unnamed Asset Type',
            cycle_time: 'Cycle Time',
            cycle_time_hint: 'The ideal time (in seconds) for this asset to produce 1 part.',
            description: 'Unnamed Asset Description',
            name: 'Unnamed Asset',
        },
        plural: 'Assets',

        types: {
            label: 'Asset Type',
            name: 'Asset Type Name',
        },

    },
    catReport: {
        badCount: 'Bad',
        badCountToolTip: 'Bad items produced',
        blocked: 'Blocked',
        blockedSecondary: 'Blocked Sec',
        blockedSecondaryToolTip: 'Time spent Blocked Secondary in minutes',
        blockedToolTip: 'Time spent Blocked in minutes',
        cycling: 'Cycling',
        cyclingToolTip: 'Cycling Time in minutes',
        downLine: 'Line Down',
        downLineToolTip: 'Time spent down at a line level in minutes',
        downStation: 'Station Down',
        downStationToolTip: 'Time spent down at a station level in minutes',
        ejph: 'EJPH',
        ejphFull: 'Expected',
        ejphToolTip: 'Expected JPH - (1 Hour / Ideal Asset Cycle Time)',
        expectedCount: 'Expected',
        expectedCountToolTip: 'Expected Count - (Planned Productive Time / Ideal Asset Cycle Time)',
        goodCount: 'Good',
        goodCountToolTip: 'Good items produced',
        graph: {
            title: 'Time Spent in Each State',
            xAxis: 'Assets',
            yAxis: 'Time (s)',
        },
        groupCount: 'Counts',
        groupCountToolTip: 'Totals',
        groupDuration: 'Duration (m)',
        groupDurationToolTip: 'Time in minutes',
        groupJob: 'JPH',
        groupJobToolTip: 'Jobs Per Hour',
        groupState: 'State Time (m)',
        groupStateToolTip: 'State Time in minutes',
        jph: 'Actual',
        jphFull: 'Actual',
        jphToolTip: 'Actual Jobs Per Hour (Actual Time Cycling / Actual Total Count)',
        prodTime: 'Productive',
        prodTimeToolTip: 'Productive Time in minutes',
        starved: 'Starved',
        starvedSecondary: 'Starved Sec',
        starvedSecondaryToolTip: 'Time spent Starved Secondary in minutes',
        starvedToolTip: 'Time spent Starved in minutes',
        title: 'Constraint Analysis Report',
        totalCount: 'Total',
        totalCountToolTip: 'Total items produced',
        totalDown: 'Total Down',
        totalDownToolTip: 'Total Down Time in minutes',
        totalTime: 'Total',
        totalTimeToolTip: 'Total time in minutes',
        utilizationTime: 'Utilization',
        utilizationTimeToolTip: 'Time utilized at a station',
    },
    headers: {
        actualTime: 'Actual Time',
        assetName: 'Asset',
        availability: 'Availability',
        code: 'Code',
        deviceName: 'Device Name',
        downTimeDuration: 'Down Time Duration',
        duration: 'Duration',
        event: 'Event',
        externalOccurrence: 'External Occurrence',
        fault: 'Fault',
        faultTime: 'Fault Time',
        locationName: 'Location Name',
        meanTimeBetweenFaults: 'Mean Time Between Faults',
        mfOccurrence: 'Fault Occurrence',
        miOccurrence: 'Intervention Occurrence',
        mttr: 'MTTR',
        nonProdTime: 'Non Prod Time',
        occurrence: 'Occurrence',
        shift: 'Shift',
        shiftDay: 'Shift Day',
        stationName: 'Station Name',
        totalProductiveTime: 'Total Productive Time',
    },
    mainPage: {
        description: 'This feature shows performance data from the factory floor, tracked to a configured Asset.',
        settingsTooltip: 'Performance Settings',
        title: 'Performance Monitoring',
    },
    settings: {
        assetTypeAssetsCount: 'Assets',
        assetTypeCodesCount: 'Code Definitions',
        assetTypeCreatedAt: 'Created At',
        assetTypeName: 'Name',
        assetTypeUpdatedAt: 'Last Updated',
        assetTypes: 'Asset Type Definitions',
    },
    stateReport: {
        svg: {
            headers: {
                duration: 'Duration',
                endTime: 'End Time',
                info: 'Viewing asset {assetName} from {startTime} to {endTime}',
                startTime: 'Start Time',
                state: 'State',
            },
            title: 'Asset State Reports',
            zoom: {
                title: 'Zoomed In View',
            },
        },
        toolTips: {
            colorLegend: 'State Color Legend',
            detail: 'View Breakdown',
        },

    },
    titles: {
        constraintAnalysis: 'Constraint Analysis',
        faultReport: 'Fault Report',
        monitoring: 'Monitoring',
        mtbfReport: 'MTBF/MTTR',
        oeeReport: 'OEE Report',
        performance: 'Performance',
    },
};
