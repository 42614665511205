import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';

export const timerCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('timer'),
    attrs: cellCfgAttrsBaseSchema.extend({
        format: z.enum([ 'hh:mm:ss', 'XXhXXmXXs' ]),
        /** name of the var that will drive the input */
        sourceVar: z.string(),
        /** supports css color values (`#RGB`, `#RGBA`, `rgb()`, `rgba()`) or vuetify text color classes */
        dialColor: z.string(),
        /** minimum percentage beyond which the timer will begin flashing */
        flashingThreshold: z.number(),
        /** maximum value that is allowed for the sourceVar to return */
        maxValue: z.number(),
    }),
    modifiers: z.record(z.enum([
        'dialColor',
        'maxValue',
        'flashingThreshold',
    ]), z.string().optional()).optional(),
});
