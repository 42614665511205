/* eslint-disable max-lines */
import {
    type AnyNodeCfg,
    type FlowCfg,
    type LatestCalendarUdfColumn,
    type NodeCfg,
    type NodeKind,
    type NodeSocketKindMap,
    nodeCfgSchemaMap,
} from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';
import { getUID } from '@redviking/argonaut-util/src/getUID';
import { UiFlowNodeControl } from './controls/UiFlowNodeControl';
import type { UiFlowNode } from './UiFlowNode';
import { ClassicPreset } from 'rete';
import { UiFlowNodeSocket } from './sockets/UiFlowNodeSocket';
import { UiFlowNodeOutput } from './sockets/UiFlowNodeOutput';
import { removeNodeSocket } from './sync-utils/remove-node-socket';
import { UiFlowNodeInput } from './sockets/UiFlowNodeInput';
import { getMetricExpr } from '@redviking/argonaut-util/src/metric-parser/metric-expr';
import { validateSparkplugMetricExpr } from '../validations/metric-expr.validations';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { getAppletEvents } from '@redviking/argonaut-util/src/mes/get-applet-events';
import { AsyncComponent } from 'vue';
import {
    ArgoUdfColumnDataTypeEnum,
    type FisDataLabelFragment,
    FisDataTimingEnum,
    ModelNumberFragment,
} from 'types/db';
import { SnfToken } from '@redviking/argonaut-util/src/serial-numbers';
import { createNodeSocket } from './sync-utils/create-node-socket';
import { calendarColumnLabels, calendarColumnSelectionMap } from '@redviking/argonaut-util/types/mes/applet-designs/v3/flow/nodes/calendar.zod';
import type { DeployParamKind } from '@redviking/argonaut-util/src/mes/deployParam.zod';

const CORE_STATE_DATA_LABEL_ID = '74127943-ce8a-47c0-89fd-c65d174072f2';

export type NodeCategory = 'text' | 'number' | 'conditional' | 'variable' | 'events' | 'argonautApi' | 'external' | 'timeAndDate' | 'other' | 'boolean';

type NodeControlValues<CFG extends AnyNodeCfg> = CFG extends { controlValues: Record<string, unknown> }
    ? {
        [controlKey in keyof CFG['controlValues']]: UiFlowNodeControl<CFG['controlValues'][controlKey]>;
    }
    : Record<string, never>;

export type NodeVaildation<KIND extends NodeKind> = (node: NodeCfg<KIND>, flow: FlowCfg) => true | {[controlName: string]: string};

export type NodeInitializer<
    KIND extends NodeKind,
    CFG extends NodeCfg<KIND> = NodeCfg<KIND>,
    SOCKETS extends NodeSocketKindMap[KIND] = NodeSocketKindMap[KIND],
> = {
    cfg: () => NodeCfg<KIND>,
    nodeCategory: NodeCategory;
    /**
     * If true, the node will not appear in the flow inspector and cannot be added
     */
    supressNode?: boolean | (() => boolean);
    /**
     * An extra component can be defined that will appear in the flow inspector when the node is selected to provide additional configuration options
     */
    extraComponent?: AsyncComponent;
    /**
     * Define how to populate control values for the node. Returns a partial object, it is expected that any controls not configured here will be configured in a helper component in the flow inspector
     */
    controls: (cfg: CFG, node: UiFlowNode<KIND, CFG>) => Partial<NodeControlValues<CFG>>;
    inputs: (cfg: CFG, node: UiFlowNode<KIND, CFG>) => ({
        [inputKey in keyof SOCKETS['inputs']]: ClassicPreset.Input<UiFlowNodeSocket>;
    }),
    kindLabel: string;
    canDelete?: boolean | ((cfg: CFG, node?: UiFlowNode<KIND, CFG>) => boolean);
    canResize?: boolean | ((cfg: CFG, node: UiFlowNode<KIND, CFG>) => boolean);
    outputs: (cfg: CFG, node: UiFlowNode<KIND, CFG>) => ({
        [outputKey in keyof SOCKETS['outputs']]: ClassicPreset.Output<UiFlowNodeSocket> | UiFlowNodeOutput<UiFlowNodeSocket>;
    }),
    // record is keyed by control name and values are error messages
    validator: NodeVaildation<KIND>;
};

/**
 * config initializer for each node kind.
 */
export const nodeKindMap: {
    [kind in NodeKind]: NodeInitializer<kind>;
} = {
    appletEventEmit: {
        cfg: () => ({ controlValues: { appletEventId: '' }, id: getUID(), kind: 'appletEventEmit' }),
        controls: (cfg, node) => ({
            appletEventId: new UiFlowNodeControl({ cfg: { kind: 'selectEvent' }, node, value: cfg.controlValues.appletEventId }),
        }),
        inputs: () => ({ trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger') }),
        kindLabel: 'Emit Applet Event',
        nodeCategory: 'events',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const entityData = accessor.entityAsType('appletDesignVersion');
            const events = entityData ? getAppletEvents(entityData.config) : {};
            const validEventNames = Object.keys(events);
            const errors: Record<string, string> = {};
            if (!node.controlValues.appletEventId) {
                errors.appletEventId = 'Event ID is required';
            }
            if (!validEventNames.includes(node.controlValues.appletEventId)) {
                errors.appletEventId = 'Event ID is not a valid event';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger input is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    appletEventListen: {
        cfg: () => ({ controlValues: { appletEventId: '' }, id: getUID(), kind: 'appletEventListen' }),
        controls: (cfg, node) => ({ appletEventId: new UiFlowNodeControl({ cfg: { kind: 'selectEvent' }, node, value: cfg.controlValues.appletEventId }) }),
        inputs: () => ({}),
        kindLabel: 'Listen For Applet Event',
        nodeCategory: 'events',
        outputs: () => ({ event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Event') }),
        validator: (node, flow) => {
            const entityData = accessor.entityAsType('appletDesignVersion');
            const events = entityData ? getAppletEvents(entityData.config) : {};
            const validEventNames = Object.keys(events);
            const errors: Record<string, string> = {};
            if (!node.controlValues.appletEventId) {
                errors.appletEventId = 'Event ID is required';
            }
            if (!validEventNames.includes(node.controlValues.appletEventId)) {
                errors.appletEventId = 'Event ID is not a valid event';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event')) {
                errors.event = 'Event output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    attributeMaterialLink: {
        supressNode: true,
        cfg: () => ({ controlValues: { attributeId: '' }, id: getUID(), kind: 'attributeMaterialLink' }),
        controls: (cfg, node) => ({
            attributeId: new UiFlowNodeControl({ cfg: { kind: 'createMaterial.attributes' }, node, value: cfg.controlValues.attributeId }),
        }),
        inputs: (cfg, node) => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            materialId: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: {
                        kind: 'text',
                        nullable: true,
                    },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.materialId,
                }),
                socket: new UiFlowNodeSocket('string'),
                label: 'Material ID',
            }),
        }),
        kindLabel: 'Attribute Material Link',
        nodeCategory: 'argonautApi',
        outputs: () => ({
            next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.socketDefaults?.inputs?.materialId && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'materialId')) {
                errors.materialId = 'Material ID is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger is required';
            }
            if (!node.controlValues.attributeId) {
                errors.attributeId = 'Attribute ID is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    boolCast: {
        cfg: () => ({ controlValues: {}, id: getUID(), kind: 'boolCast' }),
        controls: (_cfg, _node) => {
            return {};
        },
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input') }),
        kindLabel: 'Boolean Value Cast',
        nodeCategory: 'boolean',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: () => true,
    },
    comparisonNumber: {
        cfg: () => ({ controlValues: { not: false, operation: '=' }, id: getUID(), kind: 'comparisonNumber' }),
        controls: (cfg, node) => {
            return {
                not: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Not' }, node, value: cfg.controlValues.not }),
                operation: new UiFlowNodeControl({
                    cfg: {
                        items: [
                            { label: '=', value: '=' },
                            { label: '>', value: '>' },
                            { label: '>=', value: '>=' },
                            { label: '<', value: '<' },
                            { label: '<=', value: '<=' },
                        ],
                        kind: 'selection',
                        label: 'Operation',
                    },
                    node,
                    value: cfg.controlValues.operation,
                }),
            };
        },
        inputs: (cfg, node) => ({
            input1: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: {
                        kind: 'number',
                        nullable: true,
                    },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.input1,
                }),
                socket: new UiFlowNodeSocket('varValue'),
                label: 'Input 1',
            }),
            input2: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: {
                        kind: 'number',
                        nullable: true,
                    },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.input2,
                }),
                socket: new UiFlowNodeSocket('varValue'),
                label: 'Input 2',
            }),
        }),
        kindLabel: 'Number Comparison',
        nodeCategory: 'conditional',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};

            if (typeof node.socketDefaults?.inputs?.input1 !== 'number' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input1')) {
                errors.input1 = 'Input 1 is required';
            }

            if (typeof node.socketDefaults?.inputs?.input2 !== 'number' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input2')) {
                errors.input2 = 'Input 2 is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    comparisonString: {
        cfg: () => ({ controlValues: { not: false, operation: '=' }, id: getUID(), kind: 'comparisonString' }),
        controls: (cfg, node) => ({
            not: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Not' }, node, value: cfg.controlValues.not }),
            operation: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: '=', value: '=' },
                        { label: 'contains', value: 'contains' },
                        { label: 'startsWith', value: 'startsWith' },
                        { label: 'endsWith', value: 'endsWith' },
                    ],
                    kind: 'selection',
                    label: 'Operation',
                },
                node,
                value: cfg.controlValues.operation,
            }),
        }),
        inputs: (cfg, node) => ({
            input1: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: {
                        kind: 'text',
                        nullable: true,
                    },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.input1,
                }),
                socket: new UiFlowNodeSocket('varValue'),
                label: 'Input 1',
            }),
            input2: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: {
                        kind: 'text',
                        nullable: true,
                    },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.input2,
                }),
                socket: new UiFlowNodeSocket('varValue'),
                label: 'Input 2',
            }),
        }),
        kindLabel: 'Text Comparison',
        nodeCategory: 'conditional',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('boolean'), 'Output') }),
        validator: (node, flow) => {

            const errors: Record<string, string> = {};
            if (typeof node.socketDefaults?.inputs?.input1 !== 'string' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input1')) {
                errors.input1 = 'Input 1 is required';
            }
            if (typeof node.socketDefaults?.inputs?.input2 !== 'string' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input2')) {
                errors.input2 = 'Input 2 is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    createMaterial: {
        cfg: () => {
            const ret: NodeCfg<'createMaterial'> = {
                controlValues: {
                    labelPrint: false,
                    location: { id: '' },
                    materialModelId: '',
                    snCfg: { kind: 'direct' },
                    udfs: {},
                },
                id: getUID(),
                kind: 'createMaterial',
            };
            return ret;
        },
        controls: (cfg, node) => ({
            labelPrint: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Label Print' }, node, value: cfg.controlValues.labelPrint }),
            location: new UiFlowNodeControl({
                node,
                value: cfg.controlValues.location,
                cfg: {
                    kind: 'location',
                },
            }),
            materialModelId: new UiFlowNodeControl({
                cfg: {
                    kind: 'createMaterial.modelId',
                    label: 'Material Model',
                    onSelect: (materialModel: ModelNumberFragment) => {
                        const snFormatCfg = materialModel.sn_format?.config as { tokens: SnfToken[] } | undefined;
                        const controlValues: NodeCfg<'createMaterial'>['controlValues'] = {
                            ...node.cfg.controlValues,
                            materialModelId: materialModel.id,
                            snCfg: snFormatCfg
                                ? {
                                    fmtInputs: snFormatCfg.tokens.filter(token => token.type === 'input').map(token => token.name),
                                    kind: 'formatter',
                                }
                                : {
                                    kind: 'direct',
                                },
                            udfs: materialModel.material_class_udf_schemas.reduce((acc, schema) => {
                                const udfData: NodeCfg<'createMaterial'>['controlValues']['udfs'][string] = {
                                    name: schema.udf_column.name,
                                    required: schema.udf_column.validation_required,
                                    type: schema.udf_column.data_type,
                                };
                                acc[schema.udf_column_id] = udfData;
                                return acc;
                            }, {} as NodeCfg<'createMaterial'>['controlValues']['udfs']),
                        };
                        node.cfg = {
                            ...node.cfg,
                            controlValues,
                        };
                        node.flowCfgRef.value = {
                            ...node.flowCfgRef.value,
                            nodes: {
                                ...node.flowCfgRef.value.nodes,
                                [node.id]: node.cfg,
                            },
                        };
                    },
                },
                node,
                // placeholder: 'Select a material model',
                value: cfg.controlValues.materialModelId,
            }),
        }),
        // extraComponent: () => import('./inspector/CreateMaterial.helper.vue').then(m => m.default),
        inputs: cfg => {
            const inputs = {
                trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            } as ReturnType<NodeInitializer<'createMaterial'>['inputs']>;
            if (cfg.controlValues.snCfg.kind === 'direct') {
                inputs.sn = new ClassicPreset.Input(new UiFlowNodeSocket('string'), 'Serial Number');
            } else {
                for (const fmtInput of cfg.controlValues.snCfg.fmtInputs) {
                    inputs[`snInput_${fmtInput}`] = new ClassicPreset.Input(new UiFlowNodeSocket('string'), `${fmtInput} - Serial Number Input`);
                }
            }
            for (const [ udfId, udfData ] of Object.entries(cfg.controlValues.udfs)) {
                // FUTURE: udfData has a `required` boolean that we can use to style the optional udf inputs
                inputs[`udfInput_${udfId}`] = new ClassicPreset.Input(new UiFlowNodeSocket(udfData.type === 'number' ? 'number' : 'string'), `${cfg.controlValues.udfs[udfId].name} - UDF Input`);
            }
            return inputs;
        },
        kindLabel: 'Create Material',
        nodeCategory: 'argonautApi',
        outputs: () => ({
            next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next'),
            materialId: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Material ID'),
            serialNumber: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Serial Number'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if ('deployParamKind' in node.controlValues.location) {
                if (!node.controlValues.location.deployParamId) {
                    errors.location = 'Location deployment parameter is required';
                }
            } else if (!node.controlValues.location.id) {
                errors.location = 'Location is required';
            }
            if (!node.controlValues.materialModelId) {
                errors.materialModelId = 'Material Model is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger is required';
            }
            if (node.controlValues.snCfg.kind === 'direct') {
                if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'sn')) {
                    errors.sn = 'Serial number is required';
                }
            } else {
                for (const fmtInput of node.controlValues.snCfg.fmtInputs) {
                    if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === `snInput_${fmtInput}`)) {
                        errors[`snInput_${fmtInput}`] = 'Serial number input is required';
                    }
                }
            }
            /**
             * FUTURE: This should be a warning not an error that prevents save.
             * This loop throw an error if a udf input is not present, but the default could be set at the material model.
             * Today if neither are supplied the plv8 function will error.
             */
            // for (const udfId of Object.keys(node.controlValues.udfs)) {
            //     if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === `udfInput_${udfId}`)) {
            //         errors[`udfInput_${udfId}`] = 'UDF input is required';
            //     }
            // }
            return Object.keys(errors).length ? errors : true;
        },
    },
    defaultVal: {
        cfg: () => ({ id: getUID(), kind: 'defaultVal' }),
        controls: () => ({}),
        inputs: (cfg, node) => ({
            defaultValue: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: {
                        kind: 'varValue',
                        nullable: true,
                        label: 'Default',
                    },
                    nullable: true,
                    node,
                    value: cfg.socketDefaults?.inputs?.defaultValue,
                }),
                socket: new UiFlowNodeSocket('varValue'),
            }),
            input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input'),
        }),
        kindLabel: 'Default Value',
        nodeCategory: 'variable',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (node.socketDefaults?.inputs?.defaultValue === undefined && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'defaultValue')) {
                errors.defaultValue = 'Default value is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    eventDelay: {
        cfg: () => ({ controlValues: { delayMs: 1000 }, id: getUID(), kind: 'eventDelay' }),
        controls: (cfg, node) => ({ delayMs: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Delay (ms)', min: 0 }, node, value: cfg.controlValues.delayMs }) }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Input') }),
        kindLabel: 'Event Delay',
        nodeCategory: 'events',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input value is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    eventOneShot: {
        cfg: () => ({ id: getUID(), kind: 'eventOneShot' }),
        controls: () => ({}),
        inputs: () => ({
            input: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Input'),
            reset: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Reset'),
        }),
        kindLabel: 'Event One Shot',
        nodeCategory: 'events',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input value is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    eventSwitch: {
        cfg: () => ({ id: getUID(), kind: 'eventSwitch' }),
        controls: () => ({}),
        inputs: () => ({
            event: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Event'),
            condition: new ClassicPreset.Input(new UiFlowNodeSocket('boolean'), 'Condition'),
        }),
        kindLabel: 'Event Switch',
        nodeCategory: 'events',
        outputs: () => ({
            false: new UiFlowNodeOutput({
                socket: new UiFlowNodeSocket('event'),
                label: 'False',
                index: 1,
            }),
            true: new UiFlowNodeOutput({
                socket: new UiFlowNodeSocket('event'),
                label: 'True',
                index: 0,
            }),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'event')) {
                errors.event = 'Event is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'condition')) {
                errors.condition = 'Condition is required';
            }

            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'true') && !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'false')) {
                errors.true = 'At least one output is required';
                errors.false = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    eventThrottle: {
        cfg: () => ({ controlValues: { max: 1, windowMs: 200 }, id: getUID(), kind: 'eventThrottle' }),
        controls: (cfg, node) => ({
            max: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Max', min: 1 }, node, value: cfg.controlValues.max }),
            windowMs: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Window (ms)', min: 0 }, node, value: cfg.controlValues.windowMs }),
        }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Input') }),
        kindLabel: 'Event Throttle',
        nodeCategory: 'events',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    jsonParse: {
        cfg: () => ({ controlValues: { outputExpressions: [] }, id: getUID(), kind: 'jsonParse' }),
        controls: (cfg, node) => ({
            outputExpressions: new UiFlowNodeControl({
                cfg: {
                    kind: 'button',
                    label: 'Add Output',
                    onClick: () => {
                        createNodeSocket({
                            node,
                            newNodeCfg: {
                                ...node.cfg,
                                controlValues: {
                                    ...node.cfg.controlValues,
                                    outputExpressions: [
                                        ...node.cfg.controlValues.outputExpressions,
                                        '',
                                    ],
                                },
                            },
                        });
                    },
                },
                node,
                value: cfg.controlValues.outputExpressions,
            }),
        }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('string'), 'Input') }),
        kindLabel: 'JSON Parse',
        nodeCategory: 'variable',
        outputs: (cfg, node) => {
            const outputs: Record<`output${number}`, ClassicPreset.Output<UiFlowNodeSocket>> = {};
            for (let i = 0; i < cfg.controlValues.outputExpressions.length; i++) {
                const control = new UiFlowNodeControl({
                    cfg: {
                        kind: 'jsonParse.outputExpression',
                        onDelete () {
                            removeNodeSocket({
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        outputExpressions: [
                                            ...node.cfg.controlValues.outputExpressions.slice(0, i),
                                            ...node.cfg.controlValues.outputExpressions.slice(i + 1),
                                        ],
                                    },
                                },
                                node,
                                direction: 'output',
                                removalMethod: {
                                    index: i,
                                    prefix: 'output',
                                    kind: 'indexedIdentifier',
                                },
                            });
                        },
                        placeholder: 'JSONata Expression',
                    },
                    node,
                    value: cfg.controlValues.outputExpressions[i],
                    socketDefault: false,
                });
                control.changeCb = value => {
                    node.cfg.controlValues.outputExpressions[i] = value;
                    node.cfg.controlValues.outputExpressions = [ ...node.cfg.controlValues.outputExpressions ];
                    node.applyControlValue('outputExpressions', node.cfg.controlValues.outputExpressions);
                };
                outputs[`output${i}`] = new UiFlowNodeOutput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket('varValue'),
                });
            }
            return outputs;
        },
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.outputExpressions.length) {
                errors.outputExpressions = 'At least one output expression is required';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }

            let atLeastOneOutput = false;
            for (let i = 0; i < node.controlValues.outputExpressions.length; i++) {
                const jsonExpression = node.controlValues.outputExpressions[i];
                if (!jsonExpression) {
                    errors[`output${i}`] = 'Output expression is required';
                } else {
                    // I think there is a call to safe parse on the entire config that is occuring elsewhere essentially duplicating this check
                    const parseResult = nodeCfgSchemaMap.jsonParse.shape.controlValues.shape.outputExpressions.safeParse([ jsonExpression ]);
                    if (parseResult.success === false) {
                        errors[`output${i}`] = 'This is not a valid JSONata expression';
                    }
                }
                if (Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === `output${i}`)) {
                    atLeastOneOutput = true;
                }
            }

            if (!atLeastOneOutput) {
                for (let i = 0; i < node.controlValues.outputExpressions.length; i++) {
                    errors[`output${i}`] = 'At least one output is required';
                }
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    mqttPublish: {
        cfg: () => ({ controlValues: { qos: 0, retain: false }, id: getUID(), kind: 'mqttPublish' }),
        controls: (cfg, node) => ({
            qos: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: '0', value: 0 },
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                    ],
                    kind: 'selection',
                    label: 'QoS',
                },
                node,
                value: cfg.controlValues.qos,
            }),
            retain: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Retain' }, node, value: cfg.controlValues.retain }),
        }),
        inputs: (cfg, node) => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            payload: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'text', nullable: true },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.payload || '',
                }),
                socket: new UiFlowNodeSocket('string'),
                label: 'Message',
            }),
            topic: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'text', nullable: true },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.topic || '',
                }),
                socket: new UiFlowNodeSocket('string'),
                label: 'Topic',
            }),
        }),
        kindLabel: 'MQTT Publish',
        nodeCategory: 'external',
        outputs: () => ({
            next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger is required';
            }
            if (typeof node.socketDefaults?.inputs?.payload !== 'string' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'payload')) {
                errors.payload = 'Payload is required';
            }
            if (typeof node.socketDefaults?.inputs?.topic !== 'string' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'topic')) {
                errors.topic = 'Topic is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    mqttSubscribe: {
        cfg: () => ({ controlValues: { qos: 0, topic: '' }, id: getUID(), kind: 'mqttSubscribe' }),
        controls: (cfg, node) => ({
            qos: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: '0', value: 0 },
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                    ],
                    kind: 'selection',
                    label: 'QoS',
                },
                node,
                value: cfg.controlValues.qos,
            }),
            topic: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Topic' }, node, value: cfg.controlValues.topic }),
        }),
        inputs: () => ({}),
        kindLabel: 'MQTT Subscribe',
        nodeCategory: 'external',
        outputs: () => ({
            event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Message'),
            payload: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Payload'),
        }),
        validator: (node, flow) => {

            const errors: Record<string, string> = {};
            if (!node.controlValues.topic) {
                errors.topic = 'Topic is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event')) {
                errors.event = 'Event is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'payload')) {
                errors.payload = 'Payload is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    numCast: {
        cfg: () => ({ controlValues: {}, id: getUID(), kind: 'numCast' }),
        controls: (_cfg, _node) => {
            return {};
        },
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input') }),
        kindLabel: 'Number Value Cast',
        nodeCategory: 'number',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('number'), 'Output') }),
        validator: () => true,
    },
    numClamp: {
        cfg: () => ({ controlValues: { max: 0, min: 0 }, id: getUID(), kind: 'numClamp' }),
        controls: (cfg, node) => ({
            max: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Max' }, node, value: cfg.controlValues.max }),
            min: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Min' }, node, value: cfg.controlValues.min }),
        }),
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('number'), 'Input') }),
        kindLabel: 'Number Clamp',
        nodeCategory: 'number',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('number'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            if (node.controlValues.min && node.controlValues.max && node.controlValues.min > node.controlValues.max) {
                errors.min = 'Min must be less than or equal to Max';
                errors.max = 'Max must be greater than or equal to Min';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    number: {
        cfg: () => ({ controlValues: { value: 0 }, id: getUID(), kind: 'number' }),
        controls: (cfg, node) => ({ value: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Value' }, node, value: cfg.controlValues.value }) }),
        inputs: () => ({}),
        kindLabel: 'Number',
        nodeCategory: 'number',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('number'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (typeof node.controlValues.value !== 'number' || isNaN(node.controlValues.value)) {
                errors.value = 'Value must be a number';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    performanceDataCollect: {
        cfg: () => {
            const ret: NodeCfg<'performanceDataCollect'> = {
                controlValues: {
                    dataLabelId: CORE_STATE_DATA_LABEL_ID,
                    timing: FisDataTimingEnum.Start,
                },
                id: getUID(),
                kind: 'performanceDataCollect',
            };
            return ret;
        },
        controls: (cfg, node) => ({
            dataLabelId: new UiFlowNodeControl({
                cfg: {
                    kind: 'performanceDataCollect.dataLabelId',
                    label: 'Data Label',
                    onSelect: (dataLabel: FisDataLabelFragment) => {
                        const controlValues: NodeCfg<'performanceDataCollect'>['controlValues'] = {
                            ...node.cfg.controlValues,
                            dataLabelId: dataLabel.id,
                        };
                        node.cfg = {
                            ...node.cfg,
                            controlValues,
                        };
                        node.flowCfgRef.value = {
                            ...node.flowCfgRef.value,
                            nodes: {
                                ...node.flowCfgRef.value.nodes,
                                [node.id]: node.cfg,
                            },
                        };
                    },
                },
                node,
                value: cfg.controlValues.dataLabelId,
            }),
            timing: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: 'Start', value: FisDataTimingEnum.Start },
                        { label: 'End', value: FisDataTimingEnum.End },
                    ],
                    kind: 'selection',
                    label: 'Timing',
                },
                node,
                value: cfg.controlValues.timing,
            }),
        }),
        inputs: (cfg, node) => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            assetId: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'text', nullable: true },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.assetId,
                }),
                socket: new UiFlowNodeSocket('string'),
                label: 'Asset ID',
            }),
            numericValue: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'number', nullable: true },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.numericValue,
                }),
                socket: new UiFlowNodeSocket('number'),
                label: 'Numeric Value',
            }),
        }),
        kindLabel: 'Performance Data Collect',
        nodeCategory: 'argonautApi',
        outputs: () => ({
            next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.dataLabelId) {
                errors.dataLabelId = 'Data Label is required';
            }
            if (typeof node.socketDefaults?.inputs?.numericValue !== 'number' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'numericValue')) {
                errors.numericValue = 'Number Value is required';
            }
            if (typeof node.socketDefaults?.inputs?.assetId !== 'string' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'assetId')) {
                errors.assetId = 'A Valid Asset Id is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    script: {
        cfg: () => ({ controlValues: { scriptId: '' }, id: getUID(), kind: 'script' }),
        controls: (cfg, node) => ({ scriptId: new UiFlowNodeControl({ cfg: { kind: 'scriptSelect' }, node, value: cfg.controlValues.scriptId }) }),
        inputs: () => ({ trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger') }),
        kindLabel: 'Script',
        nodeCategory: 'other',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const entityData = accessor.entityAsType('appletDesignVersion');
            const validScriptIds = entityData?.config.scripts.map(s => s.id) || [];
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Input is required';
            }
            if (!node.controlValues.scriptId) {
                errors.scriptId = 'Script is required';
            }
            if (!validScriptIds.includes(node.controlValues.scriptId)) {
                errors.scriptId = 'Script is not a valid script';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    slice: {
        cfg: () => ({ id: getUID(), kind: 'slice' }),
        controls: () => ({}),
        inputs: (cfg, node) => ({
            end: new UiFlowNodeInput<UiFlowNodeSocket, number | null>({
                control: new UiFlowNodeControl<number | null>({
                    cfg: { kind: 'number', nullable: true },
                    node,
                    nullable: true,
                    value: (cfg.socketDefaults?.inputs?.end as number) || null,
                }),
                socket: new UiFlowNodeSocket('number'),
                label: 'End Index (optional)',
                index: 2,
            }),
            input: new UiFlowNodeInput({ socket: new UiFlowNodeSocket('string'), label: 'Input', index: 0 }),
            start: new UiFlowNodeInput<UiFlowNodeSocket, number | null>({
                control: new UiFlowNodeControl<number | null>({
                    cfg: { kind: 'number', nullable: true },
                    node,
                    nullable: true,
                    value: (cfg.socketDefaults?.inputs?.start as number) || 0,
                }),
                socket: new UiFlowNodeSocket('number'),
                label: 'Start Index',
                index: 1,
            }),
        }),
        kindLabel: 'Text Slice',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'input')) {
                errors.input = 'Input is required';
            }
            if (typeof node.socketDefaults?.inputs?.start !== 'number' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'start')) {
                errors.start = 'Start is required';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    sparkplugCommander: {
        cfg: () => ({
            controlValues: {
                configDeploymentParameter: {
                    deployParamId: '',
                    deployParamKind: 'sparkplugNodeCfg' as DeployParamKind.sparkplugNodeCfg,
                    type: 'deployParam',
                },
                metricExpressions: [],
                mqttBrokerId: '',
            },
            id: getUID(),
            kind: 'sparkplugCommander',
        }),
        controls: (cfg, node) => ({
            configDeploymentParameter: new UiFlowNodeControl({ cfg: { kind: 'deploymentParameter', label: 'Deployment Parameter' }, node, value: cfg.controlValues.configDeploymentParameter }),
            metricExpressions: new UiFlowNodeControl({
                cfg: {
                    kind: 'button',
                    label: 'Add Metric',
                    onClick: () => createNodeSocket({
                        node,
                        newNodeCfg: {
                            ...node.cfg,
                            controlValues: {
                                ...node.cfg.controlValues,
                                metricExpressions: [
                                    ...node.cfg.controlValues.metricExpressions,
                                    '',
                                ],
                            },
                        },
                    }),
                },
                node,
                value: cfg.controlValues.metricExpressions,
            }),
        }),
        inputs: (cfg, node) => {
            const inputs: ReturnType<NodeInitializer<'sparkplugCommander'>['inputs']> = {
                trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            };

            for (let i = 0; i < cfg.controlValues.metricExpressions.length; i++) {
                const control = new UiFlowNodeControl({
                    cfg: {
                        kind: 'sparkplug.metricExpression',
                        onDelete () {
                            removeNodeSocket({
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        metricExpressions: [
                                            ...node.cfg.controlValues.metricExpressions.slice(0, i),
                                            ...node.cfg.controlValues.metricExpressions.slice(i + 1),
                                        ],
                                    },
                                },
                                node,
                                direction: 'input',
                                removalMethod: {
                                    index: i,
                                    prefix: 'input',
                                    kind: 'indexedIdentifier',
                                },
                            });
                        },
                    },
                    node,
                    value: cfg.controlValues.metricExpressions[i],
                    socketDefault: false,
                });
                control.changeCb = value => {
                    node.cfg.controlValues.metricExpressions[i] = value;
                    node.cfg.controlValues.metricExpressions = [ ...node.cfg.controlValues.metricExpressions ];
                    node.applyControlValue('metricExpressions', node.cfg.controlValues.metricExpressions);
                };
                inputs[`input${i}`] = new UiFlowNodeInput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket('varValue'),
                });
            }
            return inputs;
        },
        kindLabel: 'Sparkplug Commander',
        nodeCategory: 'external',
        outputs: () => {
            return {
                event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next'),
            };
        },
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger is required';
            }
            if (node.controlValues.metricExpressions.length === 0) {
                errors.metricExpressions = 'At least one metric expression is required';
            } else {
                for (let i = 0; i < node.controlValues.metricExpressions.length; i++) {
                    if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === `input${i}`)) {
                        errors[`input${i}`] = 'Input is required';
                    }
                }
            }
            if (!node.controlValues.configDeploymentParameter.deployParamId) {
                errors.configDeploymentParameter = 'Sparkplug Deployment Parameter is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    sparkplugConsumer: {
        cfg: () => ({
            controlValues: {
                metricExpressions: [],
                configDeploymentParameter: {
                    deployParamId: '',
                    deployParamKind: 'sparkplugNodeCfg' as DeployParamKind.sparkplugNodeCfg,
                    type: 'deployParam',
                },
            },
            id: getUID(),
            kind: 'sparkplugConsumer',
        }),
        controls: (cfg, node) => ({
            configDeploymentParameter: new UiFlowNodeControl({ cfg: { kind: 'deploymentParameter', label: 'Sparkplug Deployment Parameter' }, node, value: cfg.controlValues.configDeploymentParameter }),
            metricExpressions: new UiFlowNodeControl({
                cfg: {
                    kind: 'button',
                    label: 'Add Metric',
                    onClick: () => createNodeSocket({
                        node,
                        newNodeCfg: {
                            ...node.cfg,
                            controlValues: {
                                ...node.cfg.controlValues,
                                metricExpressions: [
                                    ...node.cfg.controlValues.metricExpressions,
                                    '',
                                ],
                            },
                        },
                    }),
                },
                node,
                value: cfg.controlValues.metricExpressions,
            }),
        }),
        inputs: () => ({}),
        kindLabel: 'Sparkplug Consumer',
        nodeCategory: 'external',
        outputs: (cfg, node) => {
            const outputs: ReturnType<NodeInitializer<'sparkplugConsumer'>['outputs']> = {
                event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Updated'),
            };
            for (let i = 0; i < cfg.controlValues.metricExpressions.length; i++) {
                const control = new UiFlowNodeControl({
                    cfg: {
                        kind: 'sparkplug.metricExpression',
                        onDelete () {
                            removeNodeSocket({
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        metricExpressions: [
                                            ...node.cfg.controlValues.metricExpressions.slice(0, i),
                                            ...node.cfg.controlValues.metricExpressions.slice(i + 1),
                                        ],
                                    },
                                },
                                node,
                                direction: 'output',
                                removalMethod: {
                                    index: i,
                                    prefix: 'output',
                                    kind: 'indexedIdentifier',
                                },
                            });
                        },
                    },
                    node,
                    value: cfg.controlValues.metricExpressions[i],
                    socketDefault: false,
                });
                control.changeCb = value => {
                    node.cfg.controlValues.metricExpressions[i] = value;
                    node.cfg.controlValues.metricExpressions = [ ...node.cfg.controlValues.metricExpressions ];
                    node.applyControlValue('metricExpressions', node.cfg.controlValues.metricExpressions);
                };
                outputs[`output${i}`] = new UiFlowNodeOutput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket('varValue'),
                });
            }
            return outputs;
        },
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.configDeploymentParameter.deployParamId) {
                errors.configDeploymentParameter = 'Sparkplug Deployment Parameter is required';
            }
            if (!node.controlValues.metricExpressions.length) {
                errors.metricExpressions = 'At least one metric expression is required';
            }
            let atLeastOneOutput = false;
            for (let i = 0; i < node.controlValues.metricExpressions.length; i++) {
                const metricExpr = node.controlValues.metricExpressions[i];
                if (!metricExpr) {
                    errors[`output${i}`] = 'Metric expression is required';
                } else {
                    try {
                        const parsedMetricExpr = getMetricExpr(metricExpr);
                        const validationResult = validateSparkplugMetricExpr(parsedMetricExpr);
                        if (validationResult === false) {
                            errors[`output${i}`] = 'Metric expression is invalid';
                        } else if (validationResult !== true && validationResult !== '') {
                            errors[`output${i}`] = validationResult;
                        }
                        if (Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === `output${i}`)) {
                            atLeastOneOutput = true;
                        }
                    } catch {
                        errors[`output${i}`] = 'Metric expression is invalid';
                    }
                }
            }
            if (!atLeastOneOutput) {
                for (let i = 0; i < node.controlValues.metricExpressions.length; i++) {
                    errors[`output${i}`] = 'At least one output is required';
                }
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event')) {
                errors.event = 'Updated is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    start: {
        canDelete: false,
        canResize: false,
        cfg: () => ({ id: getUID(), kind: 'start' }),
        controls: () => ({}),
        inputs: () => ({}),
        kindLabel: 'Start',
        nodeCategory: 'events',
        supressNode: true,
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: () => true,
    },
    strCast: {
        cfg: () => ({ controlValues: {}, id: getUID(), kind: 'strCast' }),
        controls: (_cfg, _node) => {
            return {};
        },
        inputs: () => ({ input: new ClassicPreset.Input(new UiFlowNodeSocket('varValue'), 'Input') }),
        kindLabel: 'String Value Cast',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Output') }),
        validator: () => true,
    },
    text: {
        cfg: () => ({ controlValues: { value: '' }, id: getUID(), kind: 'text' }),
        controls: (cfg, node) => ({ value: new UiFlowNodeControl({ cfg: { kind: 'text', placeholder: 'Enter a text value' }, node, value: cfg.controlValues.value }) }),
        inputs: () => ({}),
        kindLabel: 'Text',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    timer: {
        cfg: () => ({ controlValues: { dir: 'down', limit: 0 }, id: getUID(), kind: 'timer' }),
        controls: (cfg, node) => ({
            dir: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: 'Stopwatch', value: 'up' },
                        { label: 'Timer', value: 'down' },
                    ],
                    kind: 'selection',
                    label: 'Mode',
                },
                node,
                value: cfg.controlValues.dir,
            }),
            limit: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Limit', min: 0 }, node, value: cfg.controlValues.limit }),
        }),
        inputs: (cfg, node) => ({
            start: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Start'),
            stop: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Stop'),
            reset: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Reset'),
            initialValue: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'number', nullable: true },
                    node,
                    nullable: true,
                    value: cfg.socketDefaults?.inputs?.initialValue,
                }),
                socket: new UiFlowNodeSocket('number'),
                label: 'Initial Value',
            }),
        }),
        kindLabel: 'Timer',
        nodeCategory: 'timeAndDate',
        outputs: () => ({
            done: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Done'),
            tick: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Tick'),
            value: new ClassicPreset.Output(new UiFlowNodeSocket('number'), 'Value'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (node.controlValues.limit < 0) {
                errors.limit = 'Limit must be at least 0';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'start')) {
                errors.start = 'Start is required';
            }
            if (typeof node.socketDefaults?.inputs?.initialValue !== 'number' && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'initialValue')) {
                errors.initialValue = 'Initial value is required';
            }
            if (
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'done') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'tick') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'value')
            ) {
                errors.done = 'At least one output is required';
                errors.tick = 'At least one output is required';
                errors.value = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    txtFormat: {
        cfg: () => ({ controlValues: { formatTemplate: '', inputs: 0 }, id: getUID(), kind: 'txtFormat' }),
        controls: (cfg, node) => ({
            formatTemplate: new UiFlowNodeControl({
                cfg: { kind: 'text', placeholder: 'Format' },
                index: 0,
                node,
                value: cfg.controlValues.formatTemplate,
            }),
            inputs: new UiFlowNodeControl({
                cfg: {
                    kind: 'button',
                    label: 'Add Input',
                    onClick: () => {
                        createNodeSocket({
                            node,
                            newNodeCfg: {
                                ...node.cfg,
                                controlValues: {
                                    ...node.cfg.controlValues,
                                    inputs: node.cfg.controlValues.inputs + 1,
                                },
                            },
                        });
                    },
                },
                index: 1,
                node,
                value: cfg.controlValues.inputs,
            }),
        }),
        inputs: (cfg, node) => {
            const inputs: ReturnType<NodeInitializer<'txtFormat'>['inputs']> = {};
            for (let i = 0; i < cfg.controlValues.inputs; i++) {
                const control = new UiFlowNodeControl({
                    cfg: {
                        kind: 'deletableSocket',
                        label: `$${i}`,
                        onDelete () {
                            removeNodeSocket({
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        inputs: node.cfg.controlValues.inputs - 1,
                                    },
                                },
                                node,
                                direction: 'input',
                                removalMethod: {
                                    index: i,
                                    prefix: 'input',
                                    kind: 'indexedIdentifier',
                                },
                            });
                        },
                    },
                    node,
                    value: null,
                    socketDefault: false,
                });
                inputs[`input${i}`] = new UiFlowNodeInput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket('string'),
                });
            }
            return inputs;
        },
        kindLabel: 'Text Format',
        nodeCategory: 'text',
        outputs: () => ({ output: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Output') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.formatTemplate) {
                errors.formatTemplate = 'Format Template is required';
            }
            if (!node.controlValues.inputs) {
                errors.inputs = 'At least one input is required';
            }
            let atLeastOneInput = false;
            for (let i = 0; i < node.controlValues.inputs; i++) {
                if (Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === `input${i}`)) {
                    atLeastOneInput = true;
                }
            }
            if (!atLeastOneInput) {
                for (let i = 0; i < node.controlValues.inputs; i++) {
                    errors[`input${i}`] = 'At least one input is required';
                }
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'output')) {
                errors.output = 'Output is required';
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
    udpRead: {
        cfg: () => ({ controlValues: { encoding: 'utf8', hostInterface: '', port: 1024 }, id: getUID(), kind: 'udpRead' }),
        controls: (cfg, node) => ({
            encoding: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Encoding', optional: true }, node, value: cfg.controlValues.encoding }),
            hostInterface: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'IP' }, node, value: cfg.controlValues.hostInterface }),
            port: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Port', min: 1024 }, node, value: cfg.controlValues.port }),
        }),
        inputs: () => ({}),
        kindLabel: 'UDP Read',
        nodeCategory: 'external',
        outputs: () => ({
            event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Received'),
            payload: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Payload'),
        }),
        validator: (node, flow) => {

            const errors: Record<string, string> = {};
            if (!node.controlValues.port) {
                errors.port = 'Port is required';
            }
            if (!node.controlValues.hostInterface) {
                errors.hostInterface = 'IP is required';
            }
            if (
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'event') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'payload')
            ) {
                errors.event = 'At least one output is required';
                errors.payload = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    udpSocket: {
        cfg: () => ({
            controlValues: {
                bindAddress: '0.0.0.0',
                bindPort: 1234,
                connectToAddress: '',
                connectToPort: 5678,
                encoding: 'utf8',
            },
            id: getUID(),
            kind: 'udpSocket',
        }),
        controls: (cfg, node) => ({
            bindAddress: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Bind Address' }, node, value: cfg.controlValues.bindAddress }),
            bindPort: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Bind Port' }, node, value: cfg.controlValues.bindPort }),
            connectToAddress: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'Connect To Address', optional: true }, node, value: cfg.controlValues.connectToAddress }),
            connectToPort: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Connect To Port', optional: true }, node, value: cfg.controlValues.connectToPort }),
            encoding: new UiFlowNodeControl({
                cfg: {
                    items: [
                        { label: 'UTF-8', value: 'utf8' },
                        { label: 'Hex', value: 'hex' },
                        { label: 'Ascii', value: 'ascii' },
                    ],
                    kind: 'selection',
                    label: 'Encoding',
                },
                node,
                value: cfg.controlValues.encoding,
            }),
        }),
        inputs: (cfg, node) => ({
            inputTrigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Send Trigger'),
            sendPayload: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'varValue', nullable: true, label: 'Send Payload' },
                    nullable: true,
                    node,
                    value: cfg.socketDefaults?.inputs?.sendPayload,
                }),
                socket: new UiFlowNodeSocket('varValue'),
            }),
        }),
        kindLabel: 'UDP Socket',
        nodeCategory: 'external',
        outputs: () => ({
            onReceivedTrigger: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Received'),
            onSentTrigger: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Send'),
            receivePayload: new ClassicPreset.Output(new UiFlowNodeSocket('string'), 'Received Payload'),
        }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.bindAddress) {
                errors.bindAddress = 'Bind address is required';
            }
            if (!node.controlValues.bindPort) {
                errors.bindPort = 'Bind port is required';
            }
            const hasSendWired = Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'inputTrigger');
            const hasReceievedWired = Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'onReceivedTrigger');
            if (!hasSendWired && !hasReceievedWired) {
                errors.inputTrigger = 'Upd socket must be configured to at least send or receive';
                errors.onReceivedTrigger = 'Upd socket must be configured to at least send or receive';
            }
            if (hasSendWired) {
                if (node.socketDefaults?.inputs?.sendPayload === undefined && !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'sendPayload')) {
                    errors.sendPayload = 'Send payload is required when sending';
                }
                if (!node.controlValues.connectToAddress) {
                    errors.connectToAddress = 'Connect to address is required when sending';
                }
                if (!node.controlValues.connectToPort) {
                    errors.connectToPort = 'Connect to port is required when sending';
                }
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    udpWrite: {
        cfg: () => ({ controlValues: { host: '', port: 1024 }, id: getUID(), kind: 'udpWrite' }),
        controls: (cfg, node) => ({
            host: new UiFlowNodeControl({ cfg: { kind: 'text', label: 'IP' }, node, value: cfg.controlValues.host }),
            port: new UiFlowNodeControl({ cfg: { kind: 'number', label: 'Port', min: 1024 }, node, value: cfg.controlValues.port }),
        }),
        inputs: (cfg, node) => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            payload: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'varValue', nullable: true, label: 'Payload' },
                    nullable: true,
                    node,
                    value: cfg.socketDefaults?.inputs?.sendPayload,
                }),
                socket: new UiFlowNodeSocket('varValue'),
            }),
        }),
        kindLabel: 'UDP Write',
        nodeCategory: 'external',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const errors: Record<string, string> = {};
            if (!node.controlValues.port) {
                errors.port = 'Port is required';
            }
            if (!node.controlValues.host) {
                errors.host = 'IP is required';
            }
            if (
                !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger') &&
                !Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'payload') &&
                node.socketDefaults?.inputs?.sendPayload === undefined
            ) {
                errors.trigger = 'At least one input is required';
                errors.payload = 'At least one input is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    varAssigned: {
        cfg: () => ({ controlValues: { changesOnly: false, varName: '' }, id: getUID(), kind: 'varAssigned' }),
        controls: (cfg, node) => ({
            changesOnly: new UiFlowNodeControl({ cfg: { kind: 'boolean', label: 'Changes Only' }, node, value: cfg.controlValues.changesOnly }),
            varName: new UiFlowNodeControl({ cfg: { kind: 'selectVar', allVars: true }, node, value: cfg.controlValues.varName }),
        }),
        inputs: () => ({}),
        kindLabel: 'Variable Assigned',
        nodeCategory: 'variable',
        outputs: () => ({
            trigger: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Trigger'),
            newValue: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'New Value'),
            oldValue: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'Old Value'),
        }),
        validator: (node, flow) => {
            const validVariables = accessor.appletDesign.availableVars();
            const errors: Record<string, string> = {};
            if (!node.controlValues.varName) {
                errors.varName = 'Variable is required';
            }
            if (!validVariables.includes(node.controlValues.varName)) {
                errors.varName = 'Variable is not a valid variable';
            }
            if (
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'trigger') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'newValue') &&
                !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'oldValue')
            ) {
                errors.trigger = 'At least one output is required';
                errors.newValue = 'At least one output is required';
                errors.oldValue = 'At least one output is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    varRead: {
        cfg: () => ({ controlValues: { varName: '' }, id: getUID(), kind: 'varRead' }),
        controls: (cfg, node) => ({ varName: new UiFlowNodeControl({ cfg: { kind: 'selectVar', allVars: true }, node, value: cfg.controlValues.varName }) }),
        inputs: () => ({}),
        kindLabel: 'Variable Read',
        nodeCategory: 'variable',
        outputs: () => ({ value: new ClassicPreset.Output(new UiFlowNodeSocket('varValue'), 'Value') }),
        validator: (node, flow) => {
            const validVariables = accessor.appletDesign.availableVars();
            const errors: Record<string, string> = {};
            if (!node.controlValues.varName) {
                errors.varName = 'Variable is required';
            }
            if (!validVariables.includes(node.controlValues.varName)) {
                errors.varName = 'Variable is not a valid variable';
            }
            if (!Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'value')) {
                errors.value = 'Value is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    varWrite: {
        cfg: () => ({ controlValues: { varName: '' }, id: getUID(), kind: 'varWrite' }),
        controls: (cfg, node) => ({
            varName: new UiFlowNodeControl({ cfg: { kind: 'selectVar' }, node, value: cfg.controlValues.varName }),
        }),
        inputs: (cfg, node) => ({
            trigger: new ClassicPreset.Input(new UiFlowNodeSocket('event'), 'Trigger'),
            value: new UiFlowNodeInput({
                control: new UiFlowNodeControl({
                    cfg: { kind: 'varValue', nullable: true, label: 'Value' },
                    nullable: true,
                    node,
                    value: cfg.socketDefaults?.inputs?.value,
                }),
                socket: new UiFlowNodeSocket('varValue'),
            }),
        }),
        kindLabel: 'Variable Write',
        nodeCategory: 'variable',
        outputs: () => ({ next: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'Next') }),
        validator: (node, flow) => {
            const validLocalVars = accessor.entityAsType('appletDesignVersion')?.config.varProviders[0].outputs || [];
            const errors: Record<string, string> = {};
            if (!node.controlValues.varName) {
                errors.varName = 'Variable is required';
            }
            if (!validLocalVars.includes(node.controlValues.varName)) {
                errors.varName = 'Variable is not a valid variable';
            }
            if (!Object.values(flow.connections).find(c => c.targetNodeId === node.id && c.targetNodePort === 'trigger')) {
                errors.trigger = 'Trigger is required';
            }
            return Object.keys(errors).length ? errors : true;
        },
    },
    calendar: {
        inputs: () => ({}),
        kindLabel: 'Calendar',
        nodeCategory: 'argonautApi',
        supressNode: () => accessor.featureFlags.calendarNode,
        controls: (cfg, node) => ({
            location: new UiFlowNodeControl({
                node,
                value: cfg.controlValues.location,
                cfg: {
                    kind: 'location',
                },
            }),
            subcribedColumns: new UiFlowNodeControl({
                node,
                value: cfg.controlValues.subcribedColumns,
                cfg: {
                    selectLabel: 'Add Output',
                    kind: 'selectWithCallback',
                    items: () => {
                        const notUsedOutputs = Object.entries(node.cfg.controlValues.subcribedColumns).filter(([ , value ]) => !value).map(([ key ]) => key);
                        return calendarColumnSelectionMap.filter(item => notUsedOutputs.includes(item.value));
                    },
                    onSelect (value) {
                        const typedValue = value as keyof typeof node.cfg.controlValues.subcribedColumns;
                        createNodeSocket({
                            node,
                            newNodeCfg: {
                                ...node.cfg,
                                controlValues: {
                                    ...node.cfg.controlValues,
                                    subcribedColumns: {
                                        ...node.cfg.controlValues.subcribedColumns,
                                        [typedValue]: true,
                                    },
                                },
                            },
                        });
                    },
                },
            }),
            udfColumns: new UiFlowNodeControl({
                node,
                value: cfg.controlValues.udfColumns,
                cfg: {
                    kind: 'selectCalendarUdfOutput',
                    onSelect (value) {
                        const {
                            udfId,
                            udfData,
                        } = value as {
                            udfId: string;
                            udfData: LatestCalendarUdfColumn;
                        };
                        createNodeSocket({
                            node,
                            newNodeCfg: {
                                ...node.cfg,
                                controlValues: {
                                    ...node.cfg.controlValues,
                                    udfColumns: {
                                        ...node.cfg.controlValues.udfColumns,
                                        [udfId]: udfData,
                                    },
                                },
                            },
                        });
                    },
                },
            }),
        }),
        outputs: (cfg, node) => {
            const outputs: {
                [outputKey in keyof NodeSocketKindMap['calendar']['outputs']]: ClassicPreset.Output<UiFlowNodeSocket>;
            } = {
                event: new ClassicPreset.Output(new UiFlowNodeSocket('event'), 'On Update'),
            };

            for (const [ key, value ] of Object.entries(cfg.controlValues.subcribedColumns)) {
                const typedKey = key as keyof typeof calendarColumnLabels;
                if (value) {
                    const control = new UiFlowNodeControl({
                        node,
                        value: '',
                        cfg: {
                            rightAlign: true,
                            kind: 'deletableSocket',
                            label: calendarColumnLabels[typedKey],
                            onDelete () {
                                removeNodeSocket({
                                    node,
                                    direction: 'output',
                                    removalMethod: {
                                        kind: 'uniqueIdentifier',
                                        value: typedKey,
                                    },
                                    newNodeCfg: {
                                        ...node.cfg,
                                        controlValues: {
                                            ...node.cfg.controlValues,
                                            subcribedColumns: {
                                                ...node.cfg.controlValues.subcribedColumns,
                                                [typedKey]: false,
                                            },
                                        },
                                    },
                                });
                            },
                        },
                    });
                    outputs[typedKey] = new UiFlowNodeOutput({
                        control: control as UiFlowNodeControl<unknown>,
                        socket: new UiFlowNodeSocket('string'),
                    });
                    if ([
                        'shiftCurrMetaName',
                        'shiftNextMetaName',
                        'nonProdNextMetaName',
                        'nonProdCurrMetaName',
                        'shiftCurrMetaTimeEnd',
                        'shiftNextMetaTimeEnd',
                        'nonProdNextMetaTimeEnd',
                        'nonProdCurrMetaTimeEnd',
                        'shiftNextMetaTimeStart',
                        'shiftCurrMetaTimeStart',
                        'nonProdCurrMetaTimeStart',
                        'nonProdNextMetaTimeStart',
                    ].includes(typedKey)) {
                        outputs[typedKey] = new UiFlowNodeOutput({
                            control: control as UiFlowNodeControl<unknown>,
                            socket: new UiFlowNodeSocket('string'),
                        });
                    } else {
                        outputs[typedKey] = new UiFlowNodeOutput({
                            control: control as UiFlowNodeControl<unknown>,
                            socket: new UiFlowNodeSocket('number'),
                        });
                    }
                    // else if (typedKey === 'rawCalenderUdfData') {
                    //     // outputs[typedKey] = new UiFlowNodeOutput({
                    //     //     control: control as UiFlowNodeControl<unknown>,
                    //     //     socket: new UiFlowNodeSocket('workOrderMaterialData'),
                    //     // });
                    // }
                }
            }
            for (const [ udfColId, udfColData ] of Object.entries(cfg.controlValues.udfColumns)) {
                const control = new UiFlowNodeControl({
                    node,
                    value: '',
                    cfg: {
                        rightAlign: true,
                        kind: 'deletableSocket',
                        label: `${udfColData.eventName} - ${udfColData.occurance === 'current' ? 'Current' : 'Next'} - ${udfColData.udfName}`,
                        onDelete () {
                            const newNodeUdfColumns = { ...node.cfg.controlValues.udfColumns };
                            delete newNodeUdfColumns[udfColId];
                            removeNodeSocket({
                                node,
                                direction: 'output',
                                removalMethod: {
                                    kind: 'uniqueIdentifier',
                                    value: `udfColumns_${udfColId}`,
                                },
                                newNodeCfg: {
                                    ...node.cfg,
                                    controlValues: {
                                        ...node.cfg.controlValues,
                                        udfColumns: newNodeUdfColumns,
                                    },
                                },
                            });
                        },
                    },
                });
                outputs[`udfColumns_${udfColId}`] = new UiFlowNodeOutput({
                    control: control as UiFlowNodeControl<unknown>,
                    socket: new UiFlowNodeSocket(udfColData.dataType === ArgoUdfColumnDataTypeEnum.Number ? 'number' : 'string'),
                });
            }
            return outputs;
        },
        cfg: () => {
            const ret: NodeCfg<'calendar'> = {
                id: getUID(),
                kind: 'calendar',
                controlValues: {
                    udfColumns: {},
                    location: { id: '' },
                    subcribedColumns: {
                        shiftNextMetaName: false,
                        shiftCurrMetaName: false,
                        // rawCalenderUdfData: false,
                        nonProdCurrMetaName: false,
                        nonProdNextMetaName: false,
                        shiftNextMetaTimeEnd: false,
                        shiftCurrMetaTimeEnd: false,
                        shiftNextMetaTimeStart: false,
                        shiftCurrMetaTimeStart: false,
                        nonProdCurrMetaTimeEnd: false,
                        nonProdNextMetaTimeEnd: false,
                        nonProdNextMetaTimeStart: false,
                        nonProdCurrMetaTimeStart: false,
                        shiftNextTimeSecondsUntil: false,
                        shiftCurrTimeElapsedSeconds: false,
                        nonProdNextTimeSecondsUntil: false,
                        shiftCurrTimeRemainingSeconds: false,
                        nonProdCurrTimeElapsedSeconds: false,
                        shiftCurrTimeElapsedProductive: false,
                        nonProdCurrTimeRemainingSeconds: false,
                        shiftCurrTimeRemainingProductive: false,
                    },
                },
            };
            return ret;
        },
        validator: (node, flow) => {
            const errors: Record<string, string> = {};

            if ('deployParamKind' in node.controlValues.location) {
                if (!node.controlValues.location.deployParamId) {
                    errors.location = 'Location deployment parameter is required';
                }
            } else if (!node.controlValues.location.id) {
                errors.location = 'Location is required';
            }

            const subscribedItems = [
                ...Object.entries(node.controlValues.subcribedColumns).filter(([ , value ]) => value).map(([ key ]) => key),
                ...Object.keys(node.controlValues.udfColumns).map(key => `udfColumns_${key}`),
            ];

            if (!subscribedItems.length) {
                errors.subcribedColumns = 'At least one output is required';
            }

            if (!subscribedItems.find(key => Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === key)) && !Object.values(flow.connections).find(c => c.sourceNodeId === node.id && c.sourceNodePort === 'next')) {
                errors.next = 'At least one output needs to be wired';
                subscribedItems.forEach(key => {
                    errors[key] = 'At least one output needs to be wired';
                });
            }

            return Object.keys(errors).length ? errors : true;
        },
    },
};
