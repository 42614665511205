import {
    ArgoMaterialStatusEnum,
    GetContextDefaultModelDocument,
    GetMaterialDocument,
    GetModelNumberDocument,
    ModelNumberFragment,
} from '@redviking/argonaut-core-ui/types/db';
import { accessor } from '../store';
import { messages } from 'src/i18n/i18n';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { validateMaterialSelectAcl, validateMaterialUpdateAcl } from './material.validations';
import { entityDetailRoute } from 'src/components/EntityDetail/entity-route-util';
import modelRoutes from 'src/model-number/model-number.routes';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { Notify } from 'src/notifications';
import { entityPageBannerTitle } from 'src/util/formatting';
import matClassRoutes from 'src/material-class/material-class.routes';
import { type MaterialEntity, createMaterialEntity, saveMaterialEntity } from './material.entity';
import {
    type AttributeSettingForAttributeLinker,
} from '@redviking/argonaut-util/types/attribute-settings/attribute-settings-config.zod';

export default [
    // nav group route
    {
        path: '/material',
        name: 'matNav',
        component: RouterViewSlider,
        redirect: { name: 'materialSearch' },
        meta: {
            navigationGuard: () => validateMaterialSelectAcl(),
            navbarTitle: messages.entityNamesPlural.material,
            navbarIcon: 'mdi-nut',
            transition: 'vertical',
        },
        children: [
            ...matClassRoutes,
            {
                name: 'materialListSlider',
                path: '',
                redirect: { name: 'materialSearch' },
                component: RouterViewSlider,
                meta: {
                    transition: 'horizontal',
                },
                children: [
                    {
                        name: 'materialSearch',
                        path: 'search',
                        component: () => import(/* webpackChunkName: "material" */ './MaterialSearch.vue'),
                        meta: {
                            pageBannerTitle: 'Material Search',
                            pageBannerSubtitle: 'Search for a desired Material',
                            navigationGuard: () => validateMaterialSelectAcl(),
                        },
                    },
                    entityDetailRoute<'material'>({
                        path: '/material/detail/:materialId',
                        name: 'materialMaintenance',
                        component: RouteEntityDetail,
                        redirect: to => ({ name: 'materialDetail', params: to.params }),
                        meta: {
                            // returnRoute: (route: Route) => ({ name: 'materialClassList', query: route.query }),
                            entityType: 'material',
                            canEdit: validateMaterialUpdateAcl,
                            pageBannerSubtitle: () => entityPageBannerTitle('material', accessor.entityAsType('material')?.serial_number),
                            pageBannerTitle: () => messages.material.material.entityName,
                            getEntity: async (to) => {
                                if (to.query.mode === 'create') {
                                    const matModId = typeof to.query.materialModelId === 'string' ? to.query.materialModelId : null;
                                    let actualDefaultModelNumber: ModelNumberFragment | null = null;
                                    if (matModId) {
                                        const { modelNumber } = await gqlClient.request({
                                            document: GetModelNumberDocument,
                                            variables: {
                                                modelNumberId: matModId,
                                            },
                                        });
                                        actualDefaultModelNumber = modelNumber || null;
                                    } else {
                                        const { defaultModelNumber } = await gqlClient.request({
                                            document: GetContextDefaultModelDocument,
                                            variables: {
                                                contextId: accessor.auth.contextId,
                                            },
                                        });
                                        actualDefaultModelNumber = defaultModelNumber[0];

                                    }
                                    const entityData: MaterialEntity = {
                                        id: to.params.materialId,
                                        serial_number: '',
                                        enabled: true,
                                        udfData: [],
                                        materialAttributes: [],
                                        labelPrint: false,
                                        status: ArgoMaterialStatusEnum.None,
                                        materialClass: {
                                            id: '',
                                            attributeSettings: actualDefaultModelNumber?.material_class.material_class_attribute_settings.map(setting => ({
                                                config: setting.config,
                                                attributeSchema: {
                                                    id: setting.attribute_schema.id,
                                                    name: setting.attribute_schema.name,
                                                    description: setting.attribute_schema.description,
                                                    properties: setting.attribute_schema.attribute_schema_definitions,
                                                },
                                            })) || [],
                                            udfSchemas: actualDefaultModelNumber?.material_class_udf_schemas || [],
                                        },
                                        materialModel: {
                                            attributeSettings: actualDefaultModelNumber?.material_model_attribute_settings.map(setting => ({
                                                config: setting.config,
                                                attributeSchema: {
                                                    id: setting.attribute_schema.id,
                                                    name: setting.attribute_schema.name,
                                                    description: setting.attribute_schema.description,
                                                    properties: setting.attribute_schema.attribute_schema_definitions,
                                                },
                                            })) || [],
                                            sn_format: actualDefaultModelNumber?.sn_format,
                                            id: actualDefaultModelNumber
                                                ? actualDefaultModelNumber.id
                                                : matModId || '00000000-0000-0000-0000-000000000000',
                                        },
                                        ignoreSchemaWarning: false,
                                        workOrderId: null,
                                    };
                                    return {
                                        entity: entityData,
                                    };
                                }
                                try {
                                    const { material } = await gqlClient.request({
                                        document: GetMaterialDocument,
                                        variables: {
                                            materialId: to.params.materialId,
                                        },
                                    });
                                    const materialData = material[0];
                                    const ret: MaterialEntity = {
                                        labelPrint: false,
                                        snInputValues: [],
                                        id: materialData.id,
                                        enabled: materialData.enabled,
                                        udfData: materialData.udfData,
                                        created_at: materialData.created_at,
                                        serial_number: materialData.serial_number,
                                        status: materialData.status,
                                        materialAttributes: materialData.materialAttributes,
                                        materialClass: {
                                            id: materialData.material_model.material_class.id,
                                            udfSchemas: materialData.material_model.material_class_udf_schemas,
                                            attributeSettings: materialData.material_model.material_class.material_class_attribute_settings.map<AttributeSettingForAttributeLinker>(setting => ({
                                                config: setting.config,
                                                attributeSchema: {
                                                    id: setting.attribute_schema.id,
                                                    name: setting.attribute_schema.name,
                                                    description: setting.attribute_schema.description,
                                                    properties: setting.attribute_schema.attribute_schema_definitions.map(prop => ({
                                                        id: prop.id,
                                                        name: prop.name,
                                                        type: prop.type,
                                                    })),
                                                },
                                            })),
                                        },
                                        materialModel: {
                                            id: materialData.material_model.id,
                                            sn_format: materialData.material_model.sn_format,
                                            attributeSettings: materialData.material_model.material_model_attribute_settings.map<AttributeSettingForAttributeLinker>(setting => ({
                                                config: setting.config,
                                                attributeSchema: {
                                                    id: setting.attribute_schema.id,
                                                    name: setting.attribute_schema.name,
                                                    description: setting.attribute_schema.description,
                                                    properties: setting.attribute_schema.attribute_schema_definitions.map(prop => ({
                                                        id: prop.id,
                                                        name: prop.name,
                                                        type: prop.type,
                                                    })),
                                                },
                                            })),
                                        },
                                        ignoreSchemaWarning: false,
                                        workOrderId: materialData?.workOrderMaterial?.workOrderId || null,
                                    };
                                    return {
                                        entity: ret,
                                    };
                                } catch (e) {
                                    Notify.error(e);
                                    throw e;
                                }
                            },
                            saveEntity: saveMaterialEntity,
                            createEntity: createMaterialEntity,
                        },
                        children: [
                            {
                                path: 'detail',
                                name: 'materialDetail',
                                component: () => import(/* webpackChunkName: "material" */ './MaterialProcessReport.view.vue'),
                                meta: {
                                    tab: {
                                        label: 'Detail',
                                        icon: 'mdi-chart-line-stacked',
                                    },
                                    pageBannerSubtitle: 'Material Process Status Report',
                                    pageBannerTitle: () => accessor.entityAsType('material')?.serial_number || 'material',
                                },
                            },
                            {
                                path: 'material-attribute-list',
                                name: 'materialAttributeList',
                                component: () => import(/* webpackChunkName: "material" */ './MaterialAttributeList.view.vue'),
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Attributes',
                                        icon: 'mdi-animation-outline',
                                    },
                                    navigationGuard: () => validateMaterialSelectAcl(),
                                    pageBannerTitle: () => {
                                        const { material } = accessor;
                                        return material ? `${material.serial_number}` : 'Attributes';
                                    },
                                },
                            },
                            {
                                path: 'material-attribute-history',
                                name: 'materialAttributeHistory',
                                component: () => import(/* webpackChunkName: "material" */ './MaterialAttributeHistory.view.vue'),
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Attribute History',
                                        icon: 'mdi-history',
                                    },
                                    navigationGuard: () => validateMaterialSelectAcl(),
                                    pageBannerTitle: () => {
                                        const { material } = accessor;
                                        return material ? `${material.serial_number}` : 'Attribute History';
                                    },
                                },
                            },
                        ],
                    }),
                ],
            },
            ...modelRoutes,
        ],
    },
];
