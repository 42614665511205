export const messages = {
    errorText: 'Error Text',
    validation: 'Validation',
    workOrder: {
        actualStart: 'Actual Start',
        addNew: 'Add new work order',
        config: 'Work Order Config',
        confirmDelete: 'Are you sure you want to delete this work order?',
        create: 'Create Work Order',
        hide: 'Hide Work Order',
        materialCompletedCount: 'Completed Material',
        materialModel: 'Material Model',
        materialOtherCount: 'Incomplete Material',
        noneFound: 'No Work Orders Found',
        notifySaved: 'Work Order Saved!',
        orderNumber: 'Work Order',
        plannedStart: 'Planned Start',
        restriction: 'Required to Complete',
        status: 'Status',
        statusNote: 'Status Note',
        targetQuantity: 'Target Quantity',
        unnamed: 'New Work Order',
        unrestricted: 'Restriction Options',
        view: 'View Work Order',
        workOrder: 'Work Order',
        workOrders: 'Work Orders',
    },
};
