import { EntityTypeMap } from 'types/entity';
import { messages } from '@redviking/argonaut-core-ui/src/i18n/i18n';
import { DeployParamKind } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { AppletDeploymentVersionEntity } from '../deployment.entity';

export function validateDeploymentParams (deployment: AppletDeploymentVersionEntity): void {
    // verify all required deployment params are set
    for (const ldv of deployment.linkedDesignVersions) {
        const deployParams = ldv.deployParams || [];

        for (const dp of deployParams) {
            // perform different validations depending on the type of the deployment param
            if (dp.deployParamKind === DeployParamKind.localVarValue) {
                // no validation needed, these are always optional
            } else if (dp.deployParamKind === DeployParamKind.location) {
                if (!ldv.config.deployParamVals[dp.deployParamId]?.val && !deployment.config.deployParamVals[dp.deployParamId]?.val) {
                    throw new Error(`Location Deployment Parameter "${dp.deployParamId}" is required`);
                }
            } else if (dp.deployParamKind === DeployParamKind.sparkplugNodeCfg) {
                const ldvDepVal = ldv.config.deployParamVals[dp.deployParamId]?.val;
                const entityDepVal = deployment.config.deployParamVals[dp.deployParamId]?.val;
                if (!ldvDepVal && !entityDepVal) {
                    throw new Error(`Sparkplug Node Deployment Parameter "${dp.deployParamId}" is required`);
                }
                if (
                    (ldvDepVal && typeof ldvDepVal === 'object' && Object.values(ldvDepVal).some(value => value === '')) &&
                    (entityDepVal && typeof entityDepVal === 'object' && Object.values(entityDepVal).some(value => value === ''))
                ) {
                    throw new Error(`Sparkplug Node Deployment Parameter "${dp.deployParamId}" cannot be blank`);
                }
            } else {
                // @ts-expect-error exhaustive cases
                const unknownKind: string = dp.deployParamKind;
                throw new Error(`Unknown deploy param kind: ${unknownKind}`);
            }
        }
    }
}

export function validateAppletDeployment (entityData: EntityTypeMap['appletDeployment']) {
    // ensure at least one design is linked
    if (entityData.linkedDesignVersions.length === 0) {
        throw new Error('You must pick at least one design to include in the deployment');
    }

    // ensure at least one design is linked
    if (entityData.linkedDesignVersions.length === 0) {
        throw new Error('You must pick at least one design to include in the deployment');
    }

    if (!entityData.name) {
        throw new Error(messages.applet.deployment.errors.noName);
    }

    validateDeploymentParams(entityData);
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept('@redviking/argonaut-core-ui/src/routing');
}
