import { mutationTree } from 'typed-vuex';

// TODO before removing appletFlows feature flag, we should do final review of config and naming with team.
// This type needs to be consistent with the feature flags set by the local helm chart. See `values.local.yaml`
export type FeatureFlags = Record<'materialMarriage' | 'appletFlows' | 'calendarNode', boolean>;

const routerState = {
    k8sChartNamespace: '',
    featureFlags: {} as FeatureFlags,
};

const mutations = mutationTree(routerState, {
    setChartNamespace (state, namespace: string) {
        state.k8sChartNamespace = namespace;
    },
    setFeatureFlags (state, flags: FeatureFlags) {
        state.featureFlags = flags;
    },
});

export default {
    state: routerState,
    mutations,
};
