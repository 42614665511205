import Vue from 'vue';
import Vuex from 'vuex';
import {
    mutationTree,
    useAccessor,
} from 'typed-vuex';
import { vuexPersist } from './vuex-persist';

import rolesStoreOpts from '../roles/roles.store';
import auth from '../auth/auth.store';
import links from '../links/link.store';
import attrStoreOpts from '../attributes/attributes.store';
import locationStoreOpts from '../locations/locations.store';
import contextStoreOpts from '../context/context.store';
import partsStoreOpts from '../model-number/model-number.store';
import materialStoreOpts from '../material/material.store';
import materialClassOpts from '../material-class/material-class.store';
import routerStoreOpts from '../routing/router.store';
import usersStoreOpts from '../users/users.store';
import notify from '../notifications/notifications.store';
import uomStoreOpts from '../uoms/uom.store';
import fileStoreOpts from '../files/file.store';
import deviceStoreOpts from '../device/device.store';
import entityDetailStoreOpts from '../components/EntityDetail/entity-detail.store';
import scheduleStoreOpts from '../schedules/schedule.store';
import argoGrid from 'src/components/ArgoGrid/argo-grid.store';
import appletDesignStore from '@redviking/argonaut-core-ui/src/applet/design/appletDesign.store';
import appletDeploymentStore from '@redviking/argonaut-core-ui/src/applet/deployment/appletDeployment.store';
import performanceStore from '@redviking/argonaut-core-ui/src/performance/performance.store';

const rootState = () => ({
    navbarOpen: null as boolean | null, // setting the initial value to null allows the component to automatically determine the state it should have
    ...rolesStoreOpts.state,
    ...attrStoreOpts.state,
    ...locationStoreOpts.state,
    ...contextStoreOpts.state,
    ...partsStoreOpts.state,
    ...routerStoreOpts.state,
    ...usersStoreOpts.state,
    ...uomStoreOpts.state,
    ...fileStoreOpts.state,
    ...materialStoreOpts.state,
    ...materialClassOpts.state,
    ...deviceStoreOpts.state,
    ...entityDetailStoreOpts.state,
    ...scheduleStoreOpts.state,
    ...links.state,
});

export type RootState = ReturnType<typeof rootState>;

const mutations = mutationTree(rootState, {
    toggleNavbar (state, isOpen?: boolean | undefined) {
        state.navbarOpen = typeof isOpen === 'undefined' ? !state.navbarOpen : isOpen;
    },
    ...rolesStoreOpts.mutations,
    ...attrStoreOpts.mutations,
    ...locationStoreOpts.mutations,
    ...contextStoreOpts.mutations,
    ...partsStoreOpts.mutations,
    ...routerStoreOpts.mutations,
    ...usersStoreOpts.mutations,
    ...uomStoreOpts.mutations,
    ...fileStoreOpts.mutations,
    ...materialStoreOpts.mutations,
    ...materialClassOpts.mutations,
    ...deviceStoreOpts.mutations,
    ...entityDetailStoreOpts.mutations,
    ...scheduleStoreOpts.mutations,
    ...links.mutations,
});

Vue.use(Vuex);

const storePattern = {
    state: rootState,
    mutations,
    getters: {
        ...entityDetailStoreOpts.getters,
    },
    actions: {
        ...uomStoreOpts.actions,
    },
    modules: {
        auth,
        notify,
        argoGrid,
        performance: performanceStore,
        appletDesign: appletDesignStore,
        appletDeployment: appletDeploymentStore,
    },
    plugins: [ vuexPersist.plugin ],
};

export const store = new Vuex.Store(storePattern);
export const accessor = useAccessor(store, storePattern);

Vue.prototype.$accessor = accessor;
