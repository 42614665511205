import { accessor } from '../store';
import { messages } from 'src/i18n/i18n';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { entityDetailRoute } from '../components/EntityDetail/entity-route-util';
import {
    validateEventSelectAcl,
    validateEventUpdateAcl,
    validateScheduleSelectAcl,
    validateScheduleUpdateAcl,
} from './schedule.validations';
import {
    ArgoScheduleModeEnum,
    GetCalendarDocument,
    GetScheduleDocument,
} from '../../types/db';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { Notify } from '../notifications';
import { entityPageBannerTitle } from 'src/util/formatting';
import { v4 } from 'uuid';
import { createCalendar, createSchedule, updateCalendar, updateSchedule } from './schedule.entity';
import { Route } from 'vue-router';
import { fetchEventEntity, saveEvent } from './events/event.entity';
import type { EntityTypeMap } from 'types/entity';


export default [
    {
        path: '/schedules',
        name: 'schedules',
        component: RouterViewSlider,
        redirect: { name: 'calendarList' },
        meta: {
            navigationGuard: () => validateScheduleSelectAcl(),
            navbarTitle: messages.schedule.titles.schedule.nav,
            navbarIcon: 'mdi-calendar',
            transition: 'vertical',
        },
        children: [
            {
                name: 'calendarList',
                path: '',
                component: () => import(/* webpackChunkName: "schedules" */ './calendars/CalendarList.vue'),
                meta: {
                    pageBannerTitle: messages.schedule.titles.calendar.pageBannerTitle,
                    pageBannerSubtitle: messages.schedule.titles.calendar.pageBannerSubtitle,
                    navbarTitle: messages.schedule.titles.calendar.navList,
                    navbarIcon: 'mdi-calendar-outline',

                },
            },
            entityDetailRoute<'calendar'>({
                path: 'calendar/:calendarId',
                name: 'calendarMaintenence',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'calendarCalendar', params: to.params }),
                meta: {
                    pageBannerTitle: () => entityPageBannerTitle('calendar', accessor.entityAsType('calendar')?.calendar.name),
                    returnRoute: { name: 'calendarList' },
                    canEdit: validateScheduleUpdateAcl,
                    entityType: 'calendar',
                    saveEntity: updateCalendar,
                    createEntity: createCalendar,
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            const entityData: EntityTypeMap['calendar']['calendar'] = {
                                __typename: 'argo_calendar',
                                id: to.params.calendarId,
                                name: 'Unnamed Calendar',
                                description: '',
                                enabled: true,
                                createdAt: Date.now(),
                                schedule_id: '',
                                is_default: false,
                                timezone: 'America/Detroit',
                                schedule: {
                                    __typename: 'argo_schedule',
                                    id: v4(),
                                    name: '',
                                    schedule_events: [],
                                },
                                createdBy: accessor.auth.currentUser,
                            };
                            return {
                                entity: {
                                    calendar: entityData,
                                    calendar_events: {
                                        updates: [],
                                        inserts: [],
                                        deletes: [],
                                    },
                                },
                            };
                        }
                        const { calendar } = await gqlClient.request({
                            document: GetCalendarDocument,
                            variables: {
                                calendarId: to.params.calendarId,
                            },
                        });
                        if (calendar) {
                            const entityData: EntityTypeMap['calendar']['calendar'] = calendar;
                            return {
                                entity: {
                                    calendar: entityData,
                                    calendar_events: {
                                        updates: [],
                                        inserts: [],
                                        deletes: [],
                                    },
                                },
                            };
                        } else {
                            const err = new Error('Unknown Calendar');
                            Notify.error(err);
                            throw err;
                        }
                    },
                },
                children: [
                    {
                        path: 'calendar',
                        name: 'calendarCalendar',
                        component: () => import(/* webpackChunkName: "schedule" */ './calendars/CalendarView.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Calendar',
                                icon: 'mdi-calendar',
                            },
                        },
                    },
                    {
                        path: 'settings',
                        name: 'calendarSettings',
                        component: () => import(/* webpackChunkName: "schedule" */ './calendars/CalendarSettings.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                        },
                    },
                ],
            }),
            {
                name: 'scheduleList',
                path: '/schedule-list',
                component: () => import(/* webpackChunkName: "schedule" */ './schedules/ScheduleView.vue'),
                meta: {
                    pageBannerTitle: messages.schedule.titles.schedule.pageBannerTitle,
                    pageBannerSubtitle: messages.schedule.titles.schedule.pageBannerSubtitle,
                    navbarTitle: messages.schedule.titles.schedule.navList,
                    navbarIcon: 'mdi-calendar-month-outline',
                },
            },
            {
                path: '/event-list',
                name: 'event-list',
                redirect: (to: Route) => ({ name: 'eventList', params: to.params }),
                component: RouterViewSlider,
                meta: {
                    navbarTitle: messages.schedule.titles.event.nav,
                    navbarIcon: 'mdi-calendar-today-outline',
                },
                children: [
                    {
                        name: 'eventList',
                        path: '',
                        component: () => import(/* webpackChunkName: "events" */ './events/Event.view.vue'),
                        meta: {
                            navigationGuard: () => validateEventSelectAcl(),
                            pageBannerSubtitle: messages.schedule.titles.event.pageBannerSubtitle,
                            pageBannerTitle: messages.schedule.titles.event.pageBannerTitle,
                        },
                    },
                    entityDetailRoute<'event'>({
                        path: ':eventId',
                        name: 'eventEdc',
                        component: RouteEntityDetail,
                        redirect: to => ({ name: 'eventSettings', params: to.params }),
                        meta: {
                            canEdit: validateEventUpdateAcl,
                            entityType: 'event',
                            returnRoute: { name: 'eventList' },
                            pageBannerTitle: () => entityPageBannerTitle('event', accessor.entityAsType('event')?.event.name),
                            saveEntity: saveEvent,
                            getEntity: fetchEventEntity,
                        },
                        children: [
                            {
                                path: 'settings',
                                name: 'eventSettings',
                                component: () => import(/* webpackChunkName: "events" */ './events/EventSettings.view.vue'), // a component for the settings page content
                                meta: {
                                    tab: { // the presence of this prop indicates this is a tab
                                        label: 'Settings',
                                        icon: 'mdi-cog',
                                    },
                                    navigationGuard: validateEventUpdateAcl,
                                },
                            },
                        ],
                    }),
                ],
            },
            entityDetailRoute<'schedule'>({
                path: 'schedule/:scheduleId',
                name: 'scheduleMaintenence',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'scheduleSettings', params: to.params }),
                meta: {
                    pageBannerTitle: () => entityPageBannerTitle('schedule', accessor.entityAsType('schedule')?.schedule.name),
                    canEdit: validateScheduleUpdateAcl,
                    entityType: 'schedule',
                    returnRoute: { name: 'scheduleList' },
                    saveEntity: updateSchedule,
                    createEntity: createSchedule,
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            const entityData: EntityTypeMap['schedule']['schedule'] = {
                                id: to.params.scheduleId,
                                name: 'Unnamed Schedule',
                                enabled: true,
                                mode: ArgoScheduleModeEnum.Weekly,
                                schedule_events: [],
                            };
                            return {
                                entity: {
                                    schedule: entityData,
                                },
                            };
                        }
                        const { schedule } = await gqlClient.request({
                            document: GetScheduleDocument,
                            variables: {
                                scheduleId: to.params.scheduleId,
                            },
                        });
                        if (schedule) {
                            console.log('schedule', schedule);
                            const entityData: EntityTypeMap['schedule']['schedule'] = {
                                enabled: schedule.enabled,
                                id: schedule.id,
                                mode: schedule.mode,
                                name: schedule.name,
                                schedule_events: schedule.schedule_events.map(e => {
                                    if (!e.event || !e.event.event_type) {
                                        throw new Error('Event not found when pulling schedule entity');
                                    }
                                    return {
                                        id: e.id,
                                        day: e.day,
                                        range: e.range,
                                        udf_default_data: e.udf_data,
                                        event: {
                                            id: e.event.id,
                                            name: e.event.name,
                                            meta: e.event.meta,
                                            event_type: {
                                                id: e.event.event_type.id,
                                                priority: e.event.event_type.priority,
                                                isProductive: !e.event.event_type.is_non_productive,
                                            },
                                        },
                                    };
                                }),
                            };
                            return {
                                entity: { schedule: entityData },
                            };
                        } else {
                            const err = new Error('Unknown Schedule');
                            Notify.error(err);
                            throw err;
                        }
                    },
                },
                children: [
                    {
                        path: 'schedule-edit',
                        name: 'scheduleEdit',
                        component: () => import(/* webpackChunkName: "schedule" */ './schedules/ScheduleCalendar.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Schedule',
                                icon: 'mdi-calendar',
                            },
                        },
                    },
                    {
                        path: 'settings',
                        name: 'scheduleSettings',
                        component: () => import(/* webpackChunkName: "schedule" */ './schedules/ScheduleSettings.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                        },
                    },
                ],
            }),
        ],
    },
];
