import { z } from 'zod';
import { sparkplugAppletNodeCfgSchema } from '../sparkplug-node-cfg.zod';
import { varpConfigBase } from './var-provider.zod';

export const sparkplugProviderSchema = varpConfigBase.extend({
    type: z.literal('sparkplug'),
    attrs: z.object({
      nodeCfg: sparkplugAppletNodeCfgSchema,
      vars: z.object({
        varName: z.string(),
        metric: z.string(),
      }).array(),
    }),
  });
