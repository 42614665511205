/* eslint-disable */
// @ts-nocheck
export * from '@redviking/argonaut-util/types/db';
export * from './db-ops';

import * as Operations from './db-ops';
import * as VueApolloComposable from '@redviking/argonaut-core-ui/src/util/gql-client';
import * as VueCompositionApi from 'vue';
export type ReactiveFunction<TParam> = () => TParam;


/**
 * __useGetAllAclsQuery__
 *
 * To run a query within a Vue component, call `useGetAllAclsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAclsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllAclsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sortby: // value for 'sortby'
 *   }
 * );
 */
export function useGetAllAclsQuery(variables?: Operations.GetAllAclsQueryVariables | VueCompositionApi.Ref<Operations.GetAllAclsQueryVariables> | ReactiveFunction<Operations.GetAllAclsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllAclsQuery, Operations.GetAllAclsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllAclsQuery, Operations.GetAllAclsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllAclsQuery, Operations.GetAllAclsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllAclsQuery, Operations.GetAllAclsQueryVariables>(Operations.GetAllAclsDocument, variables, options);
          }
export type GetAllAclsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllAclsQuery, Operations.GetAllAclsQueryVariables>;

/**
 * __useDesignVersionSelectorQuery__
 *
 * To run a query within a Vue component, call `useDesignVersionSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignVersionSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDesignVersionSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useDesignVersionSelectorQuery(variables: Operations.DesignVersionSelectorQueryVariables | VueCompositionApi.Ref<Operations.DesignVersionSelectorQueryVariables> | ReactiveFunction<Operations.DesignVersionSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.DesignVersionSelectorQuery, Operations.DesignVersionSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.DesignVersionSelectorQuery, Operations.DesignVersionSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.DesignVersionSelectorQuery, Operations.DesignVersionSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.DesignVersionSelectorQuery, Operations.DesignVersionSelectorQueryVariables>(Operations.DesignVersionSelectorDocument, variables, options);
          }
export type DesignVersionSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.DesignVersionSelectorQuery, Operations.DesignVersionSelectorQueryVariables>;

/**
 * __useAppletDesignVersionQuery__
 *
 * To run a query within a Vue component, call `useAppletDesignVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppletDesignVersionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAppletDesignVersionQuery(
 *   {
 *      id: // value for 'id'
 *   }
 * );
 */
export function useAppletDesignVersionQuery(variables: Operations.AppletDesignVersionQueryVariables | VueCompositionApi.Ref<Operations.AppletDesignVersionQueryVariables> | ReactiveFunction<Operations.AppletDesignVersionQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.AppletDesignVersionQuery, Operations.AppletDesignVersionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.AppletDesignVersionQuery, Operations.AppletDesignVersionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.AppletDesignVersionQuery, Operations.AppletDesignVersionQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.AppletDesignVersionQuery, Operations.AppletDesignVersionQueryVariables>(Operations.AppletDesignVersionDocument, variables, options);
          }
export type AppletDesignVersionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.AppletDesignVersionQuery, Operations.AppletDesignVersionQueryVariables>;

/**
 * __useAppletDesignsQuery__
 *
 * To run a query within a Vue component, call `useAppletDesignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppletDesignsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAppletDesignsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useAppletDesignsQuery(variables: Operations.AppletDesignsQueryVariables | VueCompositionApi.Ref<Operations.AppletDesignsQueryVariables> | ReactiveFunction<Operations.AppletDesignsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.AppletDesignsQuery, Operations.AppletDesignsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.AppletDesignsQuery, Operations.AppletDesignsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.AppletDesignsQuery, Operations.AppletDesignsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.AppletDesignsQuery, Operations.AppletDesignsQueryVariables>(Operations.AppletDesignsDocument, variables, options);
          }
export type AppletDesignsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.AppletDesignsQuery, Operations.AppletDesignsQueryVariables>;

/**
 * __useAttributeSchemaQuery__
 *
 * To run a query within a Vue component, call `useAttributeSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeSchemaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAttributeSchemaQuery(
 *   {
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useAttributeSchemaQuery(variables: Operations.AttributeSchemaQueryVariables | VueCompositionApi.Ref<Operations.AttributeSchemaQueryVariables> | ReactiveFunction<Operations.AttributeSchemaQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.AttributeSchemaQuery, Operations.AttributeSchemaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.AttributeSchemaQuery, Operations.AttributeSchemaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.AttributeSchemaQuery, Operations.AttributeSchemaQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.AttributeSchemaQuery, Operations.AttributeSchemaQueryVariables>(Operations.AttributeSchemaDocument, variables, options);
          }
export type AttributeSchemaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.AttributeSchemaQuery, Operations.AttributeSchemaQueryVariables>;

/**
 * __useUpsertAppletDesignVersionMutation__
 *
 * To run a mutation, you first call `useUpsertAppletDesignVersionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAppletDesignVersionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertAppletDesignVersionMutation({
 *   variables: {
 *      designVersion: // value for 'designVersion'
 *   },
 * });
 */
export function useUpsertAppletDesignVersionMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpsertAppletDesignVersionMutation, Operations.UpsertAppletDesignVersionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpsertAppletDesignVersionMutation, Operations.UpsertAppletDesignVersionMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpsertAppletDesignVersionMutation, Operations.UpsertAppletDesignVersionMutationVariables>(Operations.UpsertAppletDesignVersionDocument, options);
          }
export type UpsertAppletDesignVersionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpsertAppletDesignVersionMutation, Operations.UpsertAppletDesignVersionMutationVariables>;

/**
 * __useGetAppletRunnersQuery__
 *
 * To run a query within a Vue component, call `useGetAppletRunnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppletRunnersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAppletRunnersQuery(
 *   {
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useGetAppletRunnersQuery(variables?: Operations.GetAppletRunnersQueryVariables | VueCompositionApi.Ref<Operations.GetAppletRunnersQueryVariables> | ReactiveFunction<Operations.GetAppletRunnersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAppletRunnersQuery, Operations.GetAppletRunnersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAppletRunnersQuery, Operations.GetAppletRunnersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAppletRunnersQuery, Operations.GetAppletRunnersQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAppletRunnersQuery, Operations.GetAppletRunnersQueryVariables>(Operations.GetAppletRunnersDocument, variables, options);
          }
export type GetAppletRunnersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAppletRunnersQuery, Operations.GetAppletRunnersQueryVariables>;

/**
 * __useGetAllAttributesQuery__
 *
 * To run a query within a Vue component, call `useGetAllAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAttributesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllAttributesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sortby: // value for 'sortby'
 *   }
 * );
 */
export function useGetAllAttributesQuery(variables: Operations.GetAllAttributesQueryVariables | VueCompositionApi.Ref<Operations.GetAllAttributesQueryVariables> | ReactiveFunction<Operations.GetAllAttributesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllAttributesQuery, Operations.GetAllAttributesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllAttributesQuery, Operations.GetAllAttributesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllAttributesQuery, Operations.GetAllAttributesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllAttributesQuery, Operations.GetAllAttributesQueryVariables>(Operations.GetAllAttributesDocument, variables, options);
          }
export type GetAllAttributesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllAttributesQuery, Operations.GetAllAttributesQueryVariables>;

/**
 * __useGetAttrSchemaPropsQuery__
 *
 * To run a query within a Vue component, call `useGetAttrSchemaPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttrSchemaPropsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAttrSchemaPropsQuery(
 *   {
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useGetAttrSchemaPropsQuery(variables?: Operations.GetAttrSchemaPropsQueryVariables | VueCompositionApi.Ref<Operations.GetAttrSchemaPropsQueryVariables> | ReactiveFunction<Operations.GetAttrSchemaPropsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAttrSchemaPropsQuery, Operations.GetAttrSchemaPropsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAttrSchemaPropsQuery, Operations.GetAttrSchemaPropsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAttrSchemaPropsQuery, Operations.GetAttrSchemaPropsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAttrSchemaPropsQuery, Operations.GetAttrSchemaPropsQueryVariables>(Operations.GetAttrSchemaPropsDocument, variables, options);
          }
export type GetAttrSchemaPropsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAttrSchemaPropsQuery, Operations.GetAttrSchemaPropsQueryVariables>;

/**
 * __useGetAllItemsThatUseLocationAttributeQuery__
 *
 * To run a query within a Vue component, call `useGetAllItemsThatUseLocationAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllItemsThatUseLocationAttributeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllItemsThatUseLocationAttributeQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllItemsThatUseLocationAttributeQuery(variables: Operations.GetAllItemsThatUseLocationAttributeQueryVariables | VueCompositionApi.Ref<Operations.GetAllItemsThatUseLocationAttributeQueryVariables> | ReactiveFunction<Operations.GetAllItemsThatUseLocationAttributeQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllItemsThatUseLocationAttributeQuery, Operations.GetAllItemsThatUseLocationAttributeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllItemsThatUseLocationAttributeQuery, Operations.GetAllItemsThatUseLocationAttributeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllItemsThatUseLocationAttributeQuery, Operations.GetAllItemsThatUseLocationAttributeQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllItemsThatUseLocationAttributeQuery, Operations.GetAllItemsThatUseLocationAttributeQueryVariables>(Operations.GetAllItemsThatUseLocationAttributeDocument, variables, options);
          }
export type GetAllItemsThatUseLocationAttributeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllItemsThatUseLocationAttributeQuery, Operations.GetAllItemsThatUseLocationAttributeQueryVariables>;

/**
 * __useGetAllItemsThatUseMaterialAttributeQuery__
 *
 * To run a query within a Vue component, call `useGetAllItemsThatUseMaterialAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllItemsThatUseMaterialAttributeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllItemsThatUseMaterialAttributeQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllItemsThatUseMaterialAttributeQuery(variables: Operations.GetAllItemsThatUseMaterialAttributeQueryVariables | VueCompositionApi.Ref<Operations.GetAllItemsThatUseMaterialAttributeQueryVariables> | ReactiveFunction<Operations.GetAllItemsThatUseMaterialAttributeQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllItemsThatUseMaterialAttributeQuery, Operations.GetAllItemsThatUseMaterialAttributeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllItemsThatUseMaterialAttributeQuery, Operations.GetAllItemsThatUseMaterialAttributeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllItemsThatUseMaterialAttributeQuery, Operations.GetAllItemsThatUseMaterialAttributeQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllItemsThatUseMaterialAttributeQuery, Operations.GetAllItemsThatUseMaterialAttributeQueryVariables>(Operations.GetAllItemsThatUseMaterialAttributeDocument, variables, options);
          }
export type GetAllItemsThatUseMaterialAttributeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllItemsThatUseMaterialAttributeQuery, Operations.GetAllItemsThatUseMaterialAttributeQueryVariables>;

/**
 * __useGetAttributeForMaterialQuery__
 *
 * To run a query within a Vue component, call `useGetAttributeForMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributeForMaterialQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAttributeForMaterialQuery(
 *   {
 *      attributeId: // value for 'attributeId'
 *      serialNumber: // value for 'serialNumber'
 *   }
 * );
 */
export function useGetAttributeForMaterialQuery(variables: Operations.GetAttributeForMaterialQueryVariables | VueCompositionApi.Ref<Operations.GetAttributeForMaterialQueryVariables> | ReactiveFunction<Operations.GetAttributeForMaterialQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAttributeForMaterialQuery, Operations.GetAttributeForMaterialQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAttributeForMaterialQuery, Operations.GetAttributeForMaterialQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAttributeForMaterialQuery, Operations.GetAttributeForMaterialQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAttributeForMaterialQuery, Operations.GetAttributeForMaterialQueryVariables>(Operations.GetAttributeForMaterialDocument, variables, options);
          }
export type GetAttributeForMaterialQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAttributeForMaterialQuery, Operations.GetAttributeForMaterialQueryVariables>;

/**
 * __useGetSchemaQuery__
 *
 * To run a query within a Vue component, call `useGetSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSchemaQuery(
 *   {
 *      schemaId: // value for 'schemaId'
 *   }
 * );
 */
export function useGetSchemaQuery(variables: Operations.GetSchemaQueryVariables | VueCompositionApi.Ref<Operations.GetSchemaQueryVariables> | ReactiveFunction<Operations.GetSchemaQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetSchemaQuery, Operations.GetSchemaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetSchemaQuery, Operations.GetSchemaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetSchemaQuery, Operations.GetSchemaQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetSchemaQuery, Operations.GetSchemaQueryVariables>(Operations.GetSchemaDocument, variables, options);
          }
export type GetSchemaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetSchemaQuery, Operations.GetSchemaQueryVariables>;

/**
 * __useInsertSchemaMutation__
 *
 * To run a mutation, you first call `useInsertSchemaMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertSchemaMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertSchemaMutation({
 *   variables: {
 *      schemas: // value for 'schemas'
 *   },
 * });
 */
export function useInsertSchemaMutation(options: VueApolloComposable.UseMutationOptions<Operations.InsertSchemaMutation, Operations.InsertSchemaMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.InsertSchemaMutation, Operations.InsertSchemaMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.InsertSchemaMutation, Operations.InsertSchemaMutationVariables>(Operations.InsertSchemaDocument, options);
          }
export type InsertSchemaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.InsertSchemaMutation, Operations.InsertSchemaMutationVariables>;

/**
 * __useRetroApplyNewAttributeLinksMutation__
 *
 * To run a mutation, you first call `useRetroApplyNewAttributeLinksMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRetroApplyNewAttributeLinksMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRetroApplyNewAttributeLinksMutation({
 *   variables: {
 *      oldAttributeId: // value for 'oldAttributeId'
 *      newAttributeId: // value for 'newAttributeId'
 *      includeLocation: // value for 'includeLocation'
 *      locationIds: // value for 'locationIds'
 *      includeMaterial: // value for 'includeMaterial'
 *      materialIds: // value for 'materialIds'
 *      includeMaterialModel: // value for 'includeMaterialModel'
 *      materialModelIds: // value for 'materialModelIds'
 *      includeMaterialClass: // value for 'includeMaterialClass'
 *      materialClassIds: // value for 'materialClassIds'
 *   },
 * });
 */
export function useRetroApplyNewAttributeLinksMutation(options: VueApolloComposable.UseMutationOptions<Operations.RetroApplyNewAttributeLinksMutation, Operations.RetroApplyNewAttributeLinksMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.RetroApplyNewAttributeLinksMutation, Operations.RetroApplyNewAttributeLinksMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.RetroApplyNewAttributeLinksMutation, Operations.RetroApplyNewAttributeLinksMutationVariables>(Operations.RetroApplyNewAttributeLinksDocument, options);
          }
export type RetroApplyNewAttributeLinksMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.RetroApplyNewAttributeLinksMutation, Operations.RetroApplyNewAttributeLinksMutationVariables>;

/**
 * __useGetAllSchemasQuery__
 *
 * To run a query within a Vue component, call `useGetAllSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSchemasQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllSchemasQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sortby: // value for 'sortby'
 *   }
 * );
 */
export function useGetAllSchemasQuery(variables?: Operations.GetAllSchemasQueryVariables | VueCompositionApi.Ref<Operations.GetAllSchemasQueryVariables> | ReactiveFunction<Operations.GetAllSchemasQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllSchemasQuery, Operations.GetAllSchemasQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllSchemasQuery, Operations.GetAllSchemasQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllSchemasQuery, Operations.GetAllSchemasQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllSchemasQuery, Operations.GetAllSchemasQueryVariables>(Operations.GetAllSchemasDocument, variables, options);
          }
export type GetAllSchemasQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllSchemasQuery, Operations.GetAllSchemasQueryVariables>;

/**
 * __useSetAttributeToDisabledMutation__
 *
 * To run a mutation, you first call `useSetAttributeToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetAttributeToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetAttributeToDisabledMutation({
 *   variables: {
 *      attributeId: // value for 'attributeId'
 *   },
 * });
 */
export function useSetAttributeToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetAttributeToDisabledMutation, Operations.SetAttributeToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetAttributeToDisabledMutation, Operations.SetAttributeToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetAttributeToDisabledMutation, Operations.SetAttributeToDisabledMutationVariables>(Operations.SetAttributeToDisabledDocument, options);
          }
export type SetAttributeToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetAttributeToDisabledMutation, Operations.SetAttributeToDisabledMutationVariables>;

/**
 * __useSetSchemaToDisabledMutation__
 *
 * To run a mutation, you first call `useSetSchemaToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetSchemaToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetSchemaToDisabledMutation({
 *   variables: {
 *      schemaId: // value for 'schemaId'
 *   },
 * });
 */
export function useSetSchemaToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetSchemaToDisabledMutation, Operations.SetSchemaToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetSchemaToDisabledMutation, Operations.SetSchemaToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetSchemaToDisabledMutation, Operations.SetSchemaToDisabledMutationVariables>(Operations.SetSchemaToDisabledDocument, options);
          }
export type SetSchemaToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetSchemaToDisabledMutation, Operations.SetSchemaToDisabledMutationVariables>;

/**
 * __useUpdateSchemaMutation__
 *
 * To run a mutation, you first call `useUpdateSchemaMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemaMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSchemaMutation({
 *   variables: {
 *      schemaId: // value for 'schemaId'
 *      attributeIdsToDelete: // value for 'attributeIdsToDelete'
 *      schema: // value for 'schema'
 *      schemaDefs: // value for 'schemaDefs'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateSchemaMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateSchemaMutation, Operations.UpdateSchemaMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateSchemaMutation, Operations.UpdateSchemaMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateSchemaMutation, Operations.UpdateSchemaMutationVariables>(Operations.UpdateSchemaDocument, options);
          }
export type UpdateSchemaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateSchemaMutation, Operations.UpdateSchemaMutationVariables>;

/**
 * __useAllEnabledUserAclsQuery__
 *
 * To run a query within a Vue component, call `useAllEnabledUserAclsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEnabledUserAclsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAllEnabledUserAclsQuery(
 *   {
 *      userId: // value for 'userId'
 *   }
 * );
 */
export function useAllEnabledUserAclsQuery(variables: Operations.AllEnabledUserAclsQueryVariables | VueCompositionApi.Ref<Operations.AllEnabledUserAclsQueryVariables> | ReactiveFunction<Operations.AllEnabledUserAclsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.AllEnabledUserAclsQuery, Operations.AllEnabledUserAclsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.AllEnabledUserAclsQuery, Operations.AllEnabledUserAclsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.AllEnabledUserAclsQuery, Operations.AllEnabledUserAclsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.AllEnabledUserAclsQuery, Operations.AllEnabledUserAclsQueryVariables>(Operations.AllEnabledUserAclsDocument, variables, options);
          }
export type AllEnabledUserAclsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.AllEnabledUserAclsQuery, Operations.AllEnabledUserAclsQueryVariables>;

/**
 * __useAllUserContextsQuery__
 *
 * To run a query within a Vue component, call `useAllUserContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserContextsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAllUserContextsQuery(
 *   {
 *   }
 * );
 */
export function useAllUserContextsQuery(options: VueApolloComposable.UseQueryOptions<Operations.AllUserContextsQuery, Operations.AllUserContextsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.AllUserContextsQuery, Operations.AllUserContextsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.AllUserContextsQuery, Operations.AllUserContextsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.AllUserContextsQuery, undefined>(Operations.AllUserContextsDocument, undefined, options);
          }
export type AllUserContextsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.AllUserContextsQuery, Operations.AllUserContextsQueryVariables>;

/**
 * __useGetAttributeHistoryForItemQuery__
 *
 * To run a query within a Vue component, call `useGetAttributeHistoryForItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributeHistoryForItemQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAttributeHistoryForItemQuery(
 *   {
 *      itemId: // value for 'itemId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      includeMaterial: // value for 'includeMaterial'
 *      includeLocation: // value for 'includeLocation'
 *   }
 * );
 */
export function useGetAttributeHistoryForItemQuery(variables: Operations.GetAttributeHistoryForItemQueryVariables | VueCompositionApi.Ref<Operations.GetAttributeHistoryForItemQueryVariables> | ReactiveFunction<Operations.GetAttributeHistoryForItemQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAttributeHistoryForItemQuery, Operations.GetAttributeHistoryForItemQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAttributeHistoryForItemQuery, Operations.GetAttributeHistoryForItemQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAttributeHistoryForItemQuery, Operations.GetAttributeHistoryForItemQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAttributeHistoryForItemQuery, Operations.GetAttributeHistoryForItemQueryVariables>(Operations.GetAttributeHistoryForItemDocument, variables, options);
          }
export type GetAttributeHistoryForItemQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAttributeHistoryForItemQuery, Operations.GetAttributeHistoryForItemQueryVariables>;

/**
 * __useGetAttributesForEntityItemQuery__
 *
 * To run a query within a Vue component, call `useGetAttributesForEntityItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributesForEntityItemQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAttributesForEntityItemQuery(
 *   {
 *      entityItemId: // value for 'entityItemId'
 *   }
 * );
 */
export function useGetAttributesForEntityItemQuery(variables: Operations.GetAttributesForEntityItemQueryVariables | VueCompositionApi.Ref<Operations.GetAttributesForEntityItemQueryVariables> | ReactiveFunction<Operations.GetAttributesForEntityItemQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAttributesForEntityItemQuery, Operations.GetAttributesForEntityItemQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAttributesForEntityItemQuery, Operations.GetAttributesForEntityItemQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAttributesForEntityItemQuery, Operations.GetAttributesForEntityItemQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAttributesForEntityItemQuery, Operations.GetAttributesForEntityItemQueryVariables>(Operations.GetAttributesForEntityItemDocument, variables, options);
          }
export type GetAttributesForEntityItemQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAttributesForEntityItemQuery, Operations.GetAttributesForEntityItemQueryVariables>;

/**
 * __useGetAttributeSchemaTypesQuery__
 *
 * To run a query within a Vue component, call `useGetAttributeSchemaTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributeSchemaTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAttributeSchemaTypesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAttributeSchemaTypesQuery(variables: Operations.GetAttributeSchemaTypesQueryVariables | VueCompositionApi.Ref<Operations.GetAttributeSchemaTypesQueryVariables> | ReactiveFunction<Operations.GetAttributeSchemaTypesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAttributeSchemaTypesQuery, Operations.GetAttributeSchemaTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAttributeSchemaTypesQuery, Operations.GetAttributeSchemaTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAttributeSchemaTypesQuery, Operations.GetAttributeSchemaTypesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAttributeSchemaTypesQuery, Operations.GetAttributeSchemaTypesQueryVariables>(Operations.GetAttributeSchemaTypesDocument, variables, options);
          }
export type GetAttributeSchemaTypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAttributeSchemaTypesQuery, Operations.GetAttributeSchemaTypesQueryVariables>;

/**
 * __useAttributeSelectorQuery__
 *
 * To run a query within a Vue component, call `useAttributeSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAttributeSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *      selectedAttributes: // value for 'selectedAttributes'
 *   }
 * );
 */
export function useAttributeSelectorQuery(variables: Operations.AttributeSelectorQueryVariables | VueCompositionApi.Ref<Operations.AttributeSelectorQueryVariables> | ReactiveFunction<Operations.AttributeSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.AttributeSelectorQuery, Operations.AttributeSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.AttributeSelectorQuery, Operations.AttributeSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.AttributeSelectorQuery, Operations.AttributeSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.AttributeSelectorQuery, Operations.AttributeSelectorQueryVariables>(Operations.AttributeSelectorDocument, variables, options);
          }
export type AttributeSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.AttributeSelectorQuery, Operations.AttributeSelectorQueryVariables>;

/**
 * __useGetAllSchemasForAttributeTemplateQuery__
 *
 * To run a query within a Vue component, call `useGetAllSchemasForAttributeTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSchemasForAttributeTemplateQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllSchemasForAttributeTemplateQuery(
 *   {
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useGetAllSchemasForAttributeTemplateQuery(variables?: Operations.GetAllSchemasForAttributeTemplateQueryVariables | VueCompositionApi.Ref<Operations.GetAllSchemasForAttributeTemplateQueryVariables> | ReactiveFunction<Operations.GetAllSchemasForAttributeTemplateQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllSchemasForAttributeTemplateQuery, Operations.GetAllSchemasForAttributeTemplateQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllSchemasForAttributeTemplateQuery, Operations.GetAllSchemasForAttributeTemplateQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllSchemasForAttributeTemplateQuery, Operations.GetAllSchemasForAttributeTemplateQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllSchemasForAttributeTemplateQuery, Operations.GetAllSchemasForAttributeTemplateQueryVariables>(Operations.GetAllSchemasForAttributeTemplateDocument, variables, options);
          }
export type GetAllSchemasForAttributeTemplateQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllSchemasForAttributeTemplateQuery, Operations.GetAllSchemasForAttributeTemplateQueryVariables>;

/**
 * __useAuthProviderSelectorQuery__
 *
 * To run a query within a Vue component, call `useAuthProviderSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthProviderSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAuthProviderSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useAuthProviderSelectorQuery(variables: Operations.AuthProviderSelectorQueryVariables | VueCompositionApi.Ref<Operations.AuthProviderSelectorQueryVariables> | ReactiveFunction<Operations.AuthProviderSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.AuthProviderSelectorQuery, Operations.AuthProviderSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.AuthProviderSelectorQuery, Operations.AuthProviderSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.AuthProviderSelectorQuery, Operations.AuthProviderSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.AuthProviderSelectorQuery, Operations.AuthProviderSelectorQueryVariables>(Operations.AuthProviderSelectorDocument, variables, options);
          }
export type AuthProviderSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.AuthProviderSelectorQuery, Operations.AuthProviderSelectorQueryVariables>;

/**
 * __useCalendarSelectorQuery__
 *
 * To run a query within a Vue component, call `useCalendarSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCalendarSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useCalendarSelectorQuery(variables: Operations.CalendarSelectorQueryVariables | VueCompositionApi.Ref<Operations.CalendarSelectorQueryVariables> | ReactiveFunction<Operations.CalendarSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.CalendarSelectorQuery, Operations.CalendarSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.CalendarSelectorQuery, Operations.CalendarSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.CalendarSelectorQuery, Operations.CalendarSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.CalendarSelectorQuery, Operations.CalendarSelectorQueryVariables>(Operations.CalendarSelectorDocument, variables, options);
          }
export type CalendarSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.CalendarSelectorQuery, Operations.CalendarSelectorQueryVariables>;

/**
 * __useDesignVerSelectorQuery__
 *
 * To run a query within a Vue component, call `useDesignVerSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignVerSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDesignVerSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useDesignVerSelectorQuery(variables: Operations.DesignVerSelectorQueryVariables | VueCompositionApi.Ref<Operations.DesignVerSelectorQueryVariables> | ReactiveFunction<Operations.DesignVerSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.DesignVerSelectorQuery, Operations.DesignVerSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.DesignVerSelectorQuery, Operations.DesignVerSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.DesignVerSelectorQuery, Operations.DesignVerSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.DesignVerSelectorQuery, Operations.DesignVerSelectorQueryVariables>(Operations.DesignVerSelectorDocument, variables, options);
          }
export type DesignVerSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.DesignVerSelectorQuery, Operations.DesignVerSelectorQueryVariables>;

/**
 * __useDeviceSelectorQuery__
 *
 * To run a query within a Vue component, call `useDeviceSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeviceSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useDeviceSelectorQuery(variables: Operations.DeviceSelectorQueryVariables | VueCompositionApi.Ref<Operations.DeviceSelectorQueryVariables> | ReactiveFunction<Operations.DeviceSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.DeviceSelectorQuery, Operations.DeviceSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.DeviceSelectorQuery, Operations.DeviceSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.DeviceSelectorQuery, Operations.DeviceSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.DeviceSelectorQuery, Operations.DeviceSelectorQueryVariables>(Operations.DeviceSelectorDocument, variables, options);
          }
export type DeviceSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.DeviceSelectorQuery, Operations.DeviceSelectorQueryVariables>;

/**
 * __useEventSelectorQuery__
 *
 * To run a query within a Vue component, call `useEventSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useEventSelectorQuery(variables: Operations.EventSelectorQueryVariables | VueCompositionApi.Ref<Operations.EventSelectorQueryVariables> | ReactiveFunction<Operations.EventSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.EventSelectorQuery, Operations.EventSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.EventSelectorQuery, Operations.EventSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.EventSelectorQuery, Operations.EventSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.EventSelectorQuery, Operations.EventSelectorQueryVariables>(Operations.EventSelectorDocument, variables, options);
          }
export type EventSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.EventSelectorQuery, Operations.EventSelectorQueryVariables>;

/**
 * __useLocationClassSelectorQuery__
 *
 * To run a query within a Vue component, call `useLocationClassSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationClassSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLocationClassSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useLocationClassSelectorQuery(variables: Operations.LocationClassSelectorQueryVariables | VueCompositionApi.Ref<Operations.LocationClassSelectorQueryVariables> | ReactiveFunction<Operations.LocationClassSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.LocationClassSelectorQuery, Operations.LocationClassSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.LocationClassSelectorQuery, Operations.LocationClassSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.LocationClassSelectorQuery, Operations.LocationClassSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.LocationClassSelectorQuery, Operations.LocationClassSelectorQueryVariables>(Operations.LocationClassSelectorDocument, variables, options);
          }
export type LocationClassSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.LocationClassSelectorQuery, Operations.LocationClassSelectorQueryVariables>;

/**
 * __useLocationSelectorQuery__
 *
 * To run a query within a Vue component, call `useLocationSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLocationSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useLocationSelectorQuery(variables: Operations.LocationSelectorQueryVariables | VueCompositionApi.Ref<Operations.LocationSelectorQueryVariables> | ReactiveFunction<Operations.LocationSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.LocationSelectorQuery, Operations.LocationSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.LocationSelectorQuery, Operations.LocationSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.LocationSelectorQuery, Operations.LocationSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.LocationSelectorQuery, Operations.LocationSelectorQueryVariables>(Operations.LocationSelectorDocument, variables, options);
          }
export type LocationSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.LocationSelectorQuery, Operations.LocationSelectorQueryVariables>;

/**
 * __useMaterialModelSelectorQuery__
 *
 * To run a query within a Vue component, call `useMaterialModelSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialModelSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMaterialModelSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useMaterialModelSelectorQuery(variables: Operations.MaterialModelSelectorQueryVariables | VueCompositionApi.Ref<Operations.MaterialModelSelectorQueryVariables> | ReactiveFunction<Operations.MaterialModelSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.MaterialModelSelectorQuery, Operations.MaterialModelSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.MaterialModelSelectorQuery, Operations.MaterialModelSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.MaterialModelSelectorQuery, Operations.MaterialModelSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.MaterialModelSelectorQuery, Operations.MaterialModelSelectorQueryVariables>(Operations.MaterialModelSelectorDocument, variables, options);
          }
export type MaterialModelSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.MaterialModelSelectorQuery, Operations.MaterialModelSelectorQueryVariables>;

/**
 * __useProcessTableQuery__
 *
 * To run a query within a Vue component, call `useProcessTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessTableQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProcessTableQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *      includePdeObj: // value for 'includePdeObj'
 *   }
 * );
 */
export function useProcessTableQuery(variables: Operations.ProcessTableQueryVariables | VueCompositionApi.Ref<Operations.ProcessTableQueryVariables> | ReactiveFunction<Operations.ProcessTableQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.ProcessTableQuery, Operations.ProcessTableQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.ProcessTableQuery, Operations.ProcessTableQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.ProcessTableQuery, Operations.ProcessTableQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.ProcessTableQuery, Operations.ProcessTableQueryVariables>(Operations.ProcessTableDocument, variables, options);
          }
export type ProcessTableQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.ProcessTableQuery, Operations.ProcessTableQueryVariables>;

/**
 * __useProcessMaterialStatusHistoryQuery__
 *
 * To run a query within a Vue component, call `useProcessMaterialStatusHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessMaterialStatusHistoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProcessMaterialStatusHistoryQuery(
 *   {
 *      materialStateId: // value for 'materialStateId'
 *   }
 * );
 */
export function useProcessMaterialStatusHistoryQuery(variables: Operations.ProcessMaterialStatusHistoryQueryVariables | VueCompositionApi.Ref<Operations.ProcessMaterialStatusHistoryQueryVariables> | ReactiveFunction<Operations.ProcessMaterialStatusHistoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.ProcessMaterialStatusHistoryQuery, Operations.ProcessMaterialStatusHistoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.ProcessMaterialStatusHistoryQuery, Operations.ProcessMaterialStatusHistoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.ProcessMaterialStatusHistoryQuery, Operations.ProcessMaterialStatusHistoryQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.ProcessMaterialStatusHistoryQuery, Operations.ProcessMaterialStatusHistoryQueryVariables>(Operations.ProcessMaterialStatusHistoryDocument, variables, options);
          }
export type ProcessMaterialStatusHistoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.ProcessMaterialStatusHistoryQuery, Operations.ProcessMaterialStatusHistoryQueryVariables>;

/**
 * __useUpdateMaterialProcessStateMutation__
 *
 * To run a mutation, you first call `useUpdateMaterialProcessStateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaterialProcessStateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateMaterialProcessStateMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpdateMaterialProcessStateMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialProcessStateMutation, Operations.UpdateMaterialProcessStateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialProcessStateMutation, Operations.UpdateMaterialProcessStateMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateMaterialProcessStateMutation, Operations.UpdateMaterialProcessStateMutationVariables>(Operations.UpdateMaterialProcessStateDocument, options);
          }
export type UpdateMaterialProcessStateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateMaterialProcessStateMutation, Operations.UpdateMaterialProcessStateMutationVariables>;

/**
 * __useRoleSelectorQuery__
 *
 * To run a query within a Vue component, call `useRoleSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRoleSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useRoleSelectorQuery(variables: Operations.RoleSelectorQueryVariables | VueCompositionApi.Ref<Operations.RoleSelectorQueryVariables> | ReactiveFunction<Operations.RoleSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.RoleSelectorQuery, Operations.RoleSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.RoleSelectorQuery, Operations.RoleSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.RoleSelectorQuery, Operations.RoleSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.RoleSelectorQuery, Operations.RoleSelectorQueryVariables>(Operations.RoleSelectorDocument, variables, options);
          }
export type RoleSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.RoleSelectorQuery, Operations.RoleSelectorQueryVariables>;

/**
 * __useScheduleSelectorQuery__
 *
 * To run a query within a Vue component, call `useScheduleSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useScheduleSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useScheduleSelectorQuery(variables: Operations.ScheduleSelectorQueryVariables | VueCompositionApi.Ref<Operations.ScheduleSelectorQueryVariables> | ReactiveFunction<Operations.ScheduleSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.ScheduleSelectorQuery, Operations.ScheduleSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.ScheduleSelectorQuery, Operations.ScheduleSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.ScheduleSelectorQuery, Operations.ScheduleSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.ScheduleSelectorQuery, Operations.ScheduleSelectorQueryVariables>(Operations.ScheduleSelectorDocument, variables, options);
          }
export type ScheduleSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.ScheduleSelectorQuery, Operations.ScheduleSelectorQueryVariables>;

/**
 * __useSerialNumberFormatSelectorQuery__
 *
 * To run a query within a Vue component, call `useSerialNumberFormatSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerialNumberFormatSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSerialNumberFormatSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useSerialNumberFormatSelectorQuery(variables: Operations.SerialNumberFormatSelectorQueryVariables | VueCompositionApi.Ref<Operations.SerialNumberFormatSelectorQueryVariables> | ReactiveFunction<Operations.SerialNumberFormatSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.SerialNumberFormatSelectorQuery, Operations.SerialNumberFormatSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.SerialNumberFormatSelectorQuery, Operations.SerialNumberFormatSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.SerialNumberFormatSelectorQuery, Operations.SerialNumberFormatSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.SerialNumberFormatSelectorQuery, Operations.SerialNumberFormatSelectorQueryVariables>(Operations.SerialNumberFormatSelectorDocument, variables, options);
          }
export type SerialNumberFormatSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.SerialNumberFormatSelectorQuery, Operations.SerialNumberFormatSelectorQueryVariables>;

/**
 * __useGetSparkplugNodeConfigQuery__
 *
 * To run a query within a Vue component, call `useGetSparkplugNodeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSparkplugNodeConfigQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSparkplugNodeConfigQuery(
 *   {
 *      deviceId: // value for 'deviceId'
 *   }
 * );
 */
export function useGetSparkplugNodeConfigQuery(variables: Operations.GetSparkplugNodeConfigQueryVariables | VueCompositionApi.Ref<Operations.GetSparkplugNodeConfigQueryVariables> | ReactiveFunction<Operations.GetSparkplugNodeConfigQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetSparkplugNodeConfigQuery, Operations.GetSparkplugNodeConfigQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetSparkplugNodeConfigQuery, Operations.GetSparkplugNodeConfigQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetSparkplugNodeConfigQuery, Operations.GetSparkplugNodeConfigQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetSparkplugNodeConfigQuery, Operations.GetSparkplugNodeConfigQueryVariables>(Operations.GetSparkplugNodeConfigDocument, variables, options);
          }
export type GetSparkplugNodeConfigQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetSparkplugNodeConfigQuery, Operations.GetSparkplugNodeConfigQueryVariables>;

/**
 * __useTzSelectorQuery__
 *
 * To run a query within a Vue component, call `useTzSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTzSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTzSelectorQuery(
 *   {
 *   }
 * );
 */
export function useTzSelectorQuery(options: VueApolloComposable.UseQueryOptions<Operations.TzSelectorQuery, Operations.TzSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.TzSelectorQuery, Operations.TzSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.TzSelectorQuery, Operations.TzSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.TzSelectorQuery, undefined>(Operations.TzSelectorDocument, undefined, options);
          }
export type TzSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.TzSelectorQuery, Operations.TzSelectorQueryVariables>;

/**
 * __useUdfColumnSelectorQuery__
 *
 * To run a query within a Vue component, call `useUdfColumnSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUdfColumnSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUdfColumnSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useUdfColumnSelectorQuery(variables: Operations.UdfColumnSelectorQueryVariables | VueCompositionApi.Ref<Operations.UdfColumnSelectorQueryVariables> | ReactiveFunction<Operations.UdfColumnSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.UdfColumnSelectorQuery, Operations.UdfColumnSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.UdfColumnSelectorQuery, Operations.UdfColumnSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.UdfColumnSelectorQuery, Operations.UdfColumnSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.UdfColumnSelectorQuery, Operations.UdfColumnSelectorQueryVariables>(Operations.UdfColumnSelectorDocument, variables, options);
          }
export type UdfColumnSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.UdfColumnSelectorQuery, Operations.UdfColumnSelectorQueryVariables>;

/**
 * __useUserSelectorQuery__
 *
 * To run a query within a Vue component, call `useUserSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useUserSelectorQuery(variables: Operations.UserSelectorQueryVariables | VueCompositionApi.Ref<Operations.UserSelectorQueryVariables> | ReactiveFunction<Operations.UserSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.UserSelectorQuery, Operations.UserSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.UserSelectorQuery, Operations.UserSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.UserSelectorQuery, Operations.UserSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.UserSelectorQuery, Operations.UserSelectorQueryVariables>(Operations.UserSelectorDocument, variables, options);
          }
export type UserSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.UserSelectorQuery, Operations.UserSelectorQueryVariables>;

/**
 * __useAddUserToContextMutation__
 *
 * To run a mutation, you first call `useAddUserToContextMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToContextMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddUserToContextMutation({
 *   variables: {
 *      userToAdd: // value for 'userToAdd'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useAddUserToContextMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddUserToContextMutation, Operations.AddUserToContextMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddUserToContextMutation, Operations.AddUserToContextMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddUserToContextMutation, Operations.AddUserToContextMutationVariables>(Operations.AddUserToContextDocument, options);
          }
export type AddUserToContextMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddUserToContextMutation, Operations.AddUserToContextMutationVariables>;

/**
 * __useAddContextMutation__
 *
 * To run a mutation, you first call `useAddContextMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddContextMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddContextMutation({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useAddContextMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddContextMutation, Operations.AddContextMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddContextMutation, Operations.AddContextMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddContextMutation, Operations.AddContextMutationVariables>(Operations.AddContextDocument, options);
          }
export type AddContextMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddContextMutation, Operations.AddContextMutationVariables>;

/**
 * __useGetAllContextsQuery__
 *
 * To run a query within a Vue component, call `useGetAllContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContextsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllContextsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useGetAllContextsQuery(variables: Operations.GetAllContextsQueryVariables | VueCompositionApi.Ref<Operations.GetAllContextsQueryVariables> | ReactiveFunction<Operations.GetAllContextsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllContextsQuery, Operations.GetAllContextsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllContextsQuery, Operations.GetAllContextsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllContextsQuery, Operations.GetAllContextsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllContextsQuery, Operations.GetAllContextsQueryVariables>(Operations.GetAllContextsDocument, variables, options);
          }
export type GetAllContextsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllContextsQuery, Operations.GetAllContextsQueryVariables>;

/**
 * __useGetAuthProviderSettingsQuery__
 *
 * To run a query within a Vue component, call `useGetAuthProviderSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthProviderSettingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAuthProviderSettingsQuery(
 *   {
 *      contextId: // value for 'contextId'
 *   }
 * );
 */
export function useGetAuthProviderSettingsQuery(variables: Operations.GetAuthProviderSettingsQueryVariables | VueCompositionApi.Ref<Operations.GetAuthProviderSettingsQueryVariables> | ReactiveFunction<Operations.GetAuthProviderSettingsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAuthProviderSettingsQuery, Operations.GetAuthProviderSettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAuthProviderSettingsQuery, Operations.GetAuthProviderSettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAuthProviderSettingsQuery, Operations.GetAuthProviderSettingsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAuthProviderSettingsQuery, Operations.GetAuthProviderSettingsQueryVariables>(Operations.GetAuthProviderSettingsDocument, variables, options);
          }
export type GetAuthProviderSettingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAuthProviderSettingsQuery, Operations.GetAuthProviderSettingsQueryVariables>;

/**
 * __useGetContextQuery__
 *
 * To run a query within a Vue component, call `useGetContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetContextQuery(
 *   {
 *      contextId: // value for 'contextId'
 *   }
 * );
 */
export function useGetContextQuery(variables: Operations.GetContextQueryVariables | VueCompositionApi.Ref<Operations.GetContextQueryVariables> | ReactiveFunction<Operations.GetContextQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetContextQuery, Operations.GetContextQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetContextQuery, Operations.GetContextQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetContextQuery, Operations.GetContextQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetContextQuery, Operations.GetContextQueryVariables>(Operations.GetContextDocument, variables, options);
          }
export type GetContextQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetContextQuery, Operations.GetContextQueryVariables>;

/**
 * __useGetUsersForContextQuery__
 *
 * To run a query within a Vue component, call `useGetUsersForContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForContextQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUsersForContextQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sortby: // value for 'sortby'
 *      contextId: // value for 'contextId'
 *   }
 * );
 */
export function useGetUsersForContextQuery(variables: Operations.GetUsersForContextQueryVariables | VueCompositionApi.Ref<Operations.GetUsersForContextQueryVariables> | ReactiveFunction<Operations.GetUsersForContextQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetUsersForContextQuery, Operations.GetUsersForContextQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetUsersForContextQuery, Operations.GetUsersForContextQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetUsersForContextQuery, Operations.GetUsersForContextQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetUsersForContextQuery, Operations.GetUsersForContextQueryVariables>(Operations.GetUsersForContextDocument, variables, options);
          }
export type GetUsersForContextQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetUsersForContextQuery, Operations.GetUsersForContextQueryVariables>;

/**
 * __useInsertContextMutation__
 *
 * To run a mutation, you first call `useInsertContextMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertContextMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertContextMutation({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useInsertContextMutation(options: VueApolloComposable.UseMutationOptions<Operations.InsertContextMutation, Operations.InsertContextMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.InsertContextMutation, Operations.InsertContextMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.InsertContextMutation, Operations.InsertContextMutationVariables>(Operations.InsertContextDocument, options);
          }
export type InsertContextMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.InsertContextMutation, Operations.InsertContextMutationVariables>;

/**
 * __useRemoveUserFromContextMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromContextMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromContextMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveUserFromContextMutation({
 *   variables: {
 *      userToRemove: // value for 'userToRemove'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useRemoveUserFromContextMutation(options: VueApolloComposable.UseMutationOptions<Operations.RemoveUserFromContextMutation, Operations.RemoveUserFromContextMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.RemoveUserFromContextMutation, Operations.RemoveUserFromContextMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.RemoveUserFromContextMutation, Operations.RemoveUserFromContextMutationVariables>(Operations.RemoveUserFromContextDocument, options);
          }
export type RemoveUserFromContextMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.RemoveUserFromContextMutation, Operations.RemoveUserFromContextMutationVariables>;

/**
 * __useSetContextToDisabledMutation__
 *
 * To run a mutation, you first call `useSetContextToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetContextToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetContextToDisabledMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useSetContextToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetContextToDisabledMutation, Operations.SetContextToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetContextToDisabledMutation, Operations.SetContextToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetContextToDisabledMutation, Operations.SetContextToDisabledMutationVariables>(Operations.SetContextToDisabledDocument, options);
          }
export type SetContextToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetContextToDisabledMutation, Operations.SetContextToDisabledMutationVariables>;

/**
 * __useUpdateAuthProviderLocalSettingMutation__
 *
 * To run a mutation, you first call `useUpdateAuthProviderLocalSettingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthProviderLocalSettingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAuthProviderLocalSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateAuthProviderLocalSettingMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateAuthProviderLocalSettingMutation, Operations.UpdateAuthProviderLocalSettingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateAuthProviderLocalSettingMutation, Operations.UpdateAuthProviderLocalSettingMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateAuthProviderLocalSettingMutation, Operations.UpdateAuthProviderLocalSettingMutationVariables>(Operations.UpdateAuthProviderLocalSettingDocument, options);
          }
export type UpdateAuthProviderLocalSettingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateAuthProviderLocalSettingMutation, Operations.UpdateAuthProviderLocalSettingMutationVariables>;

/**
 * __useUpdateAuthProviderMsalSettingMutation__
 *
 * To run a mutation, you first call `useUpdateAuthProviderMsalSettingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthProviderMsalSettingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAuthProviderMsalSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateAuthProviderMsalSettingMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateAuthProviderMsalSettingMutation, Operations.UpdateAuthProviderMsalSettingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateAuthProviderMsalSettingMutation, Operations.UpdateAuthProviderMsalSettingMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateAuthProviderMsalSettingMutation, Operations.UpdateAuthProviderMsalSettingMutationVariables>(Operations.UpdateAuthProviderMsalSettingDocument, options);
          }
export type UpdateAuthProviderMsalSettingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateAuthProviderMsalSettingMutation, Operations.UpdateAuthProviderMsalSettingMutationVariables>;

/**
 * __useUpdateContextMutation__
 *
 * To run a mutation, you first call `useUpdateContextMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContextMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateContextMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useUpdateContextMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateContextMutation, Operations.UpdateContextMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateContextMutation, Operations.UpdateContextMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateContextMutation, Operations.UpdateContextMutationVariables>(Operations.UpdateContextDocument, options);
          }
export type UpdateContextMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateContextMutation, Operations.UpdateContextMutationVariables>;

/**
 * __useUpdateUserRolesMutation__
 *
 * To run a mutation, you first call `useUpdateUserRolesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRolesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserRolesMutation({
 *   variables: {
 *      insertUserRoles: // value for 'insertUserRoles'
 *      deleteUserRoles: // value for 'deleteUserRoles'
 *      contextId: // value for 'contextId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserRolesMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateUserRolesMutation, Operations.UpdateUserRolesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateUserRolesMutation, Operations.UpdateUserRolesMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateUserRolesMutation, Operations.UpdateUserRolesMutationVariables>(Operations.UpdateUserRolesDocument, options);
          }
export type UpdateUserRolesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateUserRolesMutation, Operations.UpdateUserRolesMutationVariables>;

/**
 * __useAddDeviceMutation__
 *
 * To run a mutation, you first call `useAddDeviceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useAddDeviceMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddDeviceMutation, Operations.AddDeviceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddDeviceMutation, Operations.AddDeviceMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddDeviceMutation, Operations.AddDeviceMutationVariables>(Operations.AddDeviceDocument, options);
          }
export type AddDeviceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddDeviceMutation, Operations.AddDeviceMutationVariables>;

/**
 * __useGetAllDeviceTypesQuery__
 *
 * To run a query within a Vue component, call `useGetAllDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDeviceTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllDeviceTypesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllDeviceTypesQuery(variables: Operations.GetAllDeviceTypesQueryVariables | VueCompositionApi.Ref<Operations.GetAllDeviceTypesQueryVariables> | ReactiveFunction<Operations.GetAllDeviceTypesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllDeviceTypesQuery, Operations.GetAllDeviceTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllDeviceTypesQuery, Operations.GetAllDeviceTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllDeviceTypesQuery, Operations.GetAllDeviceTypesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllDeviceTypesQuery, Operations.GetAllDeviceTypesQueryVariables>(Operations.GetAllDeviceTypesDocument, variables, options);
          }
export type GetAllDeviceTypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllDeviceTypesQuery, Operations.GetAllDeviceTypesQueryVariables>;

/**
 * __useGetAllDevicesQuery__
 *
 * To run a query within a Vue component, call `useGetAllDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDevicesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllDevicesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllDevicesQuery(variables: Operations.GetAllDevicesQueryVariables | VueCompositionApi.Ref<Operations.GetAllDevicesQueryVariables> | ReactiveFunction<Operations.GetAllDevicesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllDevicesQuery, Operations.GetAllDevicesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllDevicesQuery, Operations.GetAllDevicesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllDevicesQuery, Operations.GetAllDevicesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllDevicesQuery, Operations.GetAllDevicesQueryVariables>(Operations.GetAllDevicesDocument, variables, options);
          }
export type GetAllDevicesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllDevicesQuery, Operations.GetAllDevicesQueryVariables>;

/**
 * __useGetDeviceQuery__
 *
 * To run a query within a Vue component, call `useGetDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetDeviceQuery(
 *   {
 *      deviceId: // value for 'deviceId'
 *   }
 * );
 */
export function useGetDeviceQuery(variables: Operations.GetDeviceQueryVariables | VueCompositionApi.Ref<Operations.GetDeviceQueryVariables> | ReactiveFunction<Operations.GetDeviceQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetDeviceQuery, Operations.GetDeviceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetDeviceQuery, Operations.GetDeviceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetDeviceQuery, Operations.GetDeviceQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetDeviceQuery, Operations.GetDeviceQueryVariables>(Operations.GetDeviceDocument, variables, options);
          }
export type GetDeviceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetDeviceQuery, Operations.GetDeviceQueryVariables>;

/**
 * __useGetDeviceAppsQuery__
 *
 * To run a query within a Vue component, call `useGetDeviceAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceAppsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetDeviceAppsQuery(
 *   {
 *      deviceId: // value for 'deviceId'
 *   }
 * );
 */
export function useGetDeviceAppsQuery(variables: Operations.GetDeviceAppsQueryVariables | VueCompositionApi.Ref<Operations.GetDeviceAppsQueryVariables> | ReactiveFunction<Operations.GetDeviceAppsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetDeviceAppsQuery, Operations.GetDeviceAppsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetDeviceAppsQuery, Operations.GetDeviceAppsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetDeviceAppsQuery, Operations.GetDeviceAppsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetDeviceAppsQuery, Operations.GetDeviceAppsQueryVariables>(Operations.GetDeviceAppsDocument, variables, options);
          }
export type GetDeviceAppsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetDeviceAppsQuery, Operations.GetDeviceAppsQueryVariables>;

/**
 * __useSetDeviceToDisabledMutation__
 *
 * To run a mutation, you first call `useSetDeviceToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetDeviceToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetDeviceToDisabledMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useSetDeviceToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetDeviceToDisabledMutation, Operations.SetDeviceToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetDeviceToDisabledMutation, Operations.SetDeviceToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetDeviceToDisabledMutation, Operations.SetDeviceToDisabledMutationVariables>(Operations.SetDeviceToDisabledDocument, options);
          }
export type SetDeviceToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetDeviceToDisabledMutation, Operations.SetDeviceToDisabledMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *      sparkplugNodeConfig: // value for 'sparkplugNodeConfig'
 *      labelPrinterConfig: // value for 'labelPrinterConfig'
 *   },
 * });
 */
export function useUpdateDeviceMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateDeviceMutation, Operations.UpdateDeviceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateDeviceMutation, Operations.UpdateDeviceMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateDeviceMutation, Operations.UpdateDeviceMutationVariables>(Operations.UpdateDeviceDocument, options);
          }
export type UpdateDeviceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateDeviceMutation, Operations.UpdateDeviceMutationVariables>;

/**
 * __useAddFileMutation__
 *
 * To run a mutation, you first call `useAddFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddFileMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddFileMutation, Operations.AddFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddFileMutation, Operations.AddFileMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddFileMutation, Operations.AddFileMutationVariables>(Operations.AddFileDocument, options);
          }
export type AddFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddFileMutation, Operations.AddFileMutationVariables>;

/**
 * __useGetAllFilesQuery__
 *
 * To run a query within a Vue component, call `useGetAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFilesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllFilesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllFilesQuery(variables: Operations.GetAllFilesQueryVariables | VueCompositionApi.Ref<Operations.GetAllFilesQueryVariables> | ReactiveFunction<Operations.GetAllFilesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllFilesQuery, Operations.GetAllFilesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllFilesQuery, Operations.GetAllFilesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllFilesQuery, Operations.GetAllFilesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllFilesQuery, Operations.GetAllFilesQueryVariables>(Operations.GetAllFilesDocument, variables, options);
          }
export type GetAllFilesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllFilesQuery, Operations.GetAllFilesQueryVariables>;

/**
 * __useGetFileQuery__
 *
 * To run a query within a Vue component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetFileQuery(
 *   {
 *      fileId: // value for 'fileId'
 *   }
 * );
 */
export function useGetFileQuery(variables: Operations.GetFileQueryVariables | VueCompositionApi.Ref<Operations.GetFileQueryVariables> | ReactiveFunction<Operations.GetFileQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetFileQuery, Operations.GetFileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetFileQuery, Operations.GetFileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetFileQuery, Operations.GetFileQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetFileQuery, Operations.GetFileQueryVariables>(Operations.GetFileDocument, variables, options);
          }
export type GetFileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetFileQuery, Operations.GetFileQueryVariables>;

/**
 * __useSetFileToDisabledMutation__
 *
 * To run a mutation, you first call `useSetFileToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetFileToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetFileToDisabledMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useSetFileToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetFileToDisabledMutation, Operations.SetFileToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetFileToDisabledMutation, Operations.SetFileToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetFileToDisabledMutation, Operations.SetFileToDisabledMutationVariables>(Operations.SetFileToDisabledDocument, options);
          }
export type SetFileToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetFileToDisabledMutation, Operations.SetFileToDisabledMutationVariables>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateFileMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateFileMutation, Operations.UpdateFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateFileMutation, Operations.UpdateFileMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateFileMutation, Operations.UpdateFileMutationVariables>(Operations.UpdateFileDocument, options);
          }
export type UpdateFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateFileMutation, Operations.UpdateFileMutationVariables>;

/**
 * __useAddLabelTemplateMutation__
 *
 * To run a mutation, you first call `useAddLabelTemplateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddLabelTemplateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddLabelTemplateMutation({
 *   variables: {
 *      labelTemplate: // value for 'labelTemplate'
 *   },
 * });
 */
export function useAddLabelTemplateMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddLabelTemplateMutation, Operations.AddLabelTemplateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddLabelTemplateMutation, Operations.AddLabelTemplateMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddLabelTemplateMutation, Operations.AddLabelTemplateMutationVariables>(Operations.AddLabelTemplateDocument, options);
          }
export type AddLabelTemplateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddLabelTemplateMutation, Operations.AddLabelTemplateMutationVariables>;

/**
 * __useGetLabelPrintQueueQuery__
 *
 * To run a query within a Vue component, call `useGetLabelPrintQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelPrintQueueQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLabelPrintQueueQuery(
 *   {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useGetLabelPrintQueueQuery(variables: Operations.GetLabelPrintQueueQueryVariables | VueCompositionApi.Ref<Operations.GetLabelPrintQueueQueryVariables> | ReactiveFunction<Operations.GetLabelPrintQueueQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetLabelPrintQueueQuery, Operations.GetLabelPrintQueueQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetLabelPrintQueueQuery, Operations.GetLabelPrintQueueQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetLabelPrintQueueQuery, Operations.GetLabelPrintQueueQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetLabelPrintQueueQuery, Operations.GetLabelPrintQueueQueryVariables>(Operations.GetLabelPrintQueueDocument, variables, options);
          }
export type GetLabelPrintQueueQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetLabelPrintQueueQuery, Operations.GetLabelPrintQueueQueryVariables>;

/**
 * __useGetLabelTemplateQuery__
 *
 * To run a query within a Vue component, call `useGetLabelTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelTemplateQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLabelTemplateQuery(
 *   {
 *      labelTemplateId: // value for 'labelTemplateId'
 *   }
 * );
 */
export function useGetLabelTemplateQuery(variables: Operations.GetLabelTemplateQueryVariables | VueCompositionApi.Ref<Operations.GetLabelTemplateQueryVariables> | ReactiveFunction<Operations.GetLabelTemplateQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetLabelTemplateQuery, Operations.GetLabelTemplateQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetLabelTemplateQuery, Operations.GetLabelTemplateQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetLabelTemplateQuery, Operations.GetLabelTemplateQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetLabelTemplateQuery, Operations.GetLabelTemplateQueryVariables>(Operations.GetLabelTemplateDocument, variables, options);
          }
export type GetLabelTemplateQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetLabelTemplateQuery, Operations.GetLabelTemplateQueryVariables>;

/**
 * __useGetLabelTemplatesQuery__
 *
 * To run a query within a Vue component, call `useGetLabelTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelTemplatesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLabelTemplatesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetLabelTemplatesQuery(variables: Operations.GetLabelTemplatesQueryVariables | VueCompositionApi.Ref<Operations.GetLabelTemplatesQueryVariables> | ReactiveFunction<Operations.GetLabelTemplatesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetLabelTemplatesQuery, Operations.GetLabelTemplatesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetLabelTemplatesQuery, Operations.GetLabelTemplatesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetLabelTemplatesQuery, Operations.GetLabelTemplatesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetLabelTemplatesQuery, Operations.GetLabelTemplatesQueryVariables>(Operations.GetLabelTemplatesDocument, variables, options);
          }
export type GetLabelTemplatesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetLabelTemplatesQuery, Operations.GetLabelTemplatesQueryVariables>;

/**
 * __useUpdateLabelTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateLabelTemplateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelTemplateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateLabelTemplateMutation({
 *   variables: {
 *      labelTemplateId: // value for 'labelTemplateId'
 *      labelTemplate: // value for 'labelTemplate'
 *   },
 * });
 */
export function useUpdateLabelTemplateMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateLabelTemplateMutation, Operations.UpdateLabelTemplateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateLabelTemplateMutation, Operations.UpdateLabelTemplateMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateLabelTemplateMutation, Operations.UpdateLabelTemplateMutationVariables>(Operations.UpdateLabelTemplateDocument, options);
          }
export type UpdateLabelTemplateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateLabelTemplateMutation, Operations.UpdateLabelTemplateMutationVariables>;

/**
 * __useAddLinkMutation__
 *
 * To run a mutation, you first call `useAddLinkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddLinkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddLinkMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddLinkMutation, Operations.AddLinkMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddLinkMutation, Operations.AddLinkMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddLinkMutation, Operations.AddLinkMutationVariables>(Operations.AddLinkDocument, options);
          }
export type AddLinkMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddLinkMutation, Operations.AddLinkMutationVariables>;

/**
 * __useDeleteLinkMutation__
 *
 * To run a mutation, you first call `useDeleteLinkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useDeleteLinkMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteLinkMutation, Operations.DeleteLinkMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteLinkMutation, Operations.DeleteLinkMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteLinkMutation, Operations.DeleteLinkMutationVariables>(Operations.DeleteLinkDocument, options);
          }
export type DeleteLinkMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteLinkMutation, Operations.DeleteLinkMutationVariables>;

/**
 * __useGetLinkQuery__
 *
 * To run a query within a Vue component, call `useGetLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLinkQuery(
 *   {
 *      linkId: // value for 'linkId'
 *   }
 * );
 */
export function useGetLinkQuery(variables: Operations.GetLinkQueryVariables | VueCompositionApi.Ref<Operations.GetLinkQueryVariables> | ReactiveFunction<Operations.GetLinkQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetLinkQuery, Operations.GetLinkQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetLinkQuery, Operations.GetLinkQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetLinkQuery, Operations.GetLinkQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetLinkQuery, Operations.GetLinkQueryVariables>(Operations.GetLinkDocument, variables, options);
          }
export type GetLinkQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetLinkQuery, Operations.GetLinkQueryVariables>;

/**
 * __useGetAllLinksQuery__
 *
 * To run a query within a Vue component, call `useGetAllLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLinksQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllLinksQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllLinksQuery(variables: Operations.GetAllLinksQueryVariables | VueCompositionApi.Ref<Operations.GetAllLinksQueryVariables> | ReactiveFunction<Operations.GetAllLinksQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllLinksQuery, Operations.GetAllLinksQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllLinksQuery, Operations.GetAllLinksQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllLinksQuery, Operations.GetAllLinksQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllLinksQuery, Operations.GetAllLinksQueryVariables>(Operations.GetAllLinksDocument, variables, options);
          }
export type GetAllLinksQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllLinksQuery, Operations.GetAllLinksQueryVariables>;

/**
 * __useUpdateLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLinkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateLinkMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateLinkMutation, Operations.UpdateLinkMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateLinkMutation, Operations.UpdateLinkMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateLinkMutation, Operations.UpdateLinkMutationVariables>(Operations.UpdateLinkDocument, options);
          }
export type UpdateLinkMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateLinkMutation, Operations.UpdateLinkMutationVariables>;

/**
 * __useGetLocationHierarchyQuery__
 *
 * To run a query within a Vue component, call `useGetLocationHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationHierarchyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLocationHierarchyQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useGetLocationHierarchyQuery(variables: Operations.GetLocationHierarchyQueryVariables | VueCompositionApi.Ref<Operations.GetLocationHierarchyQueryVariables> | ReactiveFunction<Operations.GetLocationHierarchyQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetLocationHierarchyQuery, Operations.GetLocationHierarchyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetLocationHierarchyQuery, Operations.GetLocationHierarchyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetLocationHierarchyQuery, Operations.GetLocationHierarchyQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetLocationHierarchyQuery, Operations.GetLocationHierarchyQueryVariables>(Operations.GetLocationHierarchyDocument, variables, options);
          }
export type GetLocationHierarchyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetLocationHierarchyQuery, Operations.GetLocationHierarchyQueryVariables>;

/**
 * __useAddLocationMutation__
 *
 * To run a mutation, you first call `useAddLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useAddLocationMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddLocationMutation, Operations.AddLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddLocationMutation, Operations.AddLocationMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddLocationMutation, Operations.AddLocationMutationVariables>(Operations.AddLocationDocument, options);
          }
export type AddLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddLocationMutation, Operations.AddLocationMutationVariables>;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a Vue component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLocationQuery(
 *   {
 *      locationId: // value for 'locationId'
 *   }
 * );
 */
export function useGetLocationQuery(variables: Operations.GetLocationQueryVariables | VueCompositionApi.Ref<Operations.GetLocationQueryVariables> | ReactiveFunction<Operations.GetLocationQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetLocationQuery, Operations.GetLocationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetLocationQuery, Operations.GetLocationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetLocationQuery, Operations.GetLocationQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetLocationQuery, Operations.GetLocationQueryVariables>(Operations.GetLocationDocument, variables, options);
          }
export type GetLocationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetLocationQuery, Operations.GetLocationQueryVariables>;

/**
 * __useGetCalendarLocationLinksQuery__
 *
 * To run a query within a Vue component, call `useGetCalendarLocationLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarLocationLinksQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCalendarLocationLinksQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetCalendarLocationLinksQuery(variables: Operations.GetCalendarLocationLinksQueryVariables | VueCompositionApi.Ref<Operations.GetCalendarLocationLinksQueryVariables> | ReactiveFunction<Operations.GetCalendarLocationLinksQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetCalendarLocationLinksQuery, Operations.GetCalendarLocationLinksQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetCalendarLocationLinksQuery, Operations.GetCalendarLocationLinksQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetCalendarLocationLinksQuery, Operations.GetCalendarLocationLinksQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetCalendarLocationLinksQuery, Operations.GetCalendarLocationLinksQueryVariables>(Operations.GetCalendarLocationLinksDocument, variables, options);
          }
export type GetCalendarLocationLinksQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetCalendarLocationLinksQuery, Operations.GetCalendarLocationLinksQueryVariables>;

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a Vue component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllLocationsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllLocationsQuery(variables: Operations.GetAllLocationsQueryVariables | VueCompositionApi.Ref<Operations.GetAllLocationsQueryVariables> | ReactiveFunction<Operations.GetAllLocationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllLocationsQuery, Operations.GetAllLocationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllLocationsQuery, Operations.GetAllLocationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllLocationsQuery, Operations.GetAllLocationsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllLocationsQuery, Operations.GetAllLocationsQueryVariables>(Operations.GetAllLocationsDocument, variables, options);
          }
export type GetAllLocationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllLocationsQuery, Operations.GetAllLocationsQueryVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *      attributesToUnlink: // value for 'attributesToUnlink'
 *      attributesToLink: // value for 'attributesToLink'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useUpdateLocationMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateLocationMutation, Operations.UpdateLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateLocationMutation, Operations.UpdateLocationMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateLocationMutation, Operations.UpdateLocationMutationVariables>(Operations.UpdateLocationDocument, options);
          }
export type UpdateLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateLocationMutation, Operations.UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationAttributesMutation__
 *
 * To run a mutation, you first call `useUpdateLocationAttributesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationAttributesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateLocationAttributesMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      locationAttrInput: // value for 'locationAttrInput'
 *      attributeIdList: // value for 'attributeIdList'
 *   },
 * });
 */
export function useUpdateLocationAttributesMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateLocationAttributesMutation, Operations.UpdateLocationAttributesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateLocationAttributesMutation, Operations.UpdateLocationAttributesMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateLocationAttributesMutation, Operations.UpdateLocationAttributesMutationVariables>(Operations.UpdateLocationAttributesDocument, options);
          }
export type UpdateLocationAttributesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateLocationAttributesMutation, Operations.UpdateLocationAttributesMutationVariables>;

/**
 * __useAddMaterialClassMutation__
 *
 * To run a mutation, you first call `useAddMaterialClassMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddMaterialClassMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddMaterialClassMutation({
 *   variables: {
 *      materialClass: // value for 'materialClass'
 *   },
 * });
 */
export function useAddMaterialClassMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddMaterialClassMutation, Operations.AddMaterialClassMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddMaterialClassMutation, Operations.AddMaterialClassMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddMaterialClassMutation, Operations.AddMaterialClassMutationVariables>(Operations.AddMaterialClassDocument, options);
          }
export type AddMaterialClassMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddMaterialClassMutation, Operations.AddMaterialClassMutationVariables>;

/**
 * __useDeleteMaterialClassFilesMutation__
 *
 * To run a mutation, you first call `useDeleteMaterialClassFilesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMaterialClassFilesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteMaterialClassFilesMutation({
 *   variables: {
 *      materialClassId: // value for 'materialClassId'
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useDeleteMaterialClassFilesMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteMaterialClassFilesMutation, Operations.DeleteMaterialClassFilesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteMaterialClassFilesMutation, Operations.DeleteMaterialClassFilesMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteMaterialClassFilesMutation, Operations.DeleteMaterialClassFilesMutationVariables>(Operations.DeleteMaterialClassFilesDocument, options);
          }
export type DeleteMaterialClassFilesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteMaterialClassFilesMutation, Operations.DeleteMaterialClassFilesMutationVariables>;

/**
 * __useGetMaterialClassQuery__
 *
 * To run a query within a Vue component, call `useGetMaterialClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialClassQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetMaterialClassQuery(
 *   {
 *      materialClassId: // value for 'materialClassId'
 *   }
 * );
 */
export function useGetMaterialClassQuery(variables: Operations.GetMaterialClassQueryVariables | VueCompositionApi.Ref<Operations.GetMaterialClassQueryVariables> | ReactiveFunction<Operations.GetMaterialClassQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetMaterialClassQuery, Operations.GetMaterialClassQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetMaterialClassQuery, Operations.GetMaterialClassQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetMaterialClassQuery, Operations.GetMaterialClassQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetMaterialClassQuery, Operations.GetMaterialClassQueryVariables>(Operations.GetMaterialClassDocument, variables, options);
          }
export type GetMaterialClassQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetMaterialClassQuery, Operations.GetMaterialClassQueryVariables>;

/**
 * __useGetAllMaterialClassAttributesQuery__
 *
 * To run a query within a Vue component, call `useGetAllMaterialClassAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMaterialClassAttributesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllMaterialClassAttributesQuery(
 *   {
 *   }
 * );
 */
export function useGetAllMaterialClassAttributesQuery(options: VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialClassAttributesQuery, Operations.GetAllMaterialClassAttributesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialClassAttributesQuery, Operations.GetAllMaterialClassAttributesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialClassAttributesQuery, Operations.GetAllMaterialClassAttributesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllMaterialClassAttributesQuery, undefined>(Operations.GetAllMaterialClassAttributesDocument, undefined, options);
          }
export type GetAllMaterialClassAttributesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllMaterialClassAttributesQuery, Operations.GetAllMaterialClassAttributesQueryVariables>;

/**
 * __useGetAllMaterialClassesQuery__
 *
 * To run a query within a Vue component, call `useGetAllMaterialClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMaterialClassesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllMaterialClassesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllMaterialClassesQuery(variables: Operations.GetAllMaterialClassesQueryVariables | VueCompositionApi.Ref<Operations.GetAllMaterialClassesQueryVariables> | ReactiveFunction<Operations.GetAllMaterialClassesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialClassesQuery, Operations.GetAllMaterialClassesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialClassesQuery, Operations.GetAllMaterialClassesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialClassesQuery, Operations.GetAllMaterialClassesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllMaterialClassesQuery, Operations.GetAllMaterialClassesQueryVariables>(Operations.GetAllMaterialClassesDocument, variables, options);
          }
export type GetAllMaterialClassesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllMaterialClassesQuery, Operations.GetAllMaterialClassesQueryVariables>;

/**
 * __useGetAllProcessesQuery__
 *
 * To run a query within a Vue component, call `useGetAllProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProcessesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllProcessesQuery(
 *   {
 *      processFilter: // value for 'processFilter'
 *   }
 * );
 */
export function useGetAllProcessesQuery(variables: Operations.GetAllProcessesQueryVariables | VueCompositionApi.Ref<Operations.GetAllProcessesQueryVariables> | ReactiveFunction<Operations.GetAllProcessesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllProcessesQuery, Operations.GetAllProcessesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllProcessesQuery, Operations.GetAllProcessesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllProcessesQuery, Operations.GetAllProcessesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllProcessesQuery, Operations.GetAllProcessesQueryVariables>(Operations.GetAllProcessesDocument, variables, options);
          }
export type GetAllProcessesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllProcessesQuery, Operations.GetAllProcessesQueryVariables>;

/**
 * __useSetMaterialClassToDisabledMutation__
 *
 * To run a mutation, you first call `useSetMaterialClassToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetMaterialClassToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetMaterialClassToDisabledMutation({
 *   variables: {
 *      materialClassId: // value for 'materialClassId'
 *   },
 * });
 */
export function useSetMaterialClassToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetMaterialClassToDisabledMutation, Operations.SetMaterialClassToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetMaterialClassToDisabledMutation, Operations.SetMaterialClassToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetMaterialClassToDisabledMutation, Operations.SetMaterialClassToDisabledMutationVariables>(Operations.SetMaterialClassToDisabledDocument, options);
          }
export type SetMaterialClassToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetMaterialClassToDisabledMutation, Operations.SetMaterialClassToDisabledMutationVariables>;

/**
 * __useUpdateMaterialClassMutation__
 *
 * To run a mutation, you first call `useUpdateMaterialClassMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaterialClassMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateMaterialClassMutation({
 *   variables: {
 *      materialClassId: // value for 'materialClassId'
 *      materialClass: // value for 'materialClass'
 *      udfSchema: // value for 'udfSchema'
 *      materialModelProcesses: // value for 'materialModelProcesses'
 *      materialClassProcesses: // value for 'materialClassProcesses'
 *      classIcon: // value for 'classIcon'
 *      updateImage: // value for 'updateImage'
 *      matModUdfDefaults: // value for 'matModUdfDefaults'
 *      deleteMatModelProcessInputs: // value for 'deleteMatModelProcessInputs'
 *      deleteMatUdfDefaultDataInputs: // value for 'deleteMatUdfDefaultDataInputs'
 *      deleteMatClassProcessInputs: // value for 'deleteMatClassProcessInputs'
 *      addMaterialClassAttributeSettings: // value for 'addMaterialClassAttributeSettings'
 *      updateMaterialClassAttributeSettings: // value for 'updateMaterialClassAttributeSettings'
 *      deleteMaterialClassAttributeSettings: // value for 'deleteMaterialClassAttributeSettings'
 *   },
 * });
 */
export function useUpdateMaterialClassMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialClassMutation, Operations.UpdateMaterialClassMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialClassMutation, Operations.UpdateMaterialClassMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateMaterialClassMutation, Operations.UpdateMaterialClassMutationVariables>(Operations.UpdateMaterialClassDocument, options);
          }
export type UpdateMaterialClassMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateMaterialClassMutation, Operations.UpdateMaterialClassMutationVariables>;

/**
 * __useUpdateMaterialClassAttributesMutation__
 *
 * To run a mutation, you first call `useUpdateMaterialClassAttributesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaterialClassAttributesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateMaterialClassAttributesMutation({
 *   variables: {
 *      materialClassId: // value for 'materialClassId'
 *      materialClassAttrInput: // value for 'materialClassAttrInput'
 *      attributeIdList: // value for 'attributeIdList'
 *   },
 * });
 */
export function useUpdateMaterialClassAttributesMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialClassAttributesMutation, Operations.UpdateMaterialClassAttributesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialClassAttributesMutation, Operations.UpdateMaterialClassAttributesMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateMaterialClassAttributesMutation, Operations.UpdateMaterialClassAttributesMutationVariables>(Operations.UpdateMaterialClassAttributesDocument, options);
          }
export type UpdateMaterialClassAttributesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateMaterialClassAttributesMutation, Operations.UpdateMaterialClassAttributesMutationVariables>;

/**
 * __useUploadMaterialClassFileMutation__
 *
 * To run a mutation, you first call `useUploadMaterialClassFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUploadMaterialClassFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUploadMaterialClassFileMutation({
 *   variables: {
 *      materialClassFileInput: // value for 'materialClassFileInput'
 *   },
 * });
 */
export function useUploadMaterialClassFileMutation(options: VueApolloComposable.UseMutationOptions<Operations.UploadMaterialClassFileMutation, Operations.UploadMaterialClassFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UploadMaterialClassFileMutation, Operations.UploadMaterialClassFileMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UploadMaterialClassFileMutation, Operations.UploadMaterialClassFileMutationVariables>(Operations.UploadMaterialClassFileDocument, options);
          }
export type UploadMaterialClassFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UploadMaterialClassFileMutation, Operations.UploadMaterialClassFileMutationVariables>;

/**
 * __useAddNewMaterialMutation__
 *
 * To run a mutation, you first call `useAddNewMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddNewMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddNewMaterialMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAddNewMaterialMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddNewMaterialMutation, Operations.AddNewMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddNewMaterialMutation, Operations.AddNewMaterialMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddNewMaterialMutation, Operations.AddNewMaterialMutationVariables>(Operations.AddNewMaterialDocument, options);
          }
export type AddNewMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddNewMaterialMutation, Operations.AddNewMaterialMutationVariables>;

/**
 * __useGetAllAttributeSchemasQuery__
 *
 * To run a query within a Vue component, call `useGetAllAttributeSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAttributeSchemasQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllAttributeSchemasQuery(
 *   {
 *      schemaTypes: // value for 'schemaTypes'
 *   }
 * );
 */
export function useGetAllAttributeSchemasQuery(variables: Operations.GetAllAttributeSchemasQueryVariables | VueCompositionApi.Ref<Operations.GetAllAttributeSchemasQueryVariables> | ReactiveFunction<Operations.GetAllAttributeSchemasQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllAttributeSchemasQuery, Operations.GetAllAttributeSchemasQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllAttributeSchemasQuery, Operations.GetAllAttributeSchemasQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllAttributeSchemasQuery, Operations.GetAllAttributeSchemasQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllAttributeSchemasQuery, Operations.GetAllAttributeSchemasQueryVariables>(Operations.GetAllAttributeSchemasDocument, variables, options);
          }
export type GetAllAttributeSchemasQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllAttributeSchemasQuery, Operations.GetAllAttributeSchemasQueryVariables>;

/**
 * __useGetAllLocationsForMaterialSearchQuery__
 *
 * To run a query within a Vue component, call `useGetAllLocationsForMaterialSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsForMaterialSearchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllLocationsForMaterialSearchQuery(
 *   {
 *   }
 * );
 */
export function useGetAllLocationsForMaterialSearchQuery(options: VueApolloComposable.UseQueryOptions<Operations.GetAllLocationsForMaterialSearchQuery, Operations.GetAllLocationsForMaterialSearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllLocationsForMaterialSearchQuery, Operations.GetAllLocationsForMaterialSearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllLocationsForMaterialSearchQuery, Operations.GetAllLocationsForMaterialSearchQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllLocationsForMaterialSearchQuery, undefined>(Operations.GetAllLocationsForMaterialSearchDocument, undefined, options);
          }
export type GetAllLocationsForMaterialSearchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllLocationsForMaterialSearchQuery, Operations.GetAllLocationsForMaterialSearchQueryVariables>;

/**
 * __useGetContextDefaultModelQuery__
 *
 * To run a query within a Vue component, call `useGetContextDefaultModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextDefaultModelQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetContextDefaultModelQuery(
 *   {
 *      contextId: // value for 'contextId'
 *   }
 * );
 */
export function useGetContextDefaultModelQuery(variables: Operations.GetContextDefaultModelQueryVariables | VueCompositionApi.Ref<Operations.GetContextDefaultModelQueryVariables> | ReactiveFunction<Operations.GetContextDefaultModelQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetContextDefaultModelQuery, Operations.GetContextDefaultModelQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetContextDefaultModelQuery, Operations.GetContextDefaultModelQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetContextDefaultModelQuery, Operations.GetContextDefaultModelQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetContextDefaultModelQuery, Operations.GetContextDefaultModelQueryVariables>(Operations.GetContextDefaultModelDocument, variables, options);
          }
export type GetContextDefaultModelQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetContextDefaultModelQuery, Operations.GetContextDefaultModelQueryVariables>;

/**
 * __useGetMaterialQuery__
 *
 * To run a query within a Vue component, call `useGetMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetMaterialQuery(
 *   {
 *      materialId: // value for 'materialId'
 *   }
 * );
 */
export function useGetMaterialQuery(variables: Operations.GetMaterialQueryVariables | VueCompositionApi.Ref<Operations.GetMaterialQueryVariables> | ReactiveFunction<Operations.GetMaterialQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetMaterialQuery, Operations.GetMaterialQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetMaterialQuery, Operations.GetMaterialQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetMaterialQuery, Operations.GetMaterialQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetMaterialQuery, Operations.GetMaterialQueryVariables>(Operations.GetMaterialDocument, variables, options);
          }
export type GetMaterialQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetMaterialQuery, Operations.GetMaterialQueryVariables>;

/**
 * __useGetMaterialMarriageDataQuery__
 *
 * To run a query within a Vue component, call `useGetMaterialMarriageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialMarriageDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetMaterialMarriageDataQuery(
 *   {
 *      material_id: // value for 'material_id'
 *      direction: // value for 'direction'
 *   }
 * );
 */
export function useGetMaterialMarriageDataQuery(variables: Operations.GetMaterialMarriageDataQueryVariables | VueCompositionApi.Ref<Operations.GetMaterialMarriageDataQueryVariables> | ReactiveFunction<Operations.GetMaterialMarriageDataQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetMaterialMarriageDataQuery, Operations.GetMaterialMarriageDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetMaterialMarriageDataQuery, Operations.GetMaterialMarriageDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetMaterialMarriageDataQuery, Operations.GetMaterialMarriageDataQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetMaterialMarriageDataQuery, Operations.GetMaterialMarriageDataQueryVariables>(Operations.GetMaterialMarriageDataDocument, variables, options);
          }
export type GetMaterialMarriageDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetMaterialMarriageDataQuery, Operations.GetMaterialMarriageDataQueryVariables>;

/**
 * __useGetAllMaterialQuery__
 *
 * To run a query within a Vue component, call `useGetAllMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMaterialQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllMaterialQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllMaterialQuery(variables: Operations.GetAllMaterialQueryVariables | VueCompositionApi.Ref<Operations.GetAllMaterialQueryVariables> | ReactiveFunction<Operations.GetAllMaterialQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialQuery, Operations.GetAllMaterialQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialQuery, Operations.GetAllMaterialQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllMaterialQuery, Operations.GetAllMaterialQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllMaterialQuery, Operations.GetAllMaterialQueryVariables>(Operations.GetAllMaterialDocument, variables, options);
          }
export type GetAllMaterialQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllMaterialQuery, Operations.GetAllMaterialQueryVariables>;

/**
 * __useInsertMaterialAttributeMutation__
 *
 * To run a mutation, you first call `useInsertMaterialAttributeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertMaterialAttributeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertMaterialAttributeMutation({
 *   variables: {
 *      materialAttributes: // value for 'materialAttributes'
 *   },
 * });
 */
export function useInsertMaterialAttributeMutation(options: VueApolloComposable.UseMutationOptions<Operations.InsertMaterialAttributeMutation, Operations.InsertMaterialAttributeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.InsertMaterialAttributeMutation, Operations.InsertMaterialAttributeMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.InsertMaterialAttributeMutation, Operations.InsertMaterialAttributeMutationVariables>(Operations.InsertMaterialAttributeDocument, options);
          }
export type InsertMaterialAttributeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.InsertMaterialAttributeMutation, Operations.InsertMaterialAttributeMutationVariables>;

/**
 * __useProcessMaterialStateReportQuery__
 *
 * To run a query within a Vue component, call `useProcessMaterialStateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessMaterialStateReportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProcessMaterialStateReportQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *      includeMaterial: // value for 'includeMaterial'
 *      includePdeObj: // value for 'includePdeObj'
 *   }
 * );
 */
export function useProcessMaterialStateReportQuery(variables: Operations.ProcessMaterialStateReportQueryVariables | VueCompositionApi.Ref<Operations.ProcessMaterialStateReportQueryVariables> | ReactiveFunction<Operations.ProcessMaterialStateReportQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.ProcessMaterialStateReportQuery, Operations.ProcessMaterialStateReportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.ProcessMaterialStateReportQuery, Operations.ProcessMaterialStateReportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.ProcessMaterialStateReportQuery, Operations.ProcessMaterialStateReportQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.ProcessMaterialStateReportQuery, Operations.ProcessMaterialStateReportQueryVariables>(Operations.ProcessMaterialStateReportDocument, variables, options);
          }
export type ProcessMaterialStateReportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.ProcessMaterialStateReportQuery, Operations.ProcessMaterialStateReportQueryVariables>;

/**
 * __useMaterialSearchQuery__
 *
 * To run a query within a Vue component, call `useMaterialSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialSearchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMaterialSearchQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useMaterialSearchQuery(variables: Operations.MaterialSearchQueryVariables | VueCompositionApi.Ref<Operations.MaterialSearchQueryVariables> | ReactiveFunction<Operations.MaterialSearchQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.MaterialSearchQuery, Operations.MaterialSearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.MaterialSearchQuery, Operations.MaterialSearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.MaterialSearchQuery, Operations.MaterialSearchQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.MaterialSearchQuery, Operations.MaterialSearchQueryVariables>(Operations.MaterialSearchDocument, variables, options);
          }
export type MaterialSearchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.MaterialSearchQuery, Operations.MaterialSearchQueryVariables>;

/**
 * __useGetMaterialUdfColumnsQuery__
 *
 * To run a query within a Vue component, call `useGetMaterialUdfColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialUdfColumnsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetMaterialUdfColumnsQuery(
 *   {
 *      materialModelId: // value for 'materialModelId'
 *      getDisabled: // value for 'getDisabled'
 *   }
 * );
 */
export function useGetMaterialUdfColumnsQuery(variables: Operations.GetMaterialUdfColumnsQueryVariables | VueCompositionApi.Ref<Operations.GetMaterialUdfColumnsQueryVariables> | ReactiveFunction<Operations.GetMaterialUdfColumnsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetMaterialUdfColumnsQuery, Operations.GetMaterialUdfColumnsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetMaterialUdfColumnsQuery, Operations.GetMaterialUdfColumnsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetMaterialUdfColumnsQuery, Operations.GetMaterialUdfColumnsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetMaterialUdfColumnsQuery, Operations.GetMaterialUdfColumnsQueryVariables>(Operations.GetMaterialUdfColumnsDocument, variables, options);
          }
export type GetMaterialUdfColumnsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetMaterialUdfColumnsQuery, Operations.GetMaterialUdfColumnsQueryVariables>;

/**
 * __useGetMaterialUdfDefaultDataQuery__
 *
 * To run a query within a Vue component, call `useGetMaterialUdfDefaultDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialUdfDefaultDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetMaterialUdfDefaultDataQuery(
 *   {
 *      materialModelId: // value for 'materialModelId'
 *      getDisabled: // value for 'getDisabled'
 *   }
 * );
 */
export function useGetMaterialUdfDefaultDataQuery(variables: Operations.GetMaterialUdfDefaultDataQueryVariables | VueCompositionApi.Ref<Operations.GetMaterialUdfDefaultDataQueryVariables> | ReactiveFunction<Operations.GetMaterialUdfDefaultDataQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetMaterialUdfDefaultDataQuery, Operations.GetMaterialUdfDefaultDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetMaterialUdfDefaultDataQuery, Operations.GetMaterialUdfDefaultDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetMaterialUdfDefaultDataQuery, Operations.GetMaterialUdfDefaultDataQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetMaterialUdfDefaultDataQuery, Operations.GetMaterialUdfDefaultDataQueryVariables>(Operations.GetMaterialUdfDefaultDataDocument, variables, options);
          }
export type GetMaterialUdfDefaultDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetMaterialUdfDefaultDataQuery, Operations.GetMaterialUdfDefaultDataQueryVariables>;

/**
 * __useUpdateMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateMaterialMutation({
 *   variables: {
 *      materialId: // value for 'materialId'
 *      material: // value for 'material'
 *      attrsToDelete: // value for 'attrsToDelete'
 *      attrsToAdd: // value for 'attrsToAdd'
 *      materialUdfs: // value for 'materialUdfs'
 *   },
 * });
 */
export function useUpdateMaterialMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialMutation, Operations.UpdateMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateMaterialMutation, Operations.UpdateMaterialMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateMaterialMutation, Operations.UpdateMaterialMutationVariables>(Operations.UpdateMaterialDocument, options);
          }
export type UpdateMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateMaterialMutation, Operations.UpdateMaterialMutationVariables>;

/**
 * __useInsertAppConfigVersionMutation__
 *
 * To run a mutation, you first call `useInsertAppConfigVersionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertAppConfigVersionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertAppConfigVersionMutation({
 *   variables: {
 *      appConfigInput: // value for 'appConfigInput'
 *   },
 * });
 */
export function useInsertAppConfigVersionMutation(options: VueApolloComposable.UseMutationOptions<Operations.InsertAppConfigVersionMutation, Operations.InsertAppConfigVersionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.InsertAppConfigVersionMutation, Operations.InsertAppConfigVersionMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.InsertAppConfigVersionMutation, Operations.InsertAppConfigVersionMutationVariables>(Operations.InsertAppConfigVersionDocument, options);
          }
export type InsertAppConfigVersionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.InsertAppConfigVersionMutation, Operations.InsertAppConfigVersionMutationVariables>;

/**
 * __useMigrateOldAppConfigMutation__
 *
 * To run a mutation, you first call `useMigrateOldAppConfigMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useMigrateOldAppConfigMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useMigrateOldAppConfigMutation({
 *   variables: {
 *      newAppCfgVersion: // value for 'newAppCfgVersion'
 *      oldAppCfgVersionId: // value for 'oldAppCfgVersionId'
 *   },
 * });
 */
export function useMigrateOldAppConfigMutation(options: VueApolloComposable.UseMutationOptions<Operations.MigrateOldAppConfigMutation, Operations.MigrateOldAppConfigMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.MigrateOldAppConfigMutation, Operations.MigrateOldAppConfigMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.MigrateOldAppConfigMutation, Operations.MigrateOldAppConfigMutationVariables>(Operations.MigrateOldAppConfigDocument, options);
          }
export type MigrateOldAppConfigMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.MigrateOldAppConfigMutation, Operations.MigrateOldAppConfigMutationVariables>;

/**
 * __useAddMaterialModelAttributeSettingsMutation__
 *
 * To run a mutation, you first call `useAddMaterialModelAttributeSettingsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddMaterialModelAttributeSettingsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddMaterialModelAttributeSettingsMutation({
 *   variables: {
 *      addMaterialModelAttributeSettings: // value for 'addMaterialModelAttributeSettings'
 *   },
 * });
 */
export function useAddMaterialModelAttributeSettingsMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddMaterialModelAttributeSettingsMutation, Operations.AddMaterialModelAttributeSettingsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddMaterialModelAttributeSettingsMutation, Operations.AddMaterialModelAttributeSettingsMutationVariables>> = {}) {
            return VueApolloComposable.useMutation<Operations.AddMaterialModelAttributeSettingsMutation, Operations.AddMaterialModelAttributeSettingsMutationVariables>(Operations.AddMaterialModelAttributeSettingsDocument, options);
          }
export type AddMaterialModelAttributeSettingsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddMaterialModelAttributeSettingsMutation, Operations.AddMaterialModelAttributeSettingsMutationVariables>;

/**
 * __useAddModelNumberMutation__
 *
 * To run a mutation, you first call `useAddModelNumberMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddModelNumberMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddModelNumberMutation({
 *   variables: {
 *      modelNumber: // value for 'modelNumber'
 *   },
 * });
 */
export function useAddModelNumberMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddModelNumberMutation, Operations.AddModelNumberMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddModelNumberMutation, Operations.AddModelNumberMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddModelNumberMutation, Operations.AddModelNumberMutationVariables>(Operations.AddModelNumberDocument, options);
          }
export type AddModelNumberMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddModelNumberMutation, Operations.AddModelNumberMutationVariables>;

/**
 * __useGetModelNumberQuery__
 *
 * To run a query within a Vue component, call `useGetModelNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelNumberQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetModelNumberQuery(
 *   {
 *      modelNumberId: // value for 'modelNumberId'
 *   }
 * );
 */
export function useGetModelNumberQuery(variables: Operations.GetModelNumberQueryVariables | VueCompositionApi.Ref<Operations.GetModelNumberQueryVariables> | ReactiveFunction<Operations.GetModelNumberQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetModelNumberQuery, Operations.GetModelNumberQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetModelNumberQuery, Operations.GetModelNumberQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetModelNumberQuery, Operations.GetModelNumberQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetModelNumberQuery, Operations.GetModelNumberQueryVariables>(Operations.GetModelNumberDocument, variables, options);
          }
export type GetModelNumberQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetModelNumberQuery, Operations.GetModelNumberQueryVariables>;

/**
 * __useGetAllModelNumbersQuery__
 *
 * To run a query within a Vue component, call `useGetAllModelNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllModelNumbersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllModelNumbersQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllModelNumbersQuery(variables: Operations.GetAllModelNumbersQueryVariables | VueCompositionApi.Ref<Operations.GetAllModelNumbersQueryVariables> | ReactiveFunction<Operations.GetAllModelNumbersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllModelNumbersQuery, Operations.GetAllModelNumbersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllModelNumbersQuery, Operations.GetAllModelNumbersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllModelNumbersQuery, Operations.GetAllModelNumbersQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllModelNumbersQuery, Operations.GetAllModelNumbersQueryVariables>(Operations.GetAllModelNumbersDocument, variables, options);
          }
export type GetAllModelNumbersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllModelNumbersQuery, Operations.GetAllModelNumbersQueryVariables>;

/**
 * __useUpdateModelNumberMutation__
 *
 * To run a mutation, you first call `useUpdateModelNumberMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelNumberMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateModelNumberMutation({
 *   variables: {
 *      modelNumberId: // value for 'modelNumberId'
 *      modelNumber: // value for 'modelNumber'
 *      modelIcon: // value for 'modelIcon'
 *      updateImage: // value for 'updateImage'
 *      matModUdfDefaults: // value for 'matModUdfDefaults'
 *      addMaterialModelAttributeSettings: // value for 'addMaterialModelAttributeSettings'
 *      updateMaterialModelAttributeSettings: // value for 'updateMaterialModelAttributeSettings'
 *      deleteMaterialModelAttributeSettings: // value for 'deleteMaterialModelAttributeSettings'
 *   },
 * });
 */
export function useUpdateModelNumberMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateModelNumberMutation, Operations.UpdateModelNumberMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateModelNumberMutation, Operations.UpdateModelNumberMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateModelNumberMutation, Operations.UpdateModelNumberMutationVariables>(Operations.UpdateModelNumberDocument, options);
          }
export type UpdateModelNumberMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateModelNumberMutation, Operations.UpdateModelNumberMutationVariables>;

/**
 * __useAddAssetMutation__
 *
 * To run a mutation, you first call `useAddAssetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAssetMutation({
 *   variables: {
 *      asset: // value for 'asset'
 *   },
 * });
 */
export function useAddAssetMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddAssetMutation, Operations.AddAssetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddAssetMutation, Operations.AddAssetMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddAssetMutation, Operations.AddAssetMutationVariables>(Operations.AddAssetDocument, options);
          }
export type AddAssetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddAssetMutation, Operations.AddAssetMutationVariables>;

/**
 * __useAddDataLabelMutation__
 *
 * To run a mutation, you first call `useAddDataLabelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddDataLabelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddDataLabelMutation({
 *   variables: {
 *      data_label: // value for 'data_label'
 *   },
 * });
 */
export function useAddDataLabelMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddDataLabelMutation, Operations.AddDataLabelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddDataLabelMutation, Operations.AddDataLabelMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddDataLabelMutation, Operations.AddDataLabelMutationVariables>(Operations.AddDataLabelDocument, options);
          }
export type AddDataLabelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddDataLabelMutation, Operations.AddDataLabelMutationVariables>;

/**
 * __useGetAllAssetCodesQuery__
 *
 * To run a query within a Vue component, call `useGetAllAssetCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetCodesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllAssetCodesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   }
 * );
 */
export function useGetAllAssetCodesQuery(variables: Operations.GetAllAssetCodesQueryVariables | VueCompositionApi.Ref<Operations.GetAllAssetCodesQueryVariables> | ReactiveFunction<Operations.GetAllAssetCodesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllAssetCodesQuery, Operations.GetAllAssetCodesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllAssetCodesQuery, Operations.GetAllAssetCodesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllAssetCodesQuery, Operations.GetAllAssetCodesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllAssetCodesQuery, Operations.GetAllAssetCodesQueryVariables>(Operations.GetAllAssetCodesDocument, variables, options);
          }
export type GetAllAssetCodesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllAssetCodesQuery, Operations.GetAllAssetCodesQueryVariables>;

/**
 * __useGetAllAssetTypesQuery__
 *
 * To run a query within a Vue component, call `useGetAllAssetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllAssetTypesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useGetAllAssetTypesQuery(variables: Operations.GetAllAssetTypesQueryVariables | VueCompositionApi.Ref<Operations.GetAllAssetTypesQueryVariables> | ReactiveFunction<Operations.GetAllAssetTypesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllAssetTypesQuery, Operations.GetAllAssetTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllAssetTypesQuery, Operations.GetAllAssetTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllAssetTypesQuery, Operations.GetAllAssetTypesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllAssetTypesQuery, Operations.GetAllAssetTypesQueryVariables>(Operations.GetAllAssetTypesDocument, variables, options);
          }
export type GetAllAssetTypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllAssetTypesQuery, Operations.GetAllAssetTypesQueryVariables>;

/**
 * __useCatReportQuery__
 *
 * To run a query within a Vue component, call `useCatReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatReportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCatReportQuery(
 *   {
 *      args: // value for 'args'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useCatReportQuery(variables: Operations.CatReportQueryVariables | VueCompositionApi.Ref<Operations.CatReportQueryVariables> | ReactiveFunction<Operations.CatReportQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.CatReportQuery, Operations.CatReportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.CatReportQuery, Operations.CatReportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.CatReportQuery, Operations.CatReportQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.CatReportQuery, Operations.CatReportQueryVariables>(Operations.CatReportDocument, variables, options);
          }
export type CatReportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.CatReportQuery, Operations.CatReportQueryVariables>;

/**
 * __useFisDataLabelSelectorQuery__
 *
 * To run a query within a Vue component, call `useFisDataLabelSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFisDataLabelSelectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFisDataLabelSelectorQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useFisDataLabelSelectorQuery(variables: Operations.FisDataLabelSelectorQueryVariables | VueCompositionApi.Ref<Operations.FisDataLabelSelectorQueryVariables> | ReactiveFunction<Operations.FisDataLabelSelectorQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.FisDataLabelSelectorQuery, Operations.FisDataLabelSelectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.FisDataLabelSelectorQuery, Operations.FisDataLabelSelectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.FisDataLabelSelectorQuery, Operations.FisDataLabelSelectorQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.FisDataLabelSelectorQuery, Operations.FisDataLabelSelectorQueryVariables>(Operations.FisDataLabelSelectorDocument, variables, options);
          }
export type FisDataLabelSelectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.FisDataLabelSelectorQuery, Operations.FisDataLabelSelectorQueryVariables>;

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssetMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteAssetMutation, Operations.DeleteAssetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteAssetMutation, Operations.DeleteAssetMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteAssetMutation, Operations.DeleteAssetMutationVariables>(Operations.DeleteAssetDocument, options);
          }
export type DeleteAssetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteAssetMutation, Operations.DeleteAssetMutationVariables>;

/**
 * __useFisDetailReportQuery__
 *
 * To run a query within a Vue component, call `useFisDetailReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFisDetailReportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFisDetailReportQuery(
 *   {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   }
 * );
 */
export function useFisDetailReportQuery(variables: Operations.FisDetailReportQueryVariables | VueCompositionApi.Ref<Operations.FisDetailReportQueryVariables> | ReactiveFunction<Operations.FisDetailReportQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.FisDetailReportQuery, Operations.FisDetailReportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.FisDetailReportQuery, Operations.FisDetailReportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.FisDetailReportQuery, Operations.FisDetailReportQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.FisDetailReportQuery, Operations.FisDetailReportQueryVariables>(Operations.FisDetailReportDocument, variables, options);
          }
export type FisDetailReportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.FisDetailReportQuery, Operations.FisDetailReportQueryVariables>;

/**
 * __useFaultReportQuery__
 *
 * To run a query within a Vue component, call `useFaultReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaultReportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFaultReportQuery(
 *   {
 *      args: // value for 'args'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useFaultReportQuery(variables: Operations.FaultReportQueryVariables | VueCompositionApi.Ref<Operations.FaultReportQueryVariables> | ReactiveFunction<Operations.FaultReportQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.FaultReportQuery, Operations.FaultReportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.FaultReportQuery, Operations.FaultReportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.FaultReportQuery, Operations.FaultReportQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.FaultReportQuery, Operations.FaultReportQueryVariables>(Operations.FaultReportDocument, variables, options);
          }
export type FaultReportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.FaultReportQuery, Operations.FaultReportQueryVariables>;

/**
 * __useGetAssetsQuery__
 *
 * To run a query within a Vue component, call `useGetAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAssetsQuery(
 *   {
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useGetAssetsQuery(variables: Operations.GetAssetsQueryVariables | VueCompositionApi.Ref<Operations.GetAssetsQueryVariables> | ReactiveFunction<Operations.GetAssetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAssetsQuery, Operations.GetAssetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAssetsQuery, Operations.GetAssetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAssetsQuery, Operations.GetAssetsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAssetsQuery, Operations.GetAssetsQueryVariables>(Operations.GetAssetsDocument, variables, options);
          }
export type GetAssetsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAssetsQuery, Operations.GetAssetsQueryVariables>;

/**
 * __useMtbfReportQuery__
 *
 * To run a query within a Vue component, call `useMtbfReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMtbfReportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMtbfReportQuery(
 *   {
 *      args: // value for 'args'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useMtbfReportQuery(variables: Operations.MtbfReportQueryVariables | VueCompositionApi.Ref<Operations.MtbfReportQueryVariables> | ReactiveFunction<Operations.MtbfReportQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.MtbfReportQuery, Operations.MtbfReportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.MtbfReportQuery, Operations.MtbfReportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.MtbfReportQuery, Operations.MtbfReportQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.MtbfReportQuery, Operations.MtbfReportQueryVariables>(Operations.MtbfReportDocument, variables, options);
          }
export type MtbfReportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.MtbfReportQuery, Operations.MtbfReportQueryVariables>;

/**
 * __useOeeReportQuery__
 *
 * To run a query within a Vue component, call `useOeeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOeeReportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOeeReportQuery(
 *   {
 *      args: // value for 'args'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useOeeReportQuery(variables: Operations.OeeReportQueryVariables | VueCompositionApi.Ref<Operations.OeeReportQueryVariables> | ReactiveFunction<Operations.OeeReportQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.OeeReportQuery, Operations.OeeReportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.OeeReportQuery, Operations.OeeReportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.OeeReportQuery, Operations.OeeReportQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.OeeReportQuery, Operations.OeeReportQueryVariables>(Operations.OeeReportDocument, variables, options);
          }
export type OeeReportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.OeeReportQuery, Operations.OeeReportQueryVariables>;

/**
 * __useStateReportQuery__
 *
 * To run a query within a Vue component, call `useStateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStateReportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStateReportQuery(
 *   {
 *      args: // value for 'args'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useStateReportQuery(variables: Operations.StateReportQueryVariables | VueCompositionApi.Ref<Operations.StateReportQueryVariables> | ReactiveFunction<Operations.StateReportQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.StateReportQuery, Operations.StateReportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.StateReportQuery, Operations.StateReportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.StateReportQuery, Operations.StateReportQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.StateReportQuery, Operations.StateReportQueryVariables>(Operations.StateReportDocument, variables, options);
          }
export type StateReportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.StateReportQuery, Operations.StateReportQueryVariables>;

/**
 * __useDistinctDataLabelsQuery__
 *
 * To run a query within a Vue component, call `useDistinctDataLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistinctDataLabelsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDistinctDataLabelsQuery(
 *   {
 *      args: // value for 'args'
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useDistinctDataLabelsQuery(variables: Operations.DistinctDataLabelsQueryVariables | VueCompositionApi.Ref<Operations.DistinctDataLabelsQueryVariables> | ReactiveFunction<Operations.DistinctDataLabelsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.DistinctDataLabelsQuery, Operations.DistinctDataLabelsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.DistinctDataLabelsQuery, Operations.DistinctDataLabelsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.DistinctDataLabelsQuery, Operations.DistinctDataLabelsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.DistinctDataLabelsQuery, Operations.DistinctDataLabelsQueryVariables>(Operations.DistinctDataLabelsDocument, variables, options);
          }
export type DistinctDataLabelsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.DistinctDataLabelsQuery, Operations.DistinctDataLabelsQueryVariables>;

/**
 * __useGetAllDataLabelsQuery__
 *
 * To run a query within a Vue component, call `useGetAllDataLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDataLabelsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllDataLabelsQuery(
 *   {
 *      filter: // value for 'filter'
 *      order_by: // value for 'order_by'
 *   }
 * );
 */
export function useGetAllDataLabelsQuery(variables: Operations.GetAllDataLabelsQueryVariables | VueCompositionApi.Ref<Operations.GetAllDataLabelsQueryVariables> | ReactiveFunction<Operations.GetAllDataLabelsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllDataLabelsQuery, Operations.GetAllDataLabelsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllDataLabelsQuery, Operations.GetAllDataLabelsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllDataLabelsQuery, Operations.GetAllDataLabelsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllDataLabelsQuery, Operations.GetAllDataLabelsQueryVariables>(Operations.GetAllDataLabelsDocument, variables, options);
          }
export type GetAllDataLabelsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllDataLabelsQuery, Operations.GetAllDataLabelsQueryVariables>;

/**
 * __useAddAssetTypeMutation__
 *
 * To run a mutation, you first call `useAddAssetTypeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetTypeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAssetTypeMutation({
 *   variables: {
 *      assetType: // value for 'assetType'
 *   },
 * });
 */
export function useAddAssetTypeMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddAssetTypeMutation, Operations.AddAssetTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddAssetTypeMutation, Operations.AddAssetTypeMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddAssetTypeMutation, Operations.AddAssetTypeMutationVariables>(Operations.AddAssetTypeDocument, options);
          }
export type AddAssetTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddAssetTypeMutation, Operations.AddAssetTypeMutationVariables>;

/**
 * __useGetAssetTypeQuery__
 *
 * To run a query within a Vue component, call `useGetAssetTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetTypeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAssetTypeQuery(
 *   {
 *      assetTypeId: // value for 'assetTypeId'
 *   }
 * );
 */
export function useGetAssetTypeQuery(variables: Operations.GetAssetTypeQueryVariables | VueCompositionApi.Ref<Operations.GetAssetTypeQueryVariables> | ReactiveFunction<Operations.GetAssetTypeQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAssetTypeQuery, Operations.GetAssetTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAssetTypeQuery, Operations.GetAssetTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAssetTypeQuery, Operations.GetAssetTypeQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAssetTypeQuery, Operations.GetAssetTypeQueryVariables>(Operations.GetAssetTypeDocument, variables, options);
          }
export type GetAssetTypeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAssetTypeQuery, Operations.GetAssetTypeQueryVariables>;

/**
 * __useUpdateAssetTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAssetTypeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetTypeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAssetTypeMutation({
 *   variables: {
 *      assetTypeId: // value for 'assetTypeId'
 *      assetType: // value for 'assetType'
 *      insertAssetCodes: // value for 'insertAssetCodes'
 *      deleteAssetCodeBoolExp: // value for 'deleteAssetCodeBoolExp'
 *   },
 * });
 */
export function useUpdateAssetTypeMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateAssetTypeMutation, Operations.UpdateAssetTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateAssetTypeMutation, Operations.UpdateAssetTypeMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateAssetTypeMutation, Operations.UpdateAssetTypeMutationVariables>(Operations.UpdateAssetTypeDocument, options);
          }
export type UpdateAssetTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateAssetTypeMutation, Operations.UpdateAssetTypeMutationVariables>;

/**
 * __useGetAllSchemaPropertiesQuery__
 *
 * To run a query within a Vue component, call `useGetAllSchemaPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSchemaPropertiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllSchemaPropertiesQuery(
 *   {
 *      schemaId: // value for 'schemaId'
 *   }
 * );
 */
export function useGetAllSchemaPropertiesQuery(variables: Operations.GetAllSchemaPropertiesQueryVariables | VueCompositionApi.Ref<Operations.GetAllSchemaPropertiesQueryVariables> | ReactiveFunction<Operations.GetAllSchemaPropertiesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllSchemaPropertiesQuery, Operations.GetAllSchemaPropertiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllSchemaPropertiesQuery, Operations.GetAllSchemaPropertiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllSchemaPropertiesQuery, Operations.GetAllSchemaPropertiesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllSchemaPropertiesQuery, Operations.GetAllSchemaPropertiesQueryVariables>(Operations.GetAllSchemaPropertiesDocument, variables, options);
          }
export type GetAllSchemaPropertiesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllSchemaPropertiesQuery, Operations.GetAllSchemaPropertiesQueryVariables>;

/**
 * __useSaveProcessMutation__
 *
 * To run a mutation, you first call `useSaveProcessMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveProcessMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveProcessMutation({
 *   variables: {
 *      processInput: // value for 'processInput'
 *      processOnConflict: // value for 'processOnConflict'
 *   },
 * });
 */
export function useSaveProcessMutation(options: VueApolloComposable.UseMutationOptions<Operations.SaveProcessMutation, Operations.SaveProcessMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SaveProcessMutation, Operations.SaveProcessMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SaveProcessMutation, Operations.SaveProcessMutationVariables>(Operations.SaveProcessDocument, options);
          }
export type SaveProcessMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SaveProcessMutation, Operations.SaveProcessMutationVariables>;

/**
 * __useGetProcessQuery__
 *
 * To run a query within a Vue component, call `useGetProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetProcessQuery(
 *   {
 *      process_id: // value for 'process_id'
 *   }
 * );
 */
export function useGetProcessQuery(variables: Operations.GetProcessQueryVariables | VueCompositionApi.Ref<Operations.GetProcessQueryVariables> | ReactiveFunction<Operations.GetProcessQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetProcessQuery, Operations.GetProcessQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetProcessQuery, Operations.GetProcessQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetProcessQuery, Operations.GetProcessQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetProcessQuery, Operations.GetProcessQueryVariables>(Operations.GetProcessDocument, variables, options);
          }
export type GetProcessQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetProcessQuery, Operations.GetProcessQueryVariables>;

/**
 * __useGetProcessesForListQuery__
 *
 * To run a query within a Vue component, call `useGetProcessesForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessesForListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetProcessesForListQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useGetProcessesForListQuery(variables: Operations.GetProcessesForListQueryVariables | VueCompositionApi.Ref<Operations.GetProcessesForListQueryVariables> | ReactiveFunction<Operations.GetProcessesForListQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetProcessesForListQuery, Operations.GetProcessesForListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetProcessesForListQuery, Operations.GetProcessesForListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetProcessesForListQuery, Operations.GetProcessesForListQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetProcessesForListQuery, Operations.GetProcessesForListQueryVariables>(Operations.GetProcessesForListDocument, variables, options);
          }
export type GetProcessesForListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetProcessesForListQuery, Operations.GetProcessesForListQueryVariables>;

/**
 * __useDeleteProcessMutation__
 *
 * To run a mutation, you first call `useDeleteProcessMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProcessMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProcessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProcessMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteProcessMutation, Operations.DeleteProcessMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteProcessMutation, Operations.DeleteProcessMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteProcessMutation, Operations.DeleteProcessMutationVariables>(Operations.DeleteProcessDocument, options);
          }
export type DeleteProcessMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteProcessMutation, Operations.DeleteProcessMutationVariables>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddRoleMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddRoleMutation, Operations.AddRoleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddRoleMutation, Operations.AddRoleMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddRoleMutation, Operations.AddRoleMutationVariables>(Operations.AddRoleDocument, options);
          }
export type AddRoleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddRoleMutation, Operations.AddRoleMutationVariables>;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a Vue component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRoleQuery(
 *   {
 *      queryFilter: // value for 'queryFilter'
 *   }
 * );
 */
export function useGetRoleQuery(variables: Operations.GetRoleQueryVariables | VueCompositionApi.Ref<Operations.GetRoleQueryVariables> | ReactiveFunction<Operations.GetRoleQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetRoleQuery, Operations.GetRoleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetRoleQuery, Operations.GetRoleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetRoleQuery, Operations.GetRoleQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetRoleQuery, Operations.GetRoleQueryVariables>(Operations.GetRoleDocument, variables, options);
          }
export type GetRoleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetRoleQuery, Operations.GetRoleQueryVariables>;

/**
 * __useGetRoleUsersQuery__
 *
 * To run a query within a Vue component, call `useGetRoleUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRoleUsersQuery(
 *   {
 *      roleId: // value for 'roleId'
 *   }
 * );
 */
export function useGetRoleUsersQuery(variables: Operations.GetRoleUsersQueryVariables | VueCompositionApi.Ref<Operations.GetRoleUsersQueryVariables> | ReactiveFunction<Operations.GetRoleUsersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetRoleUsersQuery, Operations.GetRoleUsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetRoleUsersQuery, Operations.GetRoleUsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetRoleUsersQuery, Operations.GetRoleUsersQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetRoleUsersQuery, Operations.GetRoleUsersQueryVariables>(Operations.GetRoleUsersDocument, variables, options);
          }
export type GetRoleUsersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetRoleUsersQuery, Operations.GetRoleUsersQueryVariables>;

/**
 * __useGetAllRolesQuery__
 *
 * To run a query within a Vue component, call `useGetAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllRolesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sortby: // value for 'sortby'
 *   }
 * );
 */
export function useGetAllRolesQuery(variables?: Operations.GetAllRolesQueryVariables | VueCompositionApi.Ref<Operations.GetAllRolesQueryVariables> | ReactiveFunction<Operations.GetAllRolesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllRolesQuery, Operations.GetAllRolesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllRolesQuery, Operations.GetAllRolesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllRolesQuery, Operations.GetAllRolesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllRolesQuery, Operations.GetAllRolesQueryVariables>(Operations.GetAllRolesDocument, variables, options);
          }
export type GetAllRolesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllRolesQuery, Operations.GetAllRolesQueryVariables>;

/**
 * __useSetRoleToDisabledMutation__
 *
 * To run a mutation, you first call `useSetRoleToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetRoleToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetRoleToDisabledMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useSetRoleToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetRoleToDisabledMutation, Operations.SetRoleToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetRoleToDisabledMutation, Operations.SetRoleToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetRoleToDisabledMutation, Operations.SetRoleToDisabledMutationVariables>(Operations.SetRoleToDisabledDocument, options);
          }
export type SetRoleToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetRoleToDisabledMutation, Operations.SetRoleToDisabledMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      role: // value for 'role'
 *      insertRoleAcls: // value for 'insertRoleAcls'
 *      deleteRoleAclsBoolExp: // value for 'deleteRoleAclsBoolExp'
 *   },
 * });
 */
export function useUpdateRoleMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateRoleMutation, Operations.UpdateRoleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateRoleMutation, Operations.UpdateRoleMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateRoleMutation, Operations.UpdateRoleMutationVariables>(Operations.UpdateRoleDocument, options);
          }
export type UpdateRoleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateRoleMutation, Operations.UpdateRoleMutationVariables>;

/**
 * __useAddCalendarMutation__
 *
 * To run a mutation, you first call `useAddCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCalendarMutation({
 *   variables: {
 *      calendar: // value for 'calendar'
 *   },
 * });
 */
export function useAddCalendarMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddCalendarMutation, Operations.AddCalendarMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddCalendarMutation, Operations.AddCalendarMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddCalendarMutation, Operations.AddCalendarMutationVariables>(Operations.AddCalendarDocument, options);
          }
export type AddCalendarMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddCalendarMutation, Operations.AddCalendarMutationVariables>;

/**
 * __useGetAllCalendarsQuery__
 *
 * To run a query within a Vue component, call `useGetAllCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCalendarsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllCalendarsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllCalendarsQuery(variables: Operations.GetAllCalendarsQueryVariables | VueCompositionApi.Ref<Operations.GetAllCalendarsQueryVariables> | ReactiveFunction<Operations.GetAllCalendarsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarsQuery, Operations.GetAllCalendarsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarsQuery, Operations.GetAllCalendarsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarsQuery, Operations.GetAllCalendarsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllCalendarsQuery, Operations.GetAllCalendarsQueryVariables>(Operations.GetAllCalendarsDocument, variables, options);
          }
export type GetAllCalendarsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllCalendarsQuery, Operations.GetAllCalendarsQueryVariables>;

/**
 * __useGetActiveCalendarQuery__
 *
 * To run a query within a Vue component, call `useGetActiveCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCalendarQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetActiveCalendarQuery(
 *   {
 *      args: // value for 'args'
 *   }
 * );
 */
export function useGetActiveCalendarQuery(variables: Operations.GetActiveCalendarQueryVariables | VueCompositionApi.Ref<Operations.GetActiveCalendarQueryVariables> | ReactiveFunction<Operations.GetActiveCalendarQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetActiveCalendarQuery, Operations.GetActiveCalendarQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetActiveCalendarQuery, Operations.GetActiveCalendarQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetActiveCalendarQuery, Operations.GetActiveCalendarQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetActiveCalendarQuery, Operations.GetActiveCalendarQueryVariables>(Operations.GetActiveCalendarDocument, variables, options);
          }
export type GetActiveCalendarQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetActiveCalendarQuery, Operations.GetActiveCalendarQueryVariables>;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a Vue component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCalendarQuery(
 *   {
 *      calendarId: // value for 'calendarId'
 *   }
 * );
 */
export function useGetCalendarQuery(variables: Operations.GetCalendarQueryVariables | VueCompositionApi.Ref<Operations.GetCalendarQueryVariables> | ReactiveFunction<Operations.GetCalendarQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetCalendarQuery, Operations.GetCalendarQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetCalendarQuery, Operations.GetCalendarQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetCalendarQuery, Operations.GetCalendarQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetCalendarQuery, Operations.GetCalendarQueryVariables>(Operations.GetCalendarDocument, variables, options);
          }
export type GetCalendarQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetCalendarQuery, Operations.GetCalendarQueryVariables>;

/**
 * __useSetCalendarToDisabledMutation__
 *
 * To run a mutation, you first call `useSetCalendarToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCalendarToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCalendarToDisabledMutation({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *   },
 * });
 */
export function useSetCalendarToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetCalendarToDisabledMutation, Operations.SetCalendarToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetCalendarToDisabledMutation, Operations.SetCalendarToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetCalendarToDisabledMutation, Operations.SetCalendarToDisabledMutationVariables>(Operations.SetCalendarToDisabledDocument, options);
          }
export type SetCalendarToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetCalendarToDisabledMutation, Operations.SetCalendarToDisabledMutationVariables>;

/**
 * __useUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCalendarMutation({
 *   variables: {
 *      calendar: // value for 'calendar'
 *      deleteCalEvents: // value for 'deleteCalEvents'
 *   },
 * });
 */
export function useUpdateCalendarMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateCalendarMutation, Operations.UpdateCalendarMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateCalendarMutation, Operations.UpdateCalendarMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateCalendarMutation, Operations.UpdateCalendarMutationVariables>(Operations.UpdateCalendarDocument, options);
          }
export type UpdateCalendarMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateCalendarMutation, Operations.UpdateCalendarMutationVariables>;

/**
 * __useAddCalendarEventsMutation__
 *
 * To run a mutation, you first call `useAddCalendarEventsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCalendarEventsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCalendarEventsMutation({
 *   variables: {
 *      calendarEvent: // value for 'calendarEvent'
 *   },
 * });
 */
export function useAddCalendarEventsMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddCalendarEventsMutation, Operations.AddCalendarEventsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddCalendarEventsMutation, Operations.AddCalendarEventsMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddCalendarEventsMutation, Operations.AddCalendarEventsMutationVariables>(Operations.AddCalendarEventsDocument, options);
          }
export type AddCalendarEventsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddCalendarEventsMutation, Operations.AddCalendarEventsMutationVariables>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteCalendarEventMutation, Operations.DeleteCalendarEventMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteCalendarEventMutation, Operations.DeleteCalendarEventMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteCalendarEventMutation, Operations.DeleteCalendarEventMutationVariables>(Operations.DeleteCalendarEventDocument, options);
          }
export type DeleteCalendarEventMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteCalendarEventMutation, Operations.DeleteCalendarEventMutationVariables>;

/**
 * __useGetAllCalendarEventUdfsQuery__
 *
 * To run a query within a Vue component, call `useGetAllCalendarEventUdfsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCalendarEventUdfsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllCalendarEventUdfsQuery(
 *   {
 *   }
 * );
 */
export function useGetAllCalendarEventUdfsQuery(options: VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarEventUdfsQuery, Operations.GetAllCalendarEventUdfsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarEventUdfsQuery, Operations.GetAllCalendarEventUdfsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarEventUdfsQuery, Operations.GetAllCalendarEventUdfsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllCalendarEventUdfsQuery, undefined>(Operations.GetAllCalendarEventUdfsDocument, undefined, options);
          }
export type GetAllCalendarEventUdfsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllCalendarEventUdfsQuery, Operations.GetAllCalendarEventUdfsQueryVariables>;

/**
 * __useGetAllCalendarEventsQuery__
 *
 * To run a query within a Vue component, call `useGetAllCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCalendarEventsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllCalendarEventsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllCalendarEventsQuery(variables: Operations.GetAllCalendarEventsQueryVariables | VueCompositionApi.Ref<Operations.GetAllCalendarEventsQueryVariables> | ReactiveFunction<Operations.GetAllCalendarEventsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarEventsQuery, Operations.GetAllCalendarEventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarEventsQuery, Operations.GetAllCalendarEventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarEventsQuery, Operations.GetAllCalendarEventsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllCalendarEventsQuery, Operations.GetAllCalendarEventsQueryVariables>(Operations.GetAllCalendarEventsDocument, variables, options);
          }
export type GetAllCalendarEventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllCalendarEventsQuery, Operations.GetAllCalendarEventsQueryVariables>;

/**
 * __useGetCalendarEventQuery__
 *
 * To run a query within a Vue component, call `useGetCalendarEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarEventQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCalendarEventQuery(
 *   {
 *      calendarEventId: // value for 'calendarEventId'
 *   }
 * );
 */
export function useGetCalendarEventQuery(variables: Operations.GetCalendarEventQueryVariables | VueCompositionApi.Ref<Operations.GetCalendarEventQueryVariables> | ReactiveFunction<Operations.GetCalendarEventQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetCalendarEventQuery, Operations.GetCalendarEventQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetCalendarEventQuery, Operations.GetCalendarEventQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetCalendarEventQuery, Operations.GetCalendarEventQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetCalendarEventQuery, Operations.GetCalendarEventQueryVariables>(Operations.GetCalendarEventDocument, variables, options);
          }
export type GetCalendarEventQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetCalendarEventQuery, Operations.GetCalendarEventQueryVariables>;

/**
 * __useGetCalendarEventsForScheduleQuery__
 *
 * To run a query within a Vue component, call `useGetCalendarEventsForScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarEventsForScheduleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCalendarEventsForScheduleQuery(
 *   {
 *      calendarId: // value for 'calendarId'
 *   }
 * );
 */
export function useGetCalendarEventsForScheduleQuery(variables: Operations.GetCalendarEventsForScheduleQueryVariables | VueCompositionApi.Ref<Operations.GetCalendarEventsForScheduleQueryVariables> | ReactiveFunction<Operations.GetCalendarEventsForScheduleQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetCalendarEventsForScheduleQuery, Operations.GetCalendarEventsForScheduleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetCalendarEventsForScheduleQuery, Operations.GetCalendarEventsForScheduleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetCalendarEventsForScheduleQuery, Operations.GetCalendarEventsForScheduleQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetCalendarEventsForScheduleQuery, Operations.GetCalendarEventsForScheduleQueryVariables>(Operations.GetCalendarEventsForScheduleDocument, variables, options);
          }
export type GetCalendarEventsForScheduleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetCalendarEventsForScheduleQuery, Operations.GetCalendarEventsForScheduleQueryVariables>;

/**
 * __useUpdateCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarEventMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarEventMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCalendarEventMutation({
 *   variables: {
 *      calendarEventId: // value for 'calendarEventId'
 *      calendarEvent: // value for 'calendarEvent'
 *   },
 * });
 */
export function useUpdateCalendarEventMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateCalendarEventMutation, Operations.UpdateCalendarEventMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateCalendarEventMutation, Operations.UpdateCalendarEventMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateCalendarEventMutation, Operations.UpdateCalendarEventMutationVariables>(Operations.UpdateCalendarEventDocument, options);
          }
export type UpdateCalendarEventMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateCalendarEventMutation, Operations.UpdateCalendarEventMutationVariables>;

/**
 * __useAddCalendarLocationMutation__
 *
 * To run a mutation, you first call `useAddCalendarLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCalendarLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCalendarLocationMutation({
 *   variables: {
 *      calendarLocation: // value for 'calendarLocation'
 *   },
 * });
 */
export function useAddCalendarLocationMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddCalendarLocationMutation, Operations.AddCalendarLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddCalendarLocationMutation, Operations.AddCalendarLocationMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddCalendarLocationMutation, Operations.AddCalendarLocationMutationVariables>(Operations.AddCalendarLocationDocument, options);
          }
export type AddCalendarLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddCalendarLocationMutation, Operations.AddCalendarLocationMutationVariables>;

/**
 * __useGetAllCalendarLocationsQuery__
 *
 * To run a query within a Vue component, call `useGetAllCalendarLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCalendarLocationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllCalendarLocationsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllCalendarLocationsQuery(variables: Operations.GetAllCalendarLocationsQueryVariables | VueCompositionApi.Ref<Operations.GetAllCalendarLocationsQueryVariables> | ReactiveFunction<Operations.GetAllCalendarLocationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarLocationsQuery, Operations.GetAllCalendarLocationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarLocationsQuery, Operations.GetAllCalendarLocationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllCalendarLocationsQuery, Operations.GetAllCalendarLocationsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllCalendarLocationsQuery, Operations.GetAllCalendarLocationsQueryVariables>(Operations.GetAllCalendarLocationsDocument, variables, options);
          }
export type GetAllCalendarLocationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllCalendarLocationsQuery, Operations.GetAllCalendarLocationsQueryVariables>;

/**
 * __useGetCalendarLocationQuery__
 *
 * To run a query within a Vue component, call `useGetCalendarLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarLocationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCalendarLocationQuery(
 *   {
 *      calendarLocationId: // value for 'calendarLocationId'
 *   }
 * );
 */
export function useGetCalendarLocationQuery(variables: Operations.GetCalendarLocationQueryVariables | VueCompositionApi.Ref<Operations.GetCalendarLocationQueryVariables> | ReactiveFunction<Operations.GetCalendarLocationQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetCalendarLocationQuery, Operations.GetCalendarLocationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetCalendarLocationQuery, Operations.GetCalendarLocationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetCalendarLocationQuery, Operations.GetCalendarLocationQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetCalendarLocationQuery, Operations.GetCalendarLocationQueryVariables>(Operations.GetCalendarLocationDocument, variables, options);
          }
export type GetCalendarLocationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetCalendarLocationQuery, Operations.GetCalendarLocationQueryVariables>;

/**
 * __useGetCalendarsByLocationQuery__
 *
 * To run a query within a Vue component, call `useGetCalendarsByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarsByLocationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCalendarsByLocationQuery(
 *   {
 *      locationId: // value for 'locationId'
 *   }
 * );
 */
export function useGetCalendarsByLocationQuery(variables: Operations.GetCalendarsByLocationQueryVariables | VueCompositionApi.Ref<Operations.GetCalendarsByLocationQueryVariables> | ReactiveFunction<Operations.GetCalendarsByLocationQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetCalendarsByLocationQuery, Operations.GetCalendarsByLocationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetCalendarsByLocationQuery, Operations.GetCalendarsByLocationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetCalendarsByLocationQuery, Operations.GetCalendarsByLocationQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetCalendarsByLocationQuery, Operations.GetCalendarsByLocationQueryVariables>(Operations.GetCalendarsByLocationDocument, variables, options);
          }
export type GetCalendarsByLocationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetCalendarsByLocationQuery, Operations.GetCalendarsByLocationQueryVariables>;

/**
 * __useSetCalendarLocationToDisabledMutation__
 *
 * To run a mutation, you first call `useSetCalendarLocationToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCalendarLocationToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCalendarLocationToDisabledMutation({
 *   variables: {
 *      calendarLocationId: // value for 'calendarLocationId'
 *   },
 * });
 */
export function useSetCalendarLocationToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetCalendarLocationToDisabledMutation, Operations.SetCalendarLocationToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetCalendarLocationToDisabledMutation, Operations.SetCalendarLocationToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetCalendarLocationToDisabledMutation, Operations.SetCalendarLocationToDisabledMutationVariables>(Operations.SetCalendarLocationToDisabledDocument, options);
          }
export type SetCalendarLocationToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetCalendarLocationToDisabledMutation, Operations.SetCalendarLocationToDisabledMutationVariables>;

/**
 * __useUpdateCalendarLocationMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCalendarLocationMutation({
 *   variables: {
 *      calendarLocationId: // value for 'calendarLocationId'
 *      calendarLocation: // value for 'calendarLocation'
 *   },
 * });
 */
export function useUpdateCalendarLocationMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateCalendarLocationMutation, Operations.UpdateCalendarLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateCalendarLocationMutation, Operations.UpdateCalendarLocationMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateCalendarLocationMutation, Operations.UpdateCalendarLocationMutationVariables>(Operations.UpdateCalendarLocationDocument, options);
          }
export type UpdateCalendarLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateCalendarLocationMutation, Operations.UpdateCalendarLocationMutationVariables>;

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useAddEventMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddEventMutation, Operations.AddEventMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddEventMutation, Operations.AddEventMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddEventMutation, Operations.AddEventMutationVariables>(Operations.AddEventDocument, options);
          }
export type AddEventMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddEventMutation, Operations.AddEventMutationVariables>;

/**
 * __useGetAllEventsQuery__
 *
 * To run a query within a Vue component, call `useGetAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllEventsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllEventsQuery(variables: Operations.GetAllEventsQueryVariables | VueCompositionApi.Ref<Operations.GetAllEventsQueryVariables> | ReactiveFunction<Operations.GetAllEventsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllEventsQuery, Operations.GetAllEventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllEventsQuery, Operations.GetAllEventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllEventsQuery, Operations.GetAllEventsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllEventsQuery, Operations.GetAllEventsQueryVariables>(Operations.GetAllEventsDocument, variables, options);
          }
export type GetAllEventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllEventsQuery, Operations.GetAllEventsQueryVariables>;

/**
 * __useGetEventQuery__
 *
 * To run a query within a Vue component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEventQuery(
 *   {
 *      eventId: // value for 'eventId'
 *   }
 * );
 */
export function useGetEventQuery(variables: Operations.GetEventQueryVariables | VueCompositionApi.Ref<Operations.GetEventQueryVariables> | ReactiveFunction<Operations.GetEventQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetEventQuery, Operations.GetEventQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetEventQuery, Operations.GetEventQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetEventQuery, Operations.GetEventQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetEventQuery, Operations.GetEventQueryVariables>(Operations.GetEventDocument, variables, options);
          }
export type GetEventQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetEventQuery, Operations.GetEventQueryVariables>;

/**
 * __useSetEventToDisabledMutation__
 *
 * To run a mutation, you first call `useSetEventToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetEventToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetEventToDisabledMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSetEventToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetEventToDisabledMutation, Operations.SetEventToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetEventToDisabledMutation, Operations.SetEventToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetEventToDisabledMutation, Operations.SetEventToDisabledMutationVariables>(Operations.SetEventToDisabledDocument, options);
          }
export type SetEventToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetEventToDisabledMutation, Operations.SetEventToDisabledMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateEventMutation, Operations.UpdateEventMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateEventMutation, Operations.UpdateEventMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateEventMutation, Operations.UpdateEventMutationVariables>(Operations.UpdateEventDocument, options);
          }
export type UpdateEventMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateEventMutation, Operations.UpdateEventMutationVariables>;

/**
 * __useGetEventTypesWithSchemaQuery__
 *
 * To run a query within a Vue component, call `useGetEventTypesWithSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventTypesWithSchemaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEventTypesWithSchemaQuery(
 *   {
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetEventTypesWithSchemaQuery(variables?: Operations.GetEventTypesWithSchemaQueryVariables | VueCompositionApi.Ref<Operations.GetEventTypesWithSchemaQueryVariables> | ReactiveFunction<Operations.GetEventTypesWithSchemaQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetEventTypesWithSchemaQuery, Operations.GetEventTypesWithSchemaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetEventTypesWithSchemaQuery, Operations.GetEventTypesWithSchemaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetEventTypesWithSchemaQuery, Operations.GetEventTypesWithSchemaQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetEventTypesWithSchemaQuery, Operations.GetEventTypesWithSchemaQueryVariables>(Operations.GetEventTypesWithSchemaDocument, variables, options);
          }
export type GetEventTypesWithSchemaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetEventTypesWithSchemaQuery, Operations.GetEventTypesWithSchemaQueryVariables>;

/**
 * __useGetAllEventTypesQuery__
 *
 * To run a query within a Vue component, call `useGetAllEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllEventTypesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllEventTypesQuery(variables?: Operations.GetAllEventTypesQueryVariables | VueCompositionApi.Ref<Operations.GetAllEventTypesQueryVariables> | ReactiveFunction<Operations.GetAllEventTypesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllEventTypesQuery, Operations.GetAllEventTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllEventTypesQuery, Operations.GetAllEventTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllEventTypesQuery, Operations.GetAllEventTypesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllEventTypesQuery, Operations.GetAllEventTypesQueryVariables>(Operations.GetAllEventTypesDocument, variables, options);
          }
export type GetAllEventTypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllEventTypesQuery, Operations.GetAllEventTypesQueryVariables>;

/**
 * __useUpdateEventTypeUdfColumnsMutation__
 *
 * To run a mutation, you first call `useUpdateEventTypeUdfColumnsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTypeUdfColumnsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateEventTypeUdfColumnsMutation({
 *   variables: {
 *      eventTypeLinks: // value for 'eventTypeLinks'
 *   },
 * });
 */
export function useUpdateEventTypeUdfColumnsMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateEventTypeUdfColumnsMutation, Operations.UpdateEventTypeUdfColumnsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateEventTypeUdfColumnsMutation, Operations.UpdateEventTypeUdfColumnsMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateEventTypeUdfColumnsMutation, Operations.UpdateEventTypeUdfColumnsMutationVariables>(Operations.UpdateEventTypeUdfColumnsDocument, options);
          }
export type UpdateEventTypeUdfColumnsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateEventTypeUdfColumnsMutation, Operations.UpdateEventTypeUdfColumnsMutationVariables>;

/**
 * __useAddScheduleEventsMutation__
 *
 * To run a mutation, you first call `useAddScheduleEventsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddScheduleEventsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddScheduleEventsMutation({
 *   variables: {
 *      scheduleEvent: // value for 'scheduleEvent'
 *   },
 * });
 */
export function useAddScheduleEventsMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddScheduleEventsMutation, Operations.AddScheduleEventsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddScheduleEventsMutation, Operations.AddScheduleEventsMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddScheduleEventsMutation, Operations.AddScheduleEventsMutationVariables>(Operations.AddScheduleEventsDocument, options);
          }
export type AddScheduleEventsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddScheduleEventsMutation, Operations.AddScheduleEventsMutationVariables>;

/**
 * __useAddSchedulesMutation__
 *
 * To run a mutation, you first call `useAddSchedulesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddSchedulesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddSchedulesMutation({
 *   variables: {
 *      schedule: // value for 'schedule'
 *   },
 * });
 */
export function useAddSchedulesMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddSchedulesMutation, Operations.AddSchedulesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddSchedulesMutation, Operations.AddSchedulesMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddSchedulesMutation, Operations.AddSchedulesMutationVariables>(Operations.AddSchedulesDocument, options);
          }
export type AddSchedulesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddSchedulesMutation, Operations.AddSchedulesMutationVariables>;

/**
 * __useDeleteScheduleEventMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleEventMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleEventMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteScheduleEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleEventMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteScheduleEventMutation, Operations.DeleteScheduleEventMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteScheduleEventMutation, Operations.DeleteScheduleEventMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteScheduleEventMutation, Operations.DeleteScheduleEventMutationVariables>(Operations.DeleteScheduleEventDocument, options);
          }
export type DeleteScheduleEventMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteScheduleEventMutation, Operations.DeleteScheduleEventMutationVariables>;

/**
 * __useGetAllScheduleEventsQuery__
 *
 * To run a query within a Vue component, call `useGetAllScheduleEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllScheduleEventsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllScheduleEventsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllScheduleEventsQuery(variables: Operations.GetAllScheduleEventsQueryVariables | VueCompositionApi.Ref<Operations.GetAllScheduleEventsQueryVariables> | ReactiveFunction<Operations.GetAllScheduleEventsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllScheduleEventsQuery, Operations.GetAllScheduleEventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllScheduleEventsQuery, Operations.GetAllScheduleEventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllScheduleEventsQuery, Operations.GetAllScheduleEventsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllScheduleEventsQuery, Operations.GetAllScheduleEventsQueryVariables>(Operations.GetAllScheduleEventsDocument, variables, options);
          }
export type GetAllScheduleEventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllScheduleEventsQuery, Operations.GetAllScheduleEventsQueryVariables>;

/**
 * __useGetScheduleQuery__
 *
 * To run a query within a Vue component, call `useGetScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetScheduleQuery(
 *   {
 *      scheduleId: // value for 'scheduleId'
 *   }
 * );
 */
export function useGetScheduleQuery(variables: Operations.GetScheduleQueryVariables | VueCompositionApi.Ref<Operations.GetScheduleQueryVariables> | ReactiveFunction<Operations.GetScheduleQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetScheduleQuery, Operations.GetScheduleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetScheduleQuery, Operations.GetScheduleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetScheduleQuery, Operations.GetScheduleQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetScheduleQuery, Operations.GetScheduleQueryVariables>(Operations.GetScheduleDocument, variables, options);
          }
export type GetScheduleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetScheduleQuery, Operations.GetScheduleQueryVariables>;

/**
 * __useGetAllSchedulesQuery__
 *
 * To run a query within a Vue component, call `useGetAllSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSchedulesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllSchedulesQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllSchedulesQuery(variables: Operations.GetAllSchedulesQueryVariables | VueCompositionApi.Ref<Operations.GetAllSchedulesQueryVariables> | ReactiveFunction<Operations.GetAllSchedulesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllSchedulesQuery, Operations.GetAllSchedulesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllSchedulesQuery, Operations.GetAllSchedulesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllSchedulesQuery, Operations.GetAllSchedulesQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllSchedulesQuery, Operations.GetAllSchedulesQueryVariables>(Operations.GetAllSchedulesDocument, variables, options);
          }
export type GetAllSchedulesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllSchedulesQuery, Operations.GetAllSchedulesQueryVariables>;

/**
 * __useSetScheduleToDisabledMutation__
 *
 * To run a mutation, you first call `useSetScheduleToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetScheduleToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetScheduleToDisabledMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useSetScheduleToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetScheduleToDisabledMutation, Operations.SetScheduleToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetScheduleToDisabledMutation, Operations.SetScheduleToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetScheduleToDisabledMutation, Operations.SetScheduleToDisabledMutationVariables>(Operations.SetScheduleToDisabledDocument, options);
          }
export type SetScheduleToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetScheduleToDisabledMutation, Operations.SetScheduleToDisabledMutationVariables>;

/**
 * __useUpdateScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateScheduleMutation({
 *   variables: {
 *      schedule: // value for 'schedule'
 *      deleteScheduleEvents: // value for 'deleteScheduleEvents'
 *   },
 * });
 */
export function useUpdateScheduleMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateScheduleMutation, Operations.UpdateScheduleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateScheduleMutation, Operations.UpdateScheduleMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateScheduleMutation, Operations.UpdateScheduleMutationVariables>(Operations.UpdateScheduleDocument, options);
          }
export type UpdateScheduleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateScheduleMutation, Operations.UpdateScheduleMutationVariables>;

/**
 * __useUpdateScheduleEventMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleEventMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleEventMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateScheduleEventMutation({
 *   variables: {
 *      scheduleEventId: // value for 'scheduleEventId'
 *      scheduleEvent: // value for 'scheduleEvent'
 *   },
 * });
 */
export function useUpdateScheduleEventMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateScheduleEventMutation, Operations.UpdateScheduleEventMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateScheduleEventMutation, Operations.UpdateScheduleEventMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateScheduleEventMutation, Operations.UpdateScheduleEventMutationVariables>(Operations.UpdateScheduleEventDocument, options);
          }
export type UpdateScheduleEventMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateScheduleEventMutation, Operations.UpdateScheduleEventMutationVariables>;

/**
 * __useGetAllScheduleDaysQuery__
 *
 * To run a query within a Vue component, call `useGetAllScheduleDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllScheduleDaysQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllScheduleDaysQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllScheduleDaysQuery(variables: Operations.GetAllScheduleDaysQueryVariables | VueCompositionApi.Ref<Operations.GetAllScheduleDaysQueryVariables> | ReactiveFunction<Operations.GetAllScheduleDaysQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllScheduleDaysQuery, Operations.GetAllScheduleDaysQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllScheduleDaysQuery, Operations.GetAllScheduleDaysQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllScheduleDaysQuery, Operations.GetAllScheduleDaysQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllScheduleDaysQuery, Operations.GetAllScheduleDaysQueryVariables>(Operations.GetAllScheduleDaysDocument, variables, options);
          }
export type GetAllScheduleDaysQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllScheduleDaysQuery, Operations.GetAllScheduleDaysQueryVariables>;

/**
 * __useAddSnFormatsMutation__
 *
 * To run a mutation, you first call `useAddSnFormatsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddSnFormatsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddSnFormatsMutation({
 *   variables: {
 *      sn_format: // value for 'sn_format'
 *   },
 * });
 */
export function useAddSnFormatsMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddSnFormatsMutation, Operations.AddSnFormatsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddSnFormatsMutation, Operations.AddSnFormatsMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddSnFormatsMutation, Operations.AddSnFormatsMutationVariables>(Operations.AddSnFormatsDocument, options);
          }
export type AddSnFormatsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddSnFormatsMutation, Operations.AddSnFormatsMutationVariables>;

/**
 * __useDeleteSerialNumberFormatMutation__
 *
 * To run a mutation, you first call `useDeleteSerialNumberFormatMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSerialNumberFormatMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteSerialNumberFormatMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSerialNumberFormatMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteSerialNumberFormatMutation, Operations.DeleteSerialNumberFormatMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteSerialNumberFormatMutation, Operations.DeleteSerialNumberFormatMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteSerialNumberFormatMutation, Operations.DeleteSerialNumberFormatMutationVariables>(Operations.DeleteSerialNumberFormatDocument, options);
          }
export type DeleteSerialNumberFormatMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteSerialNumberFormatMutation, Operations.DeleteSerialNumberFormatMutationVariables>;

/**
 * __useGetAllSnFormatsQuery__
 *
 * To run a query within a Vue component, call `useGetAllSnFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSnFormatsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllSnFormatsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllSnFormatsQuery(variables: Operations.GetAllSnFormatsQueryVariables | VueCompositionApi.Ref<Operations.GetAllSnFormatsQueryVariables> | ReactiveFunction<Operations.GetAllSnFormatsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllSnFormatsQuery, Operations.GetAllSnFormatsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllSnFormatsQuery, Operations.GetAllSnFormatsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllSnFormatsQuery, Operations.GetAllSnFormatsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllSnFormatsQuery, Operations.GetAllSnFormatsQueryVariables>(Operations.GetAllSnFormatsDocument, variables, options);
          }
export type GetAllSnFormatsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllSnFormatsQuery, Operations.GetAllSnFormatsQueryVariables>;

/**
 * __useGetAllSnsQuery__
 *
 * To run a query within a Vue component, call `useGetAllSnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSnsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllSnsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useGetAllSnsQuery(variables: Operations.GetAllSnsQueryVariables | VueCompositionApi.Ref<Operations.GetAllSnsQueryVariables> | ReactiveFunction<Operations.GetAllSnsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllSnsQuery, Operations.GetAllSnsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllSnsQuery, Operations.GetAllSnsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllSnsQuery, Operations.GetAllSnsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllSnsQuery, Operations.GetAllSnsQueryVariables>(Operations.GetAllSnsDocument, variables, options);
          }
export type GetAllSnsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllSnsQuery, Operations.GetAllSnsQueryVariables>;

/**
 * __useGetSerialNumberFormatByPkQuery__
 *
 * To run a query within a Vue component, call `useGetSerialNumberFormatByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumberFormatByPkQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSerialNumberFormatByPkQuery(
 *   {
 *      context_id: // value for 'context_id'
 *      id: // value for 'id'
 *   }
 * );
 */
export function useGetSerialNumberFormatByPkQuery(variables: Operations.GetSerialNumberFormatByPkQueryVariables | VueCompositionApi.Ref<Operations.GetSerialNumberFormatByPkQueryVariables> | ReactiveFunction<Operations.GetSerialNumberFormatByPkQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetSerialNumberFormatByPkQuery, Operations.GetSerialNumberFormatByPkQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetSerialNumberFormatByPkQuery, Operations.GetSerialNumberFormatByPkQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetSerialNumberFormatByPkQuery, Operations.GetSerialNumberFormatByPkQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetSerialNumberFormatByPkQuery, Operations.GetSerialNumberFormatByPkQueryVariables>(Operations.GetSerialNumberFormatByPkDocument, variables, options);
          }
export type GetSerialNumberFormatByPkQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetSerialNumberFormatByPkQuery, Operations.GetSerialNumberFormatByPkQueryVariables>;

/**
 * __useUpdateSnFormatStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSnFormatStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnFormatStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSnFormatStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      snFormatStatus: // value for 'snFormatStatus'
 *   },
 * });
 */
export function useUpdateSnFormatStatusMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateSnFormatStatusMutation, Operations.UpdateSnFormatStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateSnFormatStatusMutation, Operations.UpdateSnFormatStatusMutationVariables>> = {}) {
            return VueApolloComposable.useMutation<Operations.UpdateSnFormatStatusMutation, Operations.UpdateSnFormatStatusMutationVariables>(Operations.UpdateSnFormatStatusDocument, options);
          }
export type UpdateSnFormatStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateSnFormatStatusMutation, Operations.UpdateSnFormatStatusMutationVariables>;

/**
 * __useUpdateSnFormatMutation__
 *
 * To run a mutation, you first call `useUpdateSnFormatMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnFormatMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSnFormatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      sn_format: // value for 'sn_format'
 *   },
 * });
 */
export function useUpdateSnFormatMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateSnFormatMutation, Operations.UpdateSnFormatMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateSnFormatMutation, Operations.UpdateSnFormatMutationVariables>> = {}) {
            return VueApolloComposable.useMutation<Operations.UpdateSnFormatMutation, Operations.UpdateSnFormatMutationVariables>(Operations.UpdateSnFormatDocument, options);
          }
export type UpdateSnFormatMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateSnFormatMutation, Operations.UpdateSnFormatMutationVariables>;

/**
 * __useGetUdfColumnsQuery__
 *
 * To run a query within a Vue component, call `useGetUdfColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUdfColumnsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUdfColumnsQuery(
 *   {
 *      filter: // value for 'filter'
 *   }
 * );
 */
export function useGetUdfColumnsQuery(variables?: Operations.GetUdfColumnsQueryVariables | VueCompositionApi.Ref<Operations.GetUdfColumnsQueryVariables> | ReactiveFunction<Operations.GetUdfColumnsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetUdfColumnsQuery, Operations.GetUdfColumnsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetUdfColumnsQuery, Operations.GetUdfColumnsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetUdfColumnsQuery, Operations.GetUdfColumnsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetUdfColumnsQuery, Operations.GetUdfColumnsQueryVariables>(Operations.GetUdfColumnsDocument, variables, options);
          }
export type GetUdfColumnsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetUdfColumnsQuery, Operations.GetUdfColumnsQueryVariables>;

/**
 * __useGetAllUomsQuery__
 *
 * To run a query within a Vue component, call `useGetAllUomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUomsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllUomsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useGetAllUomsQuery(variables: Operations.GetAllUomsQueryVariables | VueCompositionApi.Ref<Operations.GetAllUomsQueryVariables> | ReactiveFunction<Operations.GetAllUomsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllUomsQuery, Operations.GetAllUomsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllUomsQuery, Operations.GetAllUomsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllUomsQuery, Operations.GetAllUomsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllUomsQuery, Operations.GetAllUomsQueryVariables>(Operations.GetAllUomsDocument, variables, options);
          }
export type GetAllUomsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllUomsQuery, Operations.GetAllUomsQueryVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserMutation(options: VueApolloComposable.UseMutationOptions<Operations.AddUserMutation, Operations.AddUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.AddUserMutation, Operations.AddUserMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.AddUserMutation, Operations.AddUserMutationVariables>(Operations.AddUserDocument, options);
          }
export type AddUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.AddUserMutation, Operations.AddUserMutationVariables>;

/**
 * __useGetUserQuery__
 *
 * To run a query within a Vue component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserQuery(
 *   {
 *      queryFilter: // value for 'queryFilter'
 *   }
 * );
 */
export function useGetUserQuery(variables: Operations.GetUserQueryVariables | VueCompositionApi.Ref<Operations.GetUserQueryVariables> | ReactiveFunction<Operations.GetUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetUserQuery, Operations.GetUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetUserQuery, Operations.GetUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetUserQuery, Operations.GetUserQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetUserQuery, Operations.GetUserQueryVariables>(Operations.GetUserDocument, variables, options);
          }
export type GetUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetUserQuery, Operations.GetUserQueryVariables>;

/**
 * __useGetAllUserAclsQuery__
 *
 * To run a query within a Vue component, call `useGetAllUserAclsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserAclsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllUserAclsQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sortby: // value for 'sortby'
 *   }
 * );
 */
export function useGetAllUserAclsQuery(variables?: Operations.GetAllUserAclsQueryVariables | VueCompositionApi.Ref<Operations.GetAllUserAclsQueryVariables> | ReactiveFunction<Operations.GetAllUserAclsQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllUserAclsQuery, Operations.GetAllUserAclsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllUserAclsQuery, Operations.GetAllUserAclsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllUserAclsQuery, Operations.GetAllUserAclsQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllUserAclsQuery, Operations.GetAllUserAclsQueryVariables>(Operations.GetAllUserAclsDocument, variables, options);
          }
export type GetAllUserAclsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllUserAclsQuery, Operations.GetAllUserAclsQueryVariables>;

/**
 * __useSetUserToDisabledMutation__
 *
 * To run a mutation, you first call `useSetUserToDisabledMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetUserToDisabledMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetUserToDisabledMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetUserToDisabledMutation(options: VueApolloComposable.UseMutationOptions<Operations.SetUserToDisabledMutation, Operations.SetUserToDisabledMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SetUserToDisabledMutation, Operations.SetUserToDisabledMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SetUserToDisabledMutation, Operations.SetUserToDisabledMutationVariables>(Operations.SetUserToDisabledDocument, options);
          }
export type SetUserToDisabledMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SetUserToDisabledMutation, Operations.SetUserToDisabledMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserMutation({
 *   variables: {
 *      isAdmin: // value for 'isAdmin'
 *      userId: // value for 'userId'
 *      user: // value for 'user'
 *      insertUserContexts: // value for 'insertUserContexts'
 *      deleteUserContextsBoolExp: // value for 'deleteUserContextsBoolExp'
 *      insertUserRoles: // value for 'insertUserRoles'
 *      deleteUserRoleBoolExp: // value for 'deleteUserRoleBoolExp'
 *      deleteUserAccessTokenKeysBoolExp: // value for 'deleteUserAccessTokenKeysBoolExp'
 *   },
 * });
 */
export function useUpdateUserMutation(options: VueApolloComposable.UseMutationOptions<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>(Operations.UpdateUserDocument, options);
          }
export type UpdateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a Vue component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllUsersQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sortby: // value for 'sortby'
 *   }
 * );
 */
export function useGetAllUsersQuery(variables?: Operations.GetAllUsersQueryVariables | VueCompositionApi.Ref<Operations.GetAllUsersQueryVariables> | ReactiveFunction<Operations.GetAllUsersQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetAllUsersQuery, Operations.GetAllUsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetAllUsersQuery, Operations.GetAllUsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetAllUsersQuery, Operations.GetAllUsersQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetAllUsersQuery, Operations.GetAllUsersQueryVariables>(Operations.GetAllUsersDocument, variables, options);
          }
export type GetAllUsersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetAllUsersQuery, Operations.GetAllUsersQueryVariables>;

/**
 * __useSaveWorkOrderMutation__
 *
 * To run a mutation, you first call `useSaveWorkOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveWorkOrderMutation({
 *   variables: {
 *      workOrderInput: // value for 'workOrderInput'
 *      onConflict: // value for 'onConflict'
 *      insertWorkOrderLocations: // value for 'insertWorkOrderLocations'
 *      deleteWorkOrderLocationBoolExp: // value for 'deleteWorkOrderLocationBoolExp'
 *      insertWorkOrderMaterials: // value for 'insertWorkOrderMaterials'
 *      deleteWorkOrderMaterialBoolExp: // value for 'deleteWorkOrderMaterialBoolExp'
 *   },
 * });
 */
export function useSaveWorkOrderMutation(options: VueApolloComposable.UseMutationOptions<Operations.SaveWorkOrderMutation, Operations.SaveWorkOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.SaveWorkOrderMutation, Operations.SaveWorkOrderMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.SaveWorkOrderMutation, Operations.SaveWorkOrderMutationVariables>(Operations.SaveWorkOrderDocument, options);
          }
export type SaveWorkOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.SaveWorkOrderMutation, Operations.SaveWorkOrderMutationVariables>;

/**
 * __useGetWorkOrderQuery__
 *
 * To run a query within a Vue component, call `useGetWorkOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetWorkOrderQuery(
 *   {
 *      work_order_id: // value for 'work_order_id'
 *   }
 * );
 */
export function useGetWorkOrderQuery(variables: Operations.GetWorkOrderQueryVariables | VueCompositionApi.Ref<Operations.GetWorkOrderQueryVariables> | ReactiveFunction<Operations.GetWorkOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetWorkOrderQuery, Operations.GetWorkOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetWorkOrderQuery, Operations.GetWorkOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetWorkOrderQuery, Operations.GetWorkOrderQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetWorkOrderQuery, Operations.GetWorkOrderQueryVariables>(Operations.GetWorkOrderDocument, variables, options);
          }
export type GetWorkOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetWorkOrderQuery, Operations.GetWorkOrderQueryVariables>;

/**
 * __useGetWorkOrdersForListQuery__
 *
 * To run a query within a Vue component, call `useGetWorkOrdersForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersForListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetWorkOrdersForListQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *      selectedIds: // value for 'selectedIds'
 *   }
 * );
 */
export function useGetWorkOrdersForListQuery(variables: Operations.GetWorkOrdersForListQueryVariables | VueCompositionApi.Ref<Operations.GetWorkOrdersForListQueryVariables> | ReactiveFunction<Operations.GetWorkOrdersForListQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.GetWorkOrdersForListQuery, Operations.GetWorkOrdersForListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.GetWorkOrdersForListQuery, Operations.GetWorkOrdersForListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.GetWorkOrdersForListQuery, Operations.GetWorkOrdersForListQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.GetWorkOrdersForListQuery, Operations.GetWorkOrdersForListQueryVariables>(Operations.GetWorkOrdersForListDocument, variables, options);
          }
export type GetWorkOrdersForListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.GetWorkOrdersForListQuery, Operations.GetWorkOrdersForListQueryVariables>;

/**
 * __useDeleteWorkOrderMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteWorkOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkOrderMutation(options: VueApolloComposable.UseMutationOptions<Operations.DeleteWorkOrderMutation, Operations.DeleteWorkOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.DeleteWorkOrderMutation, Operations.DeleteWorkOrderMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.DeleteWorkOrderMutation, Operations.DeleteWorkOrderMutationVariables>(Operations.DeleteWorkOrderDocument, options);
          }
export type DeleteWorkOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.DeleteWorkOrderMutation, Operations.DeleteWorkOrderMutationVariables>;

/**
 * __useWorkOrderActivityQuery__
 *
 * To run a query within a Vue component, call `useWorkOrderActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderActivityQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWorkOrderActivityQuery(
 *   {
 *      workOrderId: // value for 'workOrderId'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useWorkOrderActivityQuery(variables: Operations.WorkOrderActivityQueryVariables | VueCompositionApi.Ref<Operations.WorkOrderActivityQueryVariables> | ReactiveFunction<Operations.WorkOrderActivityQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.WorkOrderActivityQuery, Operations.WorkOrderActivityQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.WorkOrderActivityQuery, Operations.WorkOrderActivityQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.WorkOrderActivityQuery, Operations.WorkOrderActivityQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.WorkOrderActivityQuery, Operations.WorkOrderActivityQueryVariables>(Operations.WorkOrderActivityDocument, variables, options);
          }
export type WorkOrderActivityQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.WorkOrderActivityQuery, Operations.WorkOrderActivityQueryVariables>;

/**
 * __useWorkOrderSetStatusMutation__
 *
 * To run a mutation, you first call `useWorkOrderSetStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderSetStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useWorkOrderSetStatusMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useWorkOrderSetStatusMutation(options: VueApolloComposable.UseMutationOptions<Operations.WorkOrderSetStatusMutation, Operations.WorkOrderSetStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Operations.WorkOrderSetStatusMutation, Operations.WorkOrderSetStatusMutationVariables>>) {
            return VueApolloComposable.useMutation<Operations.WorkOrderSetStatusMutation, Operations.WorkOrderSetStatusMutationVariables>(Operations.WorkOrderSetStatusDocument, options);
          }
export type WorkOrderSetStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Operations.WorkOrderSetStatusMutation, Operations.WorkOrderSetStatusMutationVariables>;

/**
 * __useWorkOrderMaterialListQuery__
 *
 * To run a query within a Vue component, call `useWorkOrderMaterialListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderMaterialListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWorkOrderMaterialListQuery(
 *   {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      colsToOrderBy: // value for 'colsToOrderBy'
 *   }
 * );
 */
export function useWorkOrderMaterialListQuery(variables: Operations.WorkOrderMaterialListQueryVariables | VueCompositionApi.Ref<Operations.WorkOrderMaterialListQueryVariables> | ReactiveFunction<Operations.WorkOrderMaterialListQueryVariables>, options: VueApolloComposable.UseQueryOptions<Operations.WorkOrderMaterialListQuery, Operations.WorkOrderMaterialListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Operations.WorkOrderMaterialListQuery, Operations.WorkOrderMaterialListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Operations.WorkOrderMaterialListQuery, Operations.WorkOrderMaterialListQueryVariables>> = {}) {
            return VueApolloComposable.useQuery<Operations.WorkOrderMaterialListQuery, Operations.WorkOrderMaterialListQueryVariables>(Operations.WorkOrderMaterialListDocument, variables, options);
          }
export type WorkOrderMaterialListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Operations.WorkOrderMaterialListQuery, Operations.WorkOrderMaterialListQueryVariables>;