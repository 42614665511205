import z from 'zod';
import { sparkplugAppletNodeCfgManualSchema, sparkplugAppletNodeCfgSchema } from '../sparkplug-node-cfg.zod';
import { inputCellAttrsSchema } from '../cell-types/input.zod';
import { varConditionSchema } from '../var-providers/conditional.zod';
import { processProviderLocationSchema, processProviderStateVarsSchema } from '../var-providers/process.zod';
import { baseMacroCfgSchema } from './base-macro.zod';

const processSparkplugNodeCfg = z.union([
    sparkplugAppletNodeCfgManualSchema,
    z.literal('default'),
]);

const baseDataCollectSchema = z.object({
    source: z.string(),
    pdeId: z.string(),
});

/** collect the value from an autogenerated input cell */
const localInputDataCollectSchema = baseDataCollectSchema.extend({
    source: z.literal('localInput'),
    cellCfg: inputCellAttrsSchema.pick({
        label: true,
        trim: true,
    }),
});

const sparkplugDataCollectionSchema = baseDataCollectSchema.extend({
    source: z.literal('sparkplug'),
    /** the metric that will source the value for this PRDE */
    varpCfg: z.object({
        metric: z.string(),
        nodeCfg: processSparkplugNodeCfg,
    }),
});

/** collect the value from a variable not managed by the macro */
const varDataCollectSchema = baseDataCollectSchema.extend({
    source: z.literal('var'),
    var: z.string(),
});

const dataCollectCfgSchema = z.discriminatedUnion('source', [
    localInputDataCollectSchema,
    sparkplugDataCollectionSchema,
    varDataCollectSchema,
]);

const baseParamWriteSchema = z.object({
    target: z.string(),
    pdeId: z.string(),
});

/** write the param value to a variable not managed by the macro */
const varParamWriteSchema = baseParamWriteSchema.extend({
    target: z.literal('var'),
    var: z.string(),
});

/** write the param to a sparkplug metric */
const sparkplugParamWriteSchema = baseParamWriteSchema.extend({
    target: z.literal('sparkplug'),
    varpCfg: z.object({
        metric: z.string(),
        nodeCfg: processSparkplugNodeCfg,
    }),
});

/** where to write spec params */
const paramWriteCfgSchema = z.discriminatedUnion('target', [
    varParamWriteSchema,
    sparkplugParamWriteSchema,
]);

const sparkplugCollectTriggerSchema = z.object({
    type: z.literal('sparkplug'),
    varpCfg: z.object({
        metric: z.string(),
        nodeCfg: processSparkplugNodeCfg,
    }),
});

const varCollectTriggerSchema = z.object({
    type: z.literal('var'),
    var: z.string(),
});

const buttonPushTriggerSchema = z.object({
    type: z.literal('btn'),
});

const conditionalTriggerSchema = z.object({
    type: z.literal('conditional'),
    conditional: varConditionSchema,
});

const eventTriggerSchema = z.object({
    eventId: z.string(),
    type: z.literal('event'),
});

const collectTriggerCfgSchema = z.discriminatedUnion('type', [
    eventTriggerSchema,
    sparkplugCollectTriggerSchema,
    varCollectTriggerSchema,
    buttonPushTriggerSchema,
    conditionalTriggerSchema,
]);

/**
 * config for a process macro
 */
export const processMacroCfgSchema = baseMacroCfgSchema.extend({
    type: z.literal('process'),
    attrs: z.object({
        processRevId: z.string(),
        matIdVar: z.string(),
        defaultSparkplugCfg: sparkplugAppletNodeCfgSchema,
        collectTrigger: collectTriggerCfgSchema,

        /** maps data collection PDEs to sources */
        dataCollection: z.array(dataCollectCfgSchema),

        /** maps spec params to what to do with them */
        params: z.array(paramWriteCfgSchema),

        stateVars: processProviderStateVarsSchema,

        processLocation: processProviderLocationSchema,

        showMedia: z.boolean().optional(),

    }),
});
