import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';
import { varConditionSchema } from '../var-providers/conditional.zod';

export const inputCellAttrsSchema = cellCfgAttrsBaseSchema.extend({
    /**
     * the local/custom variable name to write the scanner input to.
     */
    writeVar: z.string(),
    /** (only controlled by modifier) */
    errorMsg: z.string().optional(),
    /** (only controlled by modifier) */
    enabled: z.union([ z.number(), z.string() ]),
    captureFocus: z.union([
        z.boolean(),
        z.object({
            onlyIfEmpty: z.boolean().default(false),
        }),
    ]).default(false),
    label: z.string(),
    btnText: z.string(),
    trim: z.boolean(),
    clearCondition: varConditionSchema.nullable().optional(),
});

export const inputCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('input'),
    attrs: inputCellAttrsSchema,
    modifiers: z.record(z.enum([
        'errorMsg',
        'enabled',
        'label',
    ]), z.string().optional()).optional(),
});
