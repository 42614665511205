import { accessor } from 'src/store';
import type { UiFlowNode } from '../UiFlowNode';
import type {
    NodeCfg,
    NodeKind,
    NodeSocketKindMap,
} from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';
import type { AppletDesignVersionEntity } from '../../applet.entity';

/**
 * applies removing a socket from a node.
 *
 * any relevant connections are updated. and the node is rerendered by replacing its cfg object.
 *
 * This updates vuex, so update your node config before calling this.
 */
export async function removeNodeSocket<K extends NodeKind> (opts: {
    node: UiFlowNode<K>;
    newNodeCfg: NodeCfg<K>;
    direction: 'input' | 'output';
    /** Method of removing the socket
     * - uniqueIdentifier: remove by key
     * - indexedIdentifier: remove by index and prefix and readjust downstream indexes
     */
    removalMethod: {
        kind: 'uniqueIdentifier';
        value: keyof NodeSocketKindMap[K]['inputs'] | keyof NodeSocketKindMap[K]['outputs'];
    } | {
        index: number;
        prefix: string;
        kind: 'indexedIdentifier';
    };
}): Promise<void> {
    const {
        node,
        direction,
        newNodeCfg,
        removalMethod,
    } = opts;

    const entityData = accessor.entityAsType('appletDesignVersion');
    if (!entityData) {
        return;
    }
    const itemToRemove = removalMethod.kind === 'uniqueIdentifier' ? removalMethod.value : `${removalMethod.prefix}${removalMethod.index}`;

    if (typeof itemToRemove !== 'string') {
        throw new Error('socketToRemove isnt a string');
    }

    await accessor.appletDesign.flowModifiers?.deleteSpecificItem(direction === 'input'
        ? {
            direction,
            nodeId: node.id,
            kind: 'nodeSocket',
            inputKey: itemToRemove,
        }
        : {
            direction,
            nodeId: node.id,
            kind: 'nodeSocket',
            outputKey: itemToRemove,
        });
    const newFlow: AppletDesignVersionEntity = {
        ...entityData,
        config: {
            ...entityData.config,
            flows: {
                ...entityData.config.flows,
                [node.flowCfgRef.value.id]: {
                    ...node.flowCfgRef.value,
                    nodes: {
                        ...node.flowCfgRef.value.nodes,
                        [node.id]: {
                            ...newNodeCfg,
                        },
                    },
                },
            },
        },
    };

    accessor.setPageEntity({
        entity: newFlow,
        type: 'appletDesignVersion',
    });

    /**
     * creating a "callback" that gets called inside of `useFlowEditor`
     * the callback fires off every time the flow config changes via someting outside of the flow editor (i.e. the call to `setPageEntity` above)
     * this essentially is the same thing as putting a `.then` on the `setPageEntity` call above but that isn't possible because `setPageEntity` is a vuex action
     *
     * The content of the callback sets properties of the rete editor that rely on the changes from `setPageEntity` to be set which is why it needs to be done in this complex, async fashion
    */
    accessor.appletDesign.flowModifiers?.flowUpdateEvent.onSet(async () => {
        if (removalMethod.kind === 'indexedIdentifier') {
            if (direction === 'input') {
                const socketsToAdjust = Object.keys(node.inputs).filter(key => key.startsWith(removalMethod.prefix) && parseInt(key.replace(removalMethod.prefix, ''), 10) > removalMethod.index);
                for (const inputKey of socketsToAdjust) {
                    await accessor.appletDesign.flowModifiers?.updateSocketPort({
                        direction,
                        nodeId: node.id,
                        oldInputKey: inputKey,
                        newInputKey: `${removalMethod.prefix}${parseInt(inputKey.replace(removalMethod.prefix, ''), 10) - 1}`,
                    });
                }
            } else {
                const socketsToAdjust = Object.keys(node.outputs).filter(key => key.startsWith(removalMethod.prefix) && parseInt(key.replace(removalMethod.prefix, ''), 10) > removalMethod.index);
                for (const outputKey of socketsToAdjust) {
                    await accessor.appletDesign.flowModifiers?.updateSocketPort({
                        direction,
                        nodeId: node.id,
                        oldOutputKey: outputKey,
                        newOutputKey: `${removalMethod.prefix}${parseInt(outputKey.replace(removalMethod.prefix, ''), 10) - 1}`,
                    });
                }
            }
        }
    });
}
