import z from 'zod';
import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';
import { varConditionSchema } from '../var-providers/conditional.zod';

export const circularProgressCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('circularProgress'),
    attrs: cellCfgAttrsBaseSchema.extend({
        minValue: z.number(),
        maxValue: z.number(),
        innerText: z.string(),
        dialColor: z.string(),
        sourceVar: z.string(),
        flashingCondition: varConditionSchema.nullable().optional(),
    }),
    modifiers: z.record(z.enum([
        'minValue',
        'maxValue',
        'innerText',
        'dialColor',
    ]), z.string().optional()).optional(),
});
