import { ClassicPreset } from 'rete';
import type { UiFlowNodeControl } from '../controls/UiFlowNodeControl';

export class UiFlowNodeInput<S extends ClassicPreset.Socket, VALUE = unknown> extends ClassicPreset.Input<S> {
    control: UiFlowNodeControl<VALUE> | null;

    constructor (opts: {
        socket: S,
        label?: string,
        multipleConnections?: boolean,
        control?: UiFlowNodeControl<VALUE>,
        /** for sorting */
        index?: number,
    }) {
        super(opts.socket, opts.label, opts.multipleConnections ?? true);
        this.control = opts.control || null;
        this.index = opts.index;
    }
}
