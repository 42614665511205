import { z } from 'zod';
import { appletDesignVersionConfigSchema_v3 } from './v3/appletDesign.v3.zod';
import { boolVarSchema, errorVarSchema, numVarSchema, okVarSchema, okVarShemaWithContent, okVarValSchema, pendingVarSchema, strVarSchema, varSchema } from './v3/var-providers/var.zod';
import { varpTypes } from './v3/var-providers.zod';
import { baseComparisonSchema, baseOperandSchema, varComparisonOperandSchema, varConditionSchema } from './v3/var-providers/conditional.zod';
import { cellCfgBaseSchema } from './v3/cell-types/cell-base.zod';
import { VarOrConst as VarOrConst_V3, varpConfigBase } from './v3/var-providers/var-provider.zod';
import {
    appletEventDefSchema,
    appletEventRefSchema,
} from './v3/event.zod';
import type { sparkplugAppletNodeCfgDeployParamSchema, sparkplugAppletNodeCfgManualSchema } from './v3/sparkplug-node-cfg.zod';

// Applet design config imports based off of the lastest version
export const appletDesignVersionConfigSchemaLatest = appletDesignVersionConfigSchema_v3;

export const latestAppletDesignVersionNumber: Latest.AppletDesignVersionConfig['schemaVersion'] = 3;

// These are types used elsewhere in the codebase that could not be derived from the applet design config
// NOTE: we should attempt to find a way to eliminate these types and use the types from the applet design config
// The less schemas defined here the less schemas we have to maintain/ bring forward every time we create a new schema version
export const latestVarpTypes = varpTypes;
export const latestVarConditionSchema = varConditionSchema;
const latestBaseOperandSchema = baseOperandSchema;
const latestBaseComparisonSchema = baseComparisonSchema;
const latestVarComparisonOperandSchema = varComparisonOperandSchema;
const latestCellCfgBaseSchema = cellCfgBaseSchema;
const lastetVarpCfgBase = varpConfigBase;
const latestVarSchema = varSchema;
export const latestOkVarShemaWithContent = okVarShemaWithContent;
export const latestOkVarSchema = okVarSchema;
export const lastetErrorVarSchema = errorVarSchema;
export const latestPendingVarSchema = pendingVarSchema;
export const latestOkVarValSchema = okVarValSchema;
export const latestStrVarSchema = strVarSchema;
export const latestNumVarSchema = numVarSchema;
export const latestBoolVarSchema = boolVarSchema;

// This is a reference map that can be used to determine which style a tab should use in the event that two of the conditions are true for the same tab
export const displayWeightRefMap: {
    [key in Latest.VarProviders.TabGroupController.TabDisplayComparisonType]: number;
} = {
    success: 1,
    info: 2,
    warning: 3,
    error: 4,
};

export namespace Latest {

    export interface AppletDesignVersionConfig extends z.infer<typeof appletDesignVersionConfigSchemaLatest> {}

    export namespace Screen {

        export type Config = AppletDesignVersionConfig['screens'][number];

        export namespace Designer {

            export type GridLayoutAspectRatios = Config['gridLayoutAspectRatios'];

            export type GridLayoutAspectRatio = keyof GridLayoutAspectRatios;
            export type GridLayout = GridLayoutAspectRatios[GridLayoutAspectRatio];
            export type RootGridLayoutCell = GridLayout['gridConfig'];

            export type LinkedGridCell = GridLayout['linkedGridCells'][number];
            export type LinkedGridCellCoordinate = LinkedGridCell['coordinates'][number];
            export type ScreenRow = (LinkedGridCell & { type: 'subGrid'})['rows'][number];
            export type ScreenColumn = (LinkedGridCell & { type: 'subGrid'})['columns'][number];
            export type Coordinate = (LinkedGridCell & { type: 'subGrid'})['coordinates'];

            export type InfoBoxCell = LinkedGridCell & { type: 'infoBox' };
            export type SubGridCell = LinkedGridCell & { type: 'subGrid' };
            export type TabGroupCell = LinkedGridCell & { type: 'tabGroup' };
            export type RegularGridCell = LinkedGridCell & { type: 'regular' };
            export type TabInstanceCell = LinkedGridCell & { type: 'tabInstance' };

        }

        export namespace Cells {

            export type AppletCell = Config['cells'][number];

            export type AppletCellBase = z.infer<typeof latestCellCfgBaseSchema>;

            export type AppletCellType = AppletCell['type'];

            export namespace Btn {
                export type Cell = AppletCell & { type: 'btn' };
                export type VariableBehaviorType = Exclude<Cell['attrs']['varBehavior'], undefined>['type']
            }
            export type TextCell = AppletCell & { type: 'text' };
            export type TimerCell = AppletCell & { type: 'timer' };
            export namespace Media {
                export type Cell = AppletCell & { type: 'media' };
                export type AppletMediaItem = Cell['attrs']['items'][number]['media'];
            }
            export type InputCell = AppletCell & { type: 'input' };
            export type DialogCell = AppletCell & { type: 'dialog' };
            export type ProcessCell = AppletCell & { type: 'process' };
            export type DateTimeCell = AppletCell & { type: 'datetime' };
            export namespace AttrLookup {
                export type Cell = AppletCell & { type: 'attrLookup' };
                export type Condition = Cell['attrs']['conds'][number];
                export type PropMatchNum = Condition & { type: 'propMatchNum' };
            }
            export type MacroTargetCell = AppletCell & { type: 'macroTarget' };
            export type ProcessMediaCell = AppletCell & { type: 'processMedia' };
            export namespace MaterialLookup {
                export type Cell = AppletCell & { type: 'materialLookup' };
                export type FilterComparison = Cell['attrs']['filterConditions'][number]['comparisons'][number];
            }
            export type CircularProgressCell = AppletCell & { type: 'circularProgress' };
        }
    }

    export namespace Macros {
        export type AppletMacro = AppletDesignVersionConfig['macros'][number];

        export type AppletMacroType = AppletMacro['type'];

        export type MaterialLookup = AppletMacro & { type: 'materialLookup' };
        export namespace Process {
            export type Macro = AppletMacro & { type: 'process' };
            export type CollectTriggerCfg = Macro['attrs']['collectTrigger'];
            export type DataCollection = Macro['attrs']['dataCollection'][number];
            export type LocalInputDataCollect = DataCollection & { source: 'localInput'};
            export type SparkplugNodeCfgType = (DataCollection & { source: 'sparkplug'})['varpCfg']['nodeCfg'];
        }
    }

    export namespace VarProviders {

        export type VarProvider = AppletDesignVersionConfig['varProviders'][number];

        export type VarProviderBase = z.infer<typeof lastetVarpCfgBase>;

        export type VarProviderType = VarProvider['type'];

        export type FormattedProvider = VarProvider & { type: 'formatted' };
        export type LockingProvider = VarProvider & { type: 'locking' };
        export type SliceProvider = VarProvider & { type: 'slice' };
        export namespace Local {
            export type Provider = VarProvider & { type: 'local' };
            export type VarItem = Provider['attrs']['vars'][number];
            export type VarCfgDeployParam = VarItem & { type: 'deployParam' };
        }
        export namespace Comparative {
            export type Provider = VarProvider & { type: 'comparative' };
            export type Assignment = Provider['attrs']['assignments'][number];
            export type Comparison = Assignment['comparisons'][number];
            export type EqComparison = Comparison & { type: 'eq' };
            export type TypeOperand = Comparison['operands'][number] & { type: 'type' };
        }
        export namespace MaterialId {
            export type Provider = VarProvider & { type: 'materialId' };
            export type UdfSource = Provider['attrs']['udfOutputs'][number]['sources'][number];
        }
        export type MqttPublishProvider = VarProvider & { type: 'mqttPublish' };
        export type MqttSubscribeProvider = VarProvider & { type: 'mqttSubscribe' };
        export type VarStatusProvider = VarProvider & { type: 'varStatus' };
        export type ProcessProvider = VarProvider & { type: 'process' };
        export type MacroVarpTarget = VarProvider & { type: 'macroTarget' };
        export namespace Sparkplug {
            export type Provider = VarProvider & { type: 'sparkplug' };
            export type AppletNodeCfg = Provider['attrs']['nodeCfg'];
            export type AppletNodeCfgDeployParam = AppletNodeCfg & { type: 'deployParam' };
        }
        export type SparkplugDeviceWriteProvider = VarProvider & { type: 'sparkplugDeviceWrite' };
        export namespace CreateMaterial {
            export type Provider = VarProvider & { type: 'createMaterial' };
            export type MaterialModel = Provider['attrs']['materialModels'][number];
            export type SnCfgFormatted = MaterialModel['snCfg'] & { type: 'formatter' };
            export type UdfValues = MaterialModel['udfs'];
        }
        export type TimerProvider = VarProvider & { type: 'timer' };
        export namespace Calendar {
            export type Provider = VarProvider & { type: 'calendar' };
            export type UdfEvent = Provider['attrs']['udfCalendarEvents'][number];
            export type EventOccurance = UdfEvent['occurance'];
        }
        export namespace Script {
            export type Provider = VarProvider & { type: 'script' };
            export type DefaultConfig = Provider['attrs']['executeCfg'] & { type: 'default' };
        }
        export type UdpSendProvider = VarProvider & { type: 'udpSend' };
        export type UdpReceiveProvider = VarProvider & { type: 'udpReceive' };
        export type TcpClientProvider = VarProvider & { type: 'tcpClient' };
        export type TcpServerProvider = VarProvider & { type: 'tcpServer' };
        export type UdpSocketProvider = VarProvider & { type: 'udpSocket' };

        export namespace TabGroupController {
            export type Provider = VarProvider & { type: 'tabGroupController' };
            export type ValidationConfig = Provider['attrs']['validationConfig'];
            export type ValidationType = ValidationConfig['validationType'];
            export type TabConfig = ValidationConfig['tabConfigs'][number];
            export type TabLabelConfig = (ValidationConfig & { validationType: 'tabLabel' })['tabConfigs'][number];
            export type TabIndexConfig = (ValidationConfig & { validationType: 'tabIndex' })['tabConfigs'][number];
            export type TabComparison = TabConfig['activate']; // Using activate as a reference for comparisons because all comparisons (activate, deactivate, and display types) all use the same comparison
            export type TabDisplayComparisonType = keyof TabConfig['display'];

            export type PayloadVarValue = {
                active: number | string;
                tabStates: {
                    isDeactivated: boolean;
                    tabIdentifier: number | string;
                    display: TabDisplayComparisonType | 'default'; // Adding default here because it is not configurable, but the payload should still output it just to maintain state
                }[];
            };
        }
    }

    export namespace AppletEvent {
        export interface Ref extends z.infer<typeof appletEventRefSchema> {}
        export interface Def extends z.infer<typeof appletEventDefSchema> {}
    }

    // Conditional types
    export type BaseOperand = z.infer<typeof latestBaseOperandSchema>;
    export type BaseComparison = z.infer<typeof latestBaseComparisonSchema>;
    export type BaseComparison2 = Pick<VarProviders.Comparative.Provider['attrs']['assignments'][number]['comparisons'][number], 'not' | 'type' | 'operands'>;
    export type BaseOperand2 = Pick<BaseComparison2['operands'][number], 'type'>;

    export type VarComparisonOperand = z.infer<typeof latestVarComparisonOperandSchema>;
    export type VarCondition = z.infer<typeof latestVarConditionSchema>;
    export type VarComparison = VarCondition['comparisons'][number];
    export type VarOperand = VarComparison['operands'][number];

    // Variable types
    export type AppletVar = z.infer<typeof latestVarSchema>;
    export type VarOrConst<T extends Latest.OkAppletVar = Latest.OkAppletVar> = VarOrConst_V3<T>;
    export type AppletVarStatus = AppletVar['status'];
    export type AppletVarValue = AppletVar['val'];
    export type OkAppletVarWithContent = z.infer<typeof latestOkVarShemaWithContent>;
    export type OkAppletVar = AppletVar & { status: 'ok' };
    export type OkAppletVarType = OkAppletVar['type'];
    export type OkAppletVarVal = OkAppletVar['val'];
    export type StrAppletVar = OkAppletVar & { type: 'str' };
    export type NumAppletVar = OkAppletVar & { type: 'num' };
    export type ErrorAppletVar = AppletVar & { type: 'error' };
    export type NullAppletVar = AppletVar & { type: 'null' };
    export type PendingAppletVar = AppletVar & { type: 'pending' };

    // Sparkplug types
    export type SparkplugDeviceMetric = AppletDesignVersionConfig['sparkplugDeviceMetrics'][number];
    export type SparkplugDeviceMetricPermissions = SparkplugDeviceMetric['permissions'];
    export type SparkplugAppletNodeCfgManual = z.infer<typeof sparkplugAppletNodeCfgManualSchema>;
    export type SparkplugAppletNodeCfgDeployParam = z.infer<typeof sparkplugAppletNodeCfgDeployParamSchema>;
    // Script types
    export type RuntimeScriptCfg = AppletDesignVersionConfig['scripts'][number];

    export type AllowedModifiers<C extends string> = {
        attr: C;
        displayName: string;
        allowedVarTypes?: string[];
    }[];
}
