import { z } from 'zod';
import { ArgoUdfColumnDataTypeEnum } from '../../../../../db';

export const calendarSubcribedColumnsSchema = z.object({
    shiftNextMetaName: z.boolean(),
    shiftCurrMetaName: z.boolean(),
    // TODO: add this back in after the work order branch gets merged in. That branch sets up the boilerplate for the `any` variable value type this this data would need to tap into
    // rawCalenderUdfData: z.boolean(),
    nonProdCurrMetaName: z.boolean(),
    nonProdNextMetaName: z.boolean(),
    shiftNextMetaTimeEnd: z.boolean(),
    shiftCurrMetaTimeEnd: z.boolean(),
    shiftNextMetaTimeStart: z.boolean(),
    shiftCurrMetaTimeStart: z.boolean(),
    nonProdCurrMetaTimeEnd: z.boolean(),
    nonProdNextMetaTimeEnd: z.boolean(),
    nonProdNextMetaTimeStart: z.boolean(),
    nonProdCurrMetaTimeStart: z.boolean(),
    shiftNextTimeSecondsUntil: z.boolean(),
    shiftCurrTimeElapsedSeconds: z.boolean(),
    nonProdNextTimeSecondsUntil: z.boolean(),
    shiftCurrTimeRemainingSeconds: z.boolean(),
    nonProdCurrTimeElapsedSeconds: z.boolean(),
    shiftCurrTimeElapsedProductive: z.boolean(),
    nonProdCurrTimeRemainingSeconds: z.boolean(),
    shiftCurrTimeRemainingProductive: z.boolean(),
});

export type CalendarSubcribedColumns = z.infer<typeof calendarSubcribedColumnsSchema>;

export const calendarColumnLabels = {
    shiftNextMetaName: 'Next Shift Name',
    shiftCurrMetaName: 'Current Shift Name',
    // rawCalenderUdfData: 'Raw Calendar UDF Data',
    shiftNextMetaTimeEnd: 'Next Shift End Time',
    shiftCurrMetaTimeEnd: 'Current Shift End Time',
    shiftNextMetaTimeStart: 'Next Shift Start Time',
    nonProdNextMetaName: 'Next Non-Productive Name',
    nonProdCurrMetaName: 'Current Non-Productive Name',
    shiftCurrMetaTimeStart: 'Current Shift Start Time',
    nonProdNextMetaTimeEnd: 'Next Non-Productive End Time',
    nonProdCurrMetaTimeEnd: 'Current Non-Productive End Time',
    nonProdNextMetaTimeStart: 'Next Non-Productive Start Time',
    shiftNextTimeSecondsUntil: 'Next Shift Seconds Until Start',
    nonProdCurrMetaTimeStart: 'Current Non-Productive Start Time',
    shiftCurrTimeElapsedSeconds: 'Current Shift Seconds Since Start',
    shiftCurrTimeRemainingSeconds: 'Current Shift Seconds Remaining',
    nonProdNextTimeSecondsUntil: 'Next Non-Productive Seconds Until Start',
    nonProdCurrTimeRemainingSeconds: 'Current Non-Productive Seconds Remaining',
    nonProdCurrTimeElapsedSeconds: 'Current Non-Productive Seconds Since Start',
    shiftCurrTimeElapsedProductive: 'Current Shift Productive Seconds Since Start',
    shiftCurrTimeRemainingProductive: 'Current Shift Productive Seconds Remaining',
} satisfies {
    [key in keyof CalendarSubcribedColumns]: string;
};

export const calendarColumnSelectionMap = Object.entries(calendarColumnLabels).map(([ key, value ]) => ({
    value: key,
    label: value,
}));

export const calendarUdfColumnSchema = z.object({
    udfName: z.string(),
    isProd: z.boolean(),
    eventName: z.string(),
    udfColumnId: z.string(),
    dataType: z.nativeEnum(ArgoUdfColumnDataTypeEnum),
    occurance: z.union([ z.literal('next'), z.literal('current') ]),
});

export type CalendarUdfColumn = z.infer<typeof calendarUdfColumnSchema>;
