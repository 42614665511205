import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';

export const textCellAttrsSchema = cellCfgAttrsBaseSchema.extend({
    content: z.string(),
    /** supports css color values (`#RGB`, `#RGBA`, `rgb()`, `rgba()`) or vuetify text color classes */
    textColor: z.string().optional(),
    /**
     * ify REM units for font size
     */
    fontSize: z.string().default('1.5').optional(),
    autofit: z.boolean().default(false).optional(),
    wordWrap: z.boolean().default(false).optional(),
    /**
     * possible values determined from the Roboto font
     * @default '400'
     */
    fontWeight: z.enum([ '200', '400', '700', '900' ]).default('400').optional(),
    halign: z.enum([ 'flex-start', 'center', 'flex-end' ]).default('center').optional(),
    valign: z.enum([ 'flex-start', 'center', 'flex-end' ]).default('center').optional(),
});

export const textCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('text'),
    attrs: textCellAttrsSchema,
    modifiers: z.record(z.enum([
        'content',
        'textColor',
        'fontSize',
        'fontWeight',
    ]), z.string().optional()).optional(),
});
