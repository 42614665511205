import {
    AllEnabledUserAclsDocument,
    AllEnabledUserAclsQuery,
    GetUserDocument,
    GetUserQuery,
} from '../../types/db';
import {
    AuthStatus,
    GetRefreshTokenResult,
} from './types';
import { getRefreshToken } from './api/auth';
import { actionTree, getterTree, mutationTree } from 'typed-vuex';
import { DebugModePlugin } from '../vue-plugins/debug-mode';
import {
    gqlClient,
    setClientAuth,
} from '../util/gql-client';
import type { ServiceWorkerMessage } from '@redviking/argonaut-util/src/service-worker-message.zod';

let refreshTimeout: number | null;
let resolveAuthInitialized: (value: boolean) => void | null;
/**
 * promise will resolve when auth has finished attempting to load user acls and set gql endpoint
 */
export const authInitialized = new Promise<boolean>(resolve => {
    resolveAuthInitialized = resolve;
});

const authState = {
    authStatus: AuthStatus.Pending as AuthStatus,
    userId: '',
    contextId: '',
    gqlEndpoint: '',
    /** the hasura jwt */
    gqlToken: '',
    gqlExpiresInSeconds: 0,
    currentUser: null as GetUserQuery['user'][number] | null,
    acls: null as AllEnabledUserAclsQuery | null,
};

const getters = getterTree(authState, {
    wsGqlEndpoint: state => {
        if (!state.gqlEndpoint) {
            return '';
        }
        const wsGqlEndpointUrl = new URL(state.gqlEndpoint);
        wsGqlEndpointUrl.protocol = wsGqlEndpointUrl.protocol === 'https:' ? 'wss:' : 'ws:';
        return wsGqlEndpointUrl.href;
    },
    currentContext: state => {
        const currentCtxId = state.contextId;
        if (!currentCtxId) {
            return null;
        }
        const currentUserCtx = (state.currentUser?.user_contexts || []).find(userCtx => userCtx.context_id === currentCtxId);
        return currentUserCtx?.context || null;
    },
});

const mutations = mutationTree(authState, {
    setAuthStatusSuccess (state, getRefreshTokenResult: GetRefreshTokenResult) {
        // ensure the gqlEndpoint is a complete absolute url with hostname and protocol.
        const tokens = getRefreshTokenResult.tokens || [];

        if (!getRefreshTokenResult.userId) {
            throw new Error('Invalid userId');
        }

        if (!getRefreshTokenResult.contextId) {
            throw new Error('Invalid contextId');
        }

        if (!Array.isArray(tokens)) {
            throw new Error('Invalid tokens!');
        }

        const graphql = tokens.find(i => i.id === 'graphql')!;

        let gqlEndpoint = graphql.endpoint;
        if (!gqlEndpoint.match('://')) { // not full url
            if (gqlEndpoint.match(/^\//ui)) { // /absolute/path/without/hostname
                gqlEndpoint = new URL(gqlEndpoint, window.location.href).href; // adds protocol and hostname
            } else { // argonaut.com/absolute/path/with/hostname
                // assuming the url is not relative, the hostname should be in front
                // we just need to add the protocol
                gqlEndpoint = `${window.location.protocol}/${gqlEndpoint}`;
            }
        }

        state.authStatus = AuthStatus.Success;
        state.userId = getRefreshTokenResult.userId;
        state.contextId = getRefreshTokenResult.contextId;
        state.gqlEndpoint = gqlEndpoint;
        state.gqlToken = graphql.token;
        state.gqlExpiresInSeconds = graphql.expiresInSeconds;

        if (DebugModePlugin.enabled) {
            const trpcPanelHeaders = JSON.parse(window.localStorage.getItem('headers') || '{}');
            trpcPanelHeaders.Authorization = `Bearer ${state.gqlToken}`;
            window.localStorage.setItem('headers', JSON.stringify(trpcPanelHeaders));
        }
    },
    setAuthStatusFalse (state) {
        state.authStatus = AuthStatus.False;
        state.gqlEndpoint = '';
        state.gqlToken = '';
        state.gqlExpiresInSeconds = 0;
        state.currentUser = null;
        state.acls = null;

        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    },
    setCurrentUserStoreState (state, user: GetUserQuery['user'][number]) {
        if (user) {
            // TODO: sanatize data
            state.currentUser = user;
        }
    },
    getCurrentUserAcls (state, acls: AllEnabledUserAclsQuery) {
        state.acls = acls;
    },
});

const actions = actionTree({ state: authState, mutations }, {
    async setSignedInUser ({ commit, state }, payload: { refetchAllQueries: boolean }): Promise<boolean> {
        const { Notify } = await import('../notifications/notify');

        setClientAuth(state.gqlToken, state.gqlEndpoint);

        // update the service worker so it can intercept file requests with new token
        if (navigator.serviceWorker) {
            navigator.serviceWorker.ready.then(registration => {
                if (registration.active) {
                    const msg: ServiceWorkerMessage = {
                        kind: 'webConsoleJwt',
                        jwt: state.gqlToken,
                    };
                    registration.active.postMessage(msg);
                }
            });
        }

        const { refetchAllQueries } = await import('../util/gql-client');
        try {
            if (!state.userId) {
                return false;
            }

            // if the token is simply expired, we can refresh it without neededing to requery the user
            if (payload.refetchAllQueries) {
                const userQueryData = await gqlClient.request({
                    document: GetUserDocument,
                    variables: {
                        queryFilter: {
                            id: { _eq: state.userId },
                        },
                    },
                });

                const [ authenticatedUser ] = userQueryData?.user || [];

                if (authenticatedUser) {
                    commit('setCurrentUserStoreState', authenticatedUser);
                    const aclData = await gqlClient.request({
                        document: AllEnabledUserAclsDocument,
                        variables: { userId: state.userId },
                    });
                    commit('getCurrentUserAcls', aclData);
                    refetchAllQueries();
                }
            }
            if (resolveAuthInitialized) { // should immediately be available
                resolveAuthInitialized(true);
            }
            return true;
        } catch (err) {
            Notify.error('Failed to load user authentication', err);
            if (resolveAuthInitialized) { // should immediately be available
                resolveAuthInitialized(false);
            }
            return false;
        }
    },
    async refreshToken ({ commit, dispatch, state }, payload?: { automatedRefresh?: boolean }) {
        try {
            const refreshTokenResult: GetRefreshTokenResult = await getRefreshToken();
            commit('setAuthStatusSuccess', refreshTokenResult);
            const result = await dispatch('setSignedInUser', { refetchAllQueries: payload?.automatedRefresh !== true });
            if (result !== true) {
                throw new Error('Unable to set signed in user!');
            }
            let expiresInSeconds = state.gqlExpiresInSeconds;
            expiresInSeconds = Number.isInteger(expiresInSeconds) && expiresInSeconds >= 30 ? expiresInSeconds : 30;
            if (refreshTimeout !== null) {
                window.clearTimeout(refreshTimeout);
            }
            refreshTimeout = window.setTimeout(() => {
                dispatch('refreshToken', { automatedRefresh: true });
            }, expiresInSeconds * 1000 / 3);

            return true;
        } catch (error) {
            commit('setAuthStatusFalse');
            if (resolveAuthInitialized) { // should immediately be available
                resolveAuthInitialized(false);
            }
            return false;
        }
    },
});

export default {
    namespaced: true,
    state: authState,
    getters,
    mutations,
    actions,
};
