import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';

export function validateWorkOrderSelectAcl (): boolean {
    return validateAcls([ 'work_order_select' ]);
}

export function validateWorkOrderInsertAcl (): boolean {
    return validateAcls([ 'work_order_insert' ]);
}

export function validateWorkOrderUpdateAcl (): boolean {
    return validateAcls([ 'work_order_update' ]);
}

export function validateWorkOrderDeleteAcl (): boolean {
    return validateAcls([ 'work_order_delete' ]);
}
