import z from 'zod';

export const cellCfgAttrsBaseSchema = z.object({
    // TODO: This probably shouldn't be optional. Defaults to null
    parentCellId: z.string().optional(), // NOTE: this cannot be a UUID since macro cell ids are not UUIDs
});

export const cellCfgBaseSchema = z.object({
    type: z.string(),
    name: z.string().optional(),
    source: z.string().optional(), // TODO: Is this used anywhere?
    elevated: z.boolean().optional(),
    attrs: cellCfgAttrsBaseSchema,
    id: z.string(),
    modifiers: z.record(z.string(), z.string().optional()).optional(),
});
